import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { FC } from 'react';
import { NavBarLogo } from './NavBarLogo';
import { NavBarUserMenuButton } from './NavBarUserMenuButton';
import { NavBarMenuButton } from './NavBarMenuButton';
import { NavBarLinks } from './NavBarLinks';

export const NavBar: FC<{}> = () => {
  return (
    <AppBar elevation={0} position="static" sx={{ px: 2 }}>
      <Toolbar
        disableGutters
        variant="dense"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <NavBarMenuButton />
        </Box>

        <NavBarLogo />

        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
          <NavBarLinks />
        </Box>

        <NavBarUserMenuButton />
      </Toolbar>
    </AppBar>
  );
};
