import useSWR from 'swr';
import { getFinancialRecords } from '../api/finances.api';
import { GetFinancialRecordsRequest } from '../types/finances.types';
import { useState } from 'react';
import { usePreviousValidValue } from 'src/hooks';

export const useGetFinancialRecords = () => {
  const [params, setParams] = useState<GetFinancialRecordsRequest>({
    pageNumber: 0,
    pageSize: 20,
    cruiseNo: '',
    orderNo: '',
    ticketNo: '',
    orderStatuses: [],
    chartererIds: [],
    payloadNo: '',
    transactionPlaces: [],
    transactionDate: '',
    transactionPayer: '',
    transactionTypes: [],
    credit: '',
    debit: '',
    transactionCurrencies: [],
    comission: '',
  });

  var {
    data,
    isLoading,
    mutate: refresh,
  } = useSWR([`/finances`, params], ([, params]) =>
    getFinancialRecords(params)
  );
  const totalCount = usePreviousValidValue(data?.totalCount);

  const handleChangePage = (_event: any, pageNumber: number) => {
    setParams((p) => ({ ...p, pageNumber }));
  };

  const handleChangeRowsPerPage = (event: any) => {
    setParams((p) => ({ ...p, pageNumber: 0, pageSize: +event.target.value }));
  };

  return {
    params,
    setParams,
    financialRecords: data?.financialRecords || [],
    isLoading: isLoading || !data?.financialRecords,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
    refresh,
    stats: data && {
      totalCredit: data.totalCredit,
      totalDebit: data.totalDebit,
      totalBalance: data.totalBalance,
    },
  };
};
