import { FC } from 'react';
import { Tariff } from '../types/tariff.type';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { routes } from 'src/features/routes';
import { CustomTable, Loading } from 'src/components';
import { Box, Button, Card, Typography } from '@mui/material';
import { useTariffs } from '../hooks/useTariffs.hook';
import { TENANT } from 'src/config/app.config';

const ch = createColumnHelper<Tariff>();

const columns = [
  ch.accessor('type', { header: 'Type' }),
  ch.accessor('name', {
    header: 'Name',
    cell: (info) => (
      <div style={{ textAlign: 'left', paddingLeft: '20px' }}>
        <Link to={routes.editTariff(info.row.original.id)}>
          {info.getValue()}
        </Link>
      </div>
    ),
  }),
  ch.group({
    header: TENANT === 'E60' ? 'Constanta' : 'From Europe',
    columns: [
      ch.accessor('eurTariffs.freight', {
        header: 'Freight',
      }),
      ch.accessor('eurTariffs.terminalDepPort', {
        header: 'Term. Dep.',
      }),
      ch.accessor('eurTariffs.terminalDesPort', {
        header: 'Term. Des.',
      }),
    ],
  }),
  ch.group({
    header: TENANT === 'E60' ? 'Poti' : 'From Georgia',
    columns: [
      ch.accessor('geoTariffs.freight', {
        header: 'Freight',
        getGroupingValue: (row) => row.geoTariffs.sum,
      }),
      ch.accessor('geoTariffs.terminalDepPort', {
        header: 'Term. Dep.',
      }),
      ch.accessor('geoTariffs.terminalDesPort', {
        header: 'Term. Des.',
      }),
    ],
  }),
  ch.group({
    header: 'Two Way',
    columns: [
      ch.accessor('twoWayTariffs.freight', {
        header: 'Freight',
        getGroupingValue: (row) => row.twoWayTariffs.sum,
      }),
      ch.accessor('twoWayTariffs.terminalDepPort', {
        header: 'Term. Dep.',
      }),
      ch.accessor('twoWayTariffs.terminalDesPort', {
        header: 'Term. Des.',
      }),
    ],
  }),
  ch.accessor('comment', { header: 'Comment' }),
  ch.accessor('parkingTariffType', { header: 'Tariff type' }),
];

export const TariffListPage: FC<{}> = () => {
  const { data } = useTariffs();

  return (
    <>
      <Card
        sx={{
          p: 1,
          mb: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box width={64} />

        <Typography variant="h6">Tariffs</Typography>

        <Button component={Link} to={routes.createTariff}>
          Add
        </Button>
      </Card>

      {!data ? <Loading /> : <CustomTable columns={columns} data={data} />}
    </>
  );
};
