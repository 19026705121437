import { FC, useState } from 'react';
import { Box, Card, Divider, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { AccessGroup, AccessRights } from '../types/accessGroup.type';
import { CustomCheckbox } from 'src/components';
import { DeleteAccessGroupButton } from './DeleteAccessGroupButton';

export const AccessGroupForm: FC<{
  initialValue: AccessGroup;
  save: (newData: AccessGroup) => void;
  loading: boolean;
  refetch?: () => void;
}> = ({ initialValue, save, loading, refetch }) => {
  const [accessGroup, setAccessGroup] = useState(initialValue);

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          onClick={() => save(accessGroup)}
          endIcon={<SaveIcon />}
          disabled={accessGroup.name === ''}
        >
          Save
        </LoadingButton>

        {accessGroup.id ? (
          <DeleteAccessGroupButton accessGroupId={accessGroup.id} />
        ) : null}
      </Box>

      <Divider />

      <Box px={2} py={1}>
        <TextField
          label="Name"
          value={accessGroup.name}
          onChange={(e) =>
            setAccessGroup({ ...accessGroup, name: e.target.value })
          }
          error={accessGroup.name === ''}
        />

        <RightsForm
          label="Cruises"
          value={accessGroup.cruises}
          onChange={(v) => setAccessGroup({ ...accessGroup, cruises: v })}
        />

        <RightsForm
          label="Contacts"
          value={accessGroup.dictionaries}
          onChange={(v) => setAccessGroup({ ...accessGroup, dictionaries: v })}
        />

        <RightsForm
          label="Access"
          value={accessGroup.access}
          onChange={(v) => setAccessGroup({ ...accessGroup, access: v })}
        />

        <RightsForm
          label="Tariffs"
          value={accessGroup.tariffs}
          onChange={(v) => setAccessGroup({ ...accessGroup, tariffs: v })}
        />

        <RightsForm
          label="Charterers"
          value={accessGroup.charterers}
          onChange={(v) => setAccessGroup({ ...accessGroup, charterers: v })}
        />
      </Box>
    </Card>
  );
};

export const RightsForm: FC<{
  label: string;
  value: AccessRights;
  onChange: (value: AccessRights) => void;
}> = ({ label, value, onChange }) => {
  return (
    <Box
      display="flex"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      <Typography mt={1} width={100}>
        {label}
      </Typography>

      {/* <CustomCheckbox
        label="Read"
        checked={value.read}
        onChange={(v) => onChange({ ...value, read: v })}
        width={100}
      /> */}

      <CustomCheckbox
        label="Write"
        checked={value.write}
        onChange={(v) => onChange({ ...value, write: v })}
        width={100}
      />

      <CustomCheckbox
        label="Delete"
        checked={value.delete}
        onChange={(v) => onChange({ ...value, delete: v })}
        width={100}
      />
    </Box>
  );
};
