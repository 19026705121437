import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { AmountInputRHF } from 'src/components';
import {
  FinancialData,
  sumFinancialData,
} from '../../types/financialData.type';
import { TextField } from '@mui/material';

export const FinancialDataForm: FC<{
  path: string;
  freightRateRequired?: boolean;
}> = ({ path, freightRateRequired }) => {
  return (
    <>
      <AmountInputRHF
        name={`${path}.freight`}
        label="Freight rate"
        type="float"
        required={freightRateRequired}
      />

      <AmountInputRHF
        name={`${path}.terminalDepPort`}
        label="Terminal departure amount"
        type="float"
      />

      <AmountInputRHF
        name={`${path}.terminalDesPort`}
        label="Terminal destination amount"
        type="float"
      />

      <TotalAmount path={path} />
    </>
  );
};

export const TotalAmount: FC<{ path: string }> = ({ path }) => {
  const watch = useWatch({ name: path }) as FinancialData;
  const totalAmount = sumFinancialData(watch);

  return <TextField label="Total amount" value={totalAmount} disabled />;
};
