import { axiosInstance } from 'src/config/axios.config';
import { CruiseParkings, EditPayloadParking } from '../types/parkings.types';

const endpoint = '/api/parkings';

export const getCruiseParkings = async (
  cruiseId: number
): Promise<CruiseParkings> => {
  const data = await axiosInstance
    .get(`${endpoint}/cruise/${cruiseId}`)
    .then((response) => response.data);

  return data;
};

export const getPayloadParking = async (
  id: string
): Promise<EditPayloadParking> => {
  const data = await axiosInstance
    .get(`${endpoint}/${id}`)
    .then((response) => response.data);

  return data;
};

export const updatePayloadParking = async (
  payloadParking: EditPayloadParking
): Promise<EditPayloadParking> => {
  const data = await axiosInstance
    .put(endpoint, payloadParking)
    .then((response) => response.data);

  return data;
};
