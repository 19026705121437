import { createColumnHelper } from '@tanstack/react-table';
import { FC, useMemo, useState } from 'react';
import { CruisePayload, cruiseCars } from '../../types/cruise.type';
import { columnDefs } from './columnDefs';
import { PayloadsToolbar } from './PayloadsToolbar';
import { CustomTable } from 'src/components';
import { PayloadListProps } from '../../types/payloadListProps.type';
import { compact } from 'lodash';
import { ReportButton } from 'src/features/reports';
import { ScrollableTableWrapper } from './ScrollableTableWrapper';

const ch = createColumnHelper<CruisePayload>();

export const CarList: FC<PayloadListProps> = ({
  cruise,
  checks,
  setChecked,
  hideButtons,
}) => {
  const columns = useMemo(
    () =>
      compact([
        columnDefs.selected(checks, setChecked),
        hideButtons ? null : columnDefs.reports,
        columnDefs.paymentStatus,
        columnDefs.orderStatus,
        columnDefs.rowNumber,
        columnDefs.orderNo(hideButtons, cruise.no),
        ch.accessor('no', { header: 'No' }),
        ch.accessor('driver', {
          header: 'Driver',
          cell: (info) => (
            <div style={{ textAlign: 'left', paddingLeft: '20px' }}>
              {info.getValue()}
            </div>
          ),
        }),
        ch.accessor('model', { header: 'Model' }),
        ch.accessor('cargoFullWeight', { header: 'Full Weight' }),
        ch.accessor('shipper', { header: 'Payer' }),
        ch.accessor('charterer', { header: 'Charterer' }),
        ch.accessor('driverPhone', { header: 'Phone Number' }),
        ch.accessor('createdByName', { header: 'Created By' }),
      ]),
    [checks, setChecked, hideButtons, cruise.no]
  );

  const data = cruiseCars(cruise).map((i) => ({
    ...i,
    no: i.no + (i.trailerNo ? ` / ${i.trailerNo}` : ''),
  }));

  const [globalFilter, setGlobalFilter] = useState('');

  return (
    <>
      <PayloadsToolbar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      >
        {cruise.id && (
          <>
            <ReportButton
              name="CarDriverList"
              params={{ cruiseId: cruise.id }}
              formats={['Word', 'Excel']}
            />
            <ReportButton
              name="CargoManifestEATK_2"
              params={{ cruiseId: cruise.id }}
              formats={['Excel']}
            />
          </>
        )}
      </PayloadsToolbar>

      <ScrollableTableWrapper>
        <CustomTable
          data={data}
          columns={columns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </ScrollableTableWrapper>
    </>
  );
};
