import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tariff, initTariff } from '../types/tariff.type';
import {
  createTariff,
  deleteTariff,
  getTariff,
  updateTariff,
} from '../api/tariffs.api';
import { getErrorMessage } from 'src/utils';
import { Loading } from 'src/components';
import { Alert } from '@mui/material';
import { TariffForm } from './TariffForm';
import toast from 'react-hot-toast';
import { routes } from 'src/features/routes';

export const EditTariffPage: FC<{}> = () => {
  const id = +(useParams().id || -1);

  const [tariff, setTariff] = useState<Tariff | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const loadTariff = (id: number) => {
    setLoading(true);
    getTariff(id)
      .then(setTariff)
      .catch((e) => setError(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  const saveTariff = () => {
    if (tariff == null) return;

    setLoading(true);
    updateTariff(tariff)
      .then(() => toast.success('Tariff updated successfully!'))
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  const onDelete = () => {
    if (tariff == null) return;

    setLoading(true);
    deleteTariff(tariff.id)
      .then(() => {
        navigate(routes.tariffs);
        toast.success('Tariff deleted successfully!');
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadTariff(id);
  }, [id]);

  if (loading) return <Loading />;

  if (tariff == null || error)
    return (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    );

  return (
    <TariffForm
      tariff={tariff}
      setTariff={setTariff}
      save={saveTariff}
      onDelete={onDelete}
    />
  );
};

export const CreateTariffPage: FC<{}> = () => {
  const [tariff, setTariff] = useState<Tariff>(initTariff());
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const saveTariff = () => {
    setLoading(true);
    createTariff(tariff)
      .then(() => {
        navigate(routes.tariffs);
        toast.success('Tariff created successfully!');
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  if (loading) return <Loading />;

  return <TariffForm tariff={tariff} setTariff={setTariff} save={saveTariff} />;
};
