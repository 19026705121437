import { FC, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { BillDialog } from './BillDialog';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

export const BillButton: FC<{ payloadId: string }> = ({ payloadId }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Bill">
        <IconButton
          onClick={handleOpenDialog}
          sx={{
            color: 'whitesmoke',
            bgcolor: 'primary.main',
            ':hover': { bgcolor: 'primary.dark' },
            height: 30,
            mt: 0.125,
          }}
        >
          <RequestQuoteOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <BillDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        payloadId={payloadId}
      />
    </>
  );
};
