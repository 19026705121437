import { axiosInstance } from 'src/config/axios.config';
import { Search } from '../types/search.type';
import { SearchResponse } from '../types/searchResponse.type';

export const searchPayloads = async (search: Search): Promise<SearchResponse> =>
  (await axiosInstance.put('/api/search/payloads', search)).data;

export const exportPayloads = async (search: Search) =>
  await axiosInstance
    .put('/api/search/payloads-export', search, { responseType: 'blob' })
    .then((response) => {
      // create url for file
      const url = window.URL.createObjectURL(response.data);

      // download file
      const link = document.createElement('a');

      link.download = `search-result.xlsx`;
      link.href = url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // clean up url after use
      window.URL.revokeObjectURL(url);

      return response;
    });
