export type ReportType = 'Preview' | 'Pdf' | 'Word' | 'Excel';
export const reportTypes: ReportType[] = ['Preview', 'Pdf', 'Word', 'Excel'];

export const getFormat = (type: ReportType) => {
  switch (type) {
    case 'Preview':
    case 'Pdf':
      return 'PDF';
    case 'Word':
      return 'WORDOPENXML';
    case 'Excel':
      return 'EXCELOPENXML';
  }
};

export const getFileExtension = (mimeType: string) => {
  switch (mimeType) {
    case 'application/pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/xml':
      return 'xml';
  }
};
