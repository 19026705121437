import { Breadcrumbs, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from 'src/features/routes';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const PayloadBreadcrumbs: FC<{
  path: string;
  label: string;
}> = ({ path, label }) => {
  const { watch } = useFormContext();
  const [cruiseId, cruiseNo, depPortId, desPortId] = watch([
    path + '.cruiseId',
    path + '.cruiseNo',
    'departurePortId',
    'destinationPortId',
  ]);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      sx={{ color: 'black' }}
    >
      {cruiseId ? (
        <Link component={RouterLink} to={routes.cruise(cruiseId)}>
          <Typography variant="h6">{cruiseNo}</Typography>
        </Link>
      ) : (
        <Link
          component={RouterLink}
          to={routes.listOfWaiting(depPortId, desPortId)}
        >
          <Typography variant="h6">List Of Waiting</Typography>
        </Link>
      )}

      <Typography variant="h6" color="black">
        {label}
      </Typography>
    </Breadcrumbs>
  );
};
