export const routes = {
  // Index
  index: '/',

  // Auth
  login: '/login',

  // Cruise
  cruises: '/cruises',
  cruise: (id: string | number) => `/cruises/${id}`,
  listOfWaiting: (depPortId: string | number, desPortId: string | number) =>
    `/cruises/list-of-waiting/${depPortId}/${desPortId}`,

  // Order
  createOrder: '/orders/new',
  editOrder: (id: string | number) => `/orders/${id}`,

  // Search
  search: '/search',

  // Tariff
  tariffs: '/tariffs',
  createTariff: '/tariffs/new',
  editTariff: (id: string | number) => `/tariffs/${id}`,

  // Contacts
  contacts: '/contacts',
  createCharterer: '/contacts/charterer/new',
  editCharterer: (id: string | number) => `/contacts/charterer/${id}`,

  // Finances
  finances: '/finances',
  rates: '/finances/rates',

  // Parking
  parkings: '/parkings',
  cruiseParkings: (id: string | number) => `/parkings/cruise/${id}`,

  // Access
  access: '/access',
  accessGroup: (id: string | number) => `/access/group/${id}`,
  createAccessGroup: '/access/group/new',
  user: (id: string | number) => `/access/user/${id}`,
  createUser: '/access/user/new',
};
