import { axiosInstance } from 'src/config/axios.config';
import { User, UserListItem } from '../types/user.type';

// Users
export const getUsers = async (): Promise<UserListItem[]> =>
  (await axiosInstance.get<UserListItem[]>('/api/users')).data;

export const getUser = async (id: number): Promise<User> =>
  (await axiosInstance.get<User>(`/api/users/${id}`)).data;

export const createUser = async (user: User): Promise<User> =>
  (await axiosInstance.post<User>(`/api/users`, user)).data;

export const updateUser = async (user: User): Promise<User> =>
  (await axiosInstance.put<User>(`/api/users`, user)).data;
