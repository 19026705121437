import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { User, initUser } from '../types/user.type';
import { createUser, getUser, updateUser } from '../api/users.api';
import { useRUD } from 'src/hooks';
import { UserForm } from './UserForm';
import { Alert } from '@mui/material';
import { Loading } from 'src/components';
import toast from 'react-hot-toast';
import { routes } from 'src/features/routes';
import { getErrorMessage, is404Error } from 'src/utils';

export const EditUserPage: FC<{}> = () => {
  const id = +(useParams().id || -1);

  const { loading, data, save, saving, refetch, error } = useRUD(
    () => getUser(id),
    (user: User) =>
      updateUser(user)
        .then((updatedUser) => {
          toast.success('User updated!');
          return updatedUser;
        })
        .catch((e) => {
          toast.error(getErrorMessage(e));
          return e;
        })
  );

  if (is404Error(error))
    return (
      <Alert variant="filled" severity="error">
        User was not found! It may have been deleted.
      </Alert>
    );

  if (loading || data == null) return <Loading />;

  return (
    <UserForm
      initialValue={data}
      save={save}
      loading={saving}
      refetch={refetch}
    />
  );
};

export const CreateUserPage: FC<{}> = () => {
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const save = async (user: User) => {
    setSaving(true);
    createUser(user)
      .then(() => {
        toast.success('User created!');
        navigate(routes.access, { replace: true });
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  return <UserForm initialValue={initUser()} save={save} loading={saving} />;
};
