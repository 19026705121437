import { routes } from '../utils/routes';

export const navbarLinks = [
  { to: routes.cruises, title: 'Cruises' },
  { to: routes.search, title: 'Search' },
  { to: routes.tariffs, title: 'Tariffs' },
  { to: routes.contacts, title: 'Contacts' },
  { to: routes.finances, title: 'Finances' },
  { to: routes.parkings, title: 'Parkings' },
  { to: routes.access, title: 'Access' },
];
