import { IconButton } from '@mui/material';
import { FC, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadFile } from '../api/files.api';
import { Directory } from '../types/file.types';
import toast from 'react-hot-toast';

export const FileDownloadButton: FC<{
  loading: boolean;
  dir: Directory;
  id: number | string;
  name: string;
}> = ({ loading, dir, id, name }) => {
  const [downloading, setDownloading] = useState(false);

  const download = () => {
    setDownloading(true);
    downloadFile(dir, id, name)
      .catch(async (err) =>
        toast.error(JSON.parse(await err.response.data.text()).title)
      )
      .finally(() => setDownloading(false));
  };

  return (
    <IconButton
      disabled={loading || downloading}
      onClick={download}
      color="primary"
    >
      <DownloadIcon />
    </IconButton>
  );
};
