import { FC } from 'react';
import { useTariffs } from '../hooks/useTariffs.hook';
import { MenuItem, Select } from '@mui/material';

export const TariffSelectorForSearch: FC<{
  value: number | null;
  onChange: (value: number | null) => void;
}> = ({ value, onChange }) => {
  const { data } = useTariffs();

  return (
    <Select
      fullWidth
      value={value || ''}
      onChange={(e) => {
        const tariffId = (e.target.value as number) || null;
        onChange(tariffId);
      }}
      notched
      margin="dense"
      size="small"
      sx={{ width: 200 }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {data?.map((tariff) => (
        <MenuItem key={tariff.id} value={tariff.id}>
          {tariff.type} - {tariff.name}
        </MenuItem>
      ))}
    </Select>
  );
};
