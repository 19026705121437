import { axiosInstance } from 'src/config/axios.config';
import {
  CreateUpdateCruiseDto,
  Cruise,
  GetCruiseListResponse,
  ListOfWaiting,
} from '../types/cruise.type';
import { CruiseDirection } from '../types/direction.type';

const endpoint = '/api/cruises';

export const getCruise = async (id: number): Promise<Cruise> => {
  const data = await axiosInstance
    .get(`${endpoint}/${id}`)
    .then((response) => response.data);

  return data;
};

export const getCruiseList = async (
  depPortId?: number,
  desPortId?: number,
  shipId?: number,
  page?: number,
  size?: number
): Promise<GetCruiseListResponse> => {
  const data = await axiosInstance
    .get(endpoint, { params: { depPortId, desPortId, shipId, page, size } })
    .then((response) => response.data);

  return data;
};

export const getCruiseDirections = async (): Promise<CruiseDirection[]> => {
  const data = await axiosInstance
    .get(endpoint + `/directions`)
    .then((response) => response.data);

  return data;
};

export const getListOfWaiting = async (
  depPortId: number,
  desPortId: number
): Promise<ListOfWaiting> => {
  const data = await axiosInstance
    .get(`${endpoint}/list-of-waiting/${depPortId}/${desPortId}`)
    .then((response) => response.data);

  return data;
};

export const movePayloads = async (dto: {
  cruiseId: number | null;
  payloadIds: string[];
}): Promise<void> => {
  await axiosInstance
    .put(`${endpoint}/move-payloads`, dto)
    .then((response) => response.data);
};

export const sortPayloads = async (dto: {
  cruiseId: number;
  payloadIds: string[];
}): Promise<void> => {
  await axiosInstance.put(`${endpoint}/sort-payloads`, dto);
};

export const getCruiseShort = async (
  id: number
): Promise<CreateUpdateCruiseDto> => {
  const data = await axiosInstance
    .get(`${endpoint}/${id}`)
    .then((response) => response.data);

  return data;
};

export const addCruise = async (cruise: CreateUpdateCruiseDto) => {
  return await axiosInstance.post(endpoint, cruise);
};

export const updateCruise = async (cruise: CreateUpdateCruiseDto) => {
  return await axiosInstance.put(endpoint, cruise);
};

export const deleteCruise = async (id: number) => {
  return await axiosInstance.delete(`${endpoint}/${id}`);
};
