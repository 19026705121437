import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { FC } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

const height = 30.75;

export const GlobalFilterInput: FC<{
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}> = ({ globalFilter, setGlobalFilter }) => (
  <FormControl
    variant="outlined"
    size="small"
    margin="none"
    sx={{ height, width: 250 }}
    focused
  >
    <OutlinedInput
      placeholder="Filter"
      sx={{ height }}
      value={globalFilter}
      onChange={(e) => setGlobalFilter(String(e.target.value))}
      color={globalFilter.length === 0 ? 'primary' : 'warning'}
      endAdornment={
        globalFilter.length === 0 ? null : (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setGlobalFilter('')}
              edge="end"
              size="small"
              sx={{ mr: -1.5 }}
            >
              <ClearIcon color="warning" fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  </FormControl>
);
