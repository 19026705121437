import { today } from 'src/utils';
import { v4 as uuid } from 'uuid';
import { Rate } from './rates.types';

export interface GetFinancialRecordsRequest {
  pageNumber: number;
  pageSize: number;
  cruiseNo: string;
  orderNo: string;
  ticketNo: string;
  orderStatuses: string[];
  chartererIds: number[];
  payloadNo: string;
  transactionPlaces: string[];
  transactionDate: string;
  transactionPayer: string;
  transactionTypes: TransferType[];
  credit: string;
  debit: string;
  transactionCurrencies: Currency[];
  comission: string;
}

export interface GetFinancialRecordsDto {
  financialRecords: FinancialRecord[];
  totalCount: number;
  totalCredit: number;
  totalDebit: number;
  totalBalance: number;
}

export interface FinancialRecord {
  id: string;
  orderId: number;
  orderNo: string;
  cruiseId: number | null;
  cruiseNo: string;
  ticketNo: string;
  orderStatus: string;
  charterer: string;
  no: string;
  credit: number;
  balance: number;
  files: string[];
  transfers: Transfer[];
}

export interface GetFinancialRecordResponse {
  id: string;
  charterer: string;
  ticketNo: string;
  no: string;
  credit: number;
  transfers: Transfer[];
}

export interface Transfer {
  id: string;
  placeId: number;
  placeName: string;
  date: string;
  payer: string;
  type: TransferType;
  amount: number;
  amountEUR: number;
  currency: Currency;
  comission: number;
  createdByName?: string;
}

export type TransferType = 'Bank' | 'Cash' | 'Terminal';
export const transferTypes: TransferType[] = ['Bank', 'Cash', 'Terminal'];

export type Currency = 'EUR' | 'USD' | 'GEL';
export const currencies: Currency[] = ['EUR', 'USD', 'GEL'];

export const initTransfer = (): Transfer => ({
  id: uuid(),
  placeId: 1,
  placeName: '',
  date: today(),
  payer: '',
  type: 'Bank',
  amount: 0,
  amountEUR: 0,
  currency: 'EUR',
  comission: 0,
});

export const getTransferEURAmount = (
  transfer: Transfer,
  rate: Rate
): number => {
  const totalAmount = transfer.amount;
  let result = 0;
  if (transfer.currency === 'EUR') result = totalAmount;
  if (transfer.currency === 'USD') result = totalAmount * rate.usd_to_eur;
  if (transfer.currency === 'GEL') result = totalAmount * rate.gel_to_eur;
  return result;
};

export const getFinancialRecordBalance = (
  financialRecord: GetFinancialRecordResponse | undefined
): number => {
  if (!financialRecord) return 0;
  return (
    financialRecord.transfers.reduce(
      (acc, transfer) => acc + transfer.amountEUR,
      0
    ) - financialRecord.credit
  );
};

export const getFinancialRecordBalanceColor = (
  balance: number
): 'default' | 'error' | 'success' => {
  return balance > 0 ? 'success' : balance < 0 ? 'error' : 'default';
};

export const getBalanceColor = (balance: number): string | undefined => {
  if (balance > 0) return 'limegreen';
  if (balance < 0) return 'tomato';
  else return undefined;
};

/**
 * Returns remaining amount based on total balance and current
 * transfer line.
 */
export const getRemainingAmount = (
  totalBalance: number,
  transferType: TransferType,
  currency: Currency,
  rate: Rate
) => {
  let remaining = -totalBalance;

  // Multiply by rate for non EUR currencies
  if (currency === 'GEL') remaining *= rate.eur_to_gel;
  else if (currency === 'USD') remaining *= rate.eur_to_usd;

  // Add 2% comission for Terminal transfers
  if (transferType === 'Terminal') remaining /= 0.98;

  return remaining;
};

export const getCommissionRate = (transferType: TransferType): number => {
  return transferType === 'Terminal' ? 0.02 : 0;
}
