import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC } from 'react';

export const GenericSelector: FC<{
  disabled?: boolean;
  label?: string;
  value: number | string | null;
  onChange: (value: number | string | null) => void;
  options:
    | {
        id: string | number | null;
        name: string | number;
      }[]
    | number[]
    | string[]
    | null;
  fullWidth?: boolean;
  error?: boolean;
  margin?: 'none' | 'dense' | 'normal' | undefined;
}> = ({
  disabled,
  value,
  onChange,
  options,
  label,
  fullWidth,
  error,
  margin,
}) => {
  return (
    <FormControl
      sx={{ width: fullWidth ? undefined : 180 }}
      fullWidth={fullWidth}
      disabled={disabled || options == null}
      error={error}
      margin={margin}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        defaultValue=""
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options?.map((option) =>
          option && typeof option === 'object' ? (
            <MenuItem
              key={option.id}
              value={option.id == null ? '' : option.id}
            >
              {option.name}
            </MenuItem>
          ) : (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};
