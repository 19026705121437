import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, ReactNode } from 'react';

export const ConfirmDialog: FC<{
  title: string;
  children?: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  yesColor?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning';
}> = ({ title, children, open, setOpen, onConfirm, yesColor }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 250 }}>{children}</DialogContent>
      <DialogActions
        sx={{ mx: 2, mb: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <Button variant="contained" onClick={() => setOpen(false)}>
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onConfirm();
            setOpen(false);
          }}
          color={yesColor || 'error'}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
