import { Box, Chip, Typography } from '@mui/material';
import { FC } from 'react';
import { customMoneyFormat } from 'src/utils';
import { getFinancialRecordBalanceColor } from '../types/finances.types';

export const MoneyChip: FC<{
  label?: string;
  amount: number;
  mt?: number;
  decimals?: number;
  noColor?: boolean;
}> = ({ label, amount, mt, decimals, noColor }) => (
  <Box display="flex" alignItems="center" mt={mt}>
    <Typography sx={{ mx: 1 }}>{label}</Typography>
    <Chip
      label={customMoneyFormat(amount, decimals)}
      color={noColor ? undefined : getFinancialRecordBalanceColor(amount)}
    />
  </Box>
);
