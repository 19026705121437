import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import { FC, forwardRef, useState } from 'react';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { ListOfWaitingCard } from '../list-of-waiting/ListOfWaitingCard';
import { useListOfWaiting } from '../../hooks/useListOfWaiting.hook';
import { useSelectedPayloads } from '../../hooks/useSelectedPayloads.hook';
import { LoadingButton } from '@mui/lab';
import { movePayloads } from '../../api/cruises.api';

export const FromListOfWaitingButton: FC<{
  cruiseId: number;
  depPortId: number;
  desPortId: number;
  refetchCruises: () => void;
}> = ({ cruiseId, depPortId, desPortId, refetchCruises }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} endIcon={<MoveDownIcon />}>
        From List of waiting
      </Button>

      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={open}
        scroll="paper"
      >
        <DialogListOfWaitingContent
          cruiseId={cruiseId}
          depPortId={depPortId}
          desPortId={desPortId}
          closeDialog={() => setOpen(false)}
          refetchCruises={refetchCruises}
        />
      </Dialog>
    </>
  );
};

export const DialogListOfWaitingContent: FC<{
  cruiseId: number;
  depPortId: number;
  desPortId: number;
  refetchCruises: () => void;
  closeDialog: () => void;
}> = ({ cruiseId, depPortId, desPortId, refetchCruises, closeDialog }) => {
  const { listOfWaiting } = useListOfWaiting(depPortId, desPortId);
  const { checks, setChecked } = useSelectedPayloads(listOfWaiting?.payloads);
  const [moving, setMoving] = useState(false);

  const handleMoveSelected = async () => {
    setMoving(true);
    await movePayloads({ cruiseId, payloadIds: checks });
    refetchCruises();
    closeDialog();
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        py={0.875}
      >
        <LoadingButton
          disabled={!checks.length}
          endIcon={<MoveDownIcon />}
          sx={{ width: 160 }}
          onClick={handleMoveSelected}
          loading={moving}
          loadingPosition="end"
        >
          {moving ? 'Moving' : 'Move Selected'}
        </LoadingButton>

        <Typography variant="h6">Select Items To Move</Typography>

        <Box width={160} display="flex" justifyContent="end">
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <DialogContent
        dividers
        sx={{ p: 1.5, bgcolor: 'whitesmoke', overflowY: 'scroll' }}
      >
        <ListOfWaitingCard
          listOfWaiting={listOfWaiting}
          checks={checks}
          setChecked={setChecked}
          hideButtons={true}
        />
      </DialogContent>
    </>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
