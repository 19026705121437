import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';
import { deleteCruise } from '../../api/cruises.api';
import { CruiseListDto } from '../../types/cruise.type';

export const DeleteCruiseDialog: FC<{
  cruise: CruiseListDto;
  open: boolean;
  closeDialog: () => void;
  refetch: () => void;
}> = ({ cruise, open, closeDialog, refetch }) => {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    deleteCruise(cruise.id)
      .then(() => {
        toast.success('Cruise deleted!');
        refetch();
        closeDialog();
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setDeleting(false));
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Delete Cruise {cruise.no}?</DialogTitle>
      <DialogContent sx={{ minWidth: 250 }} />
      <DialogActions
        sx={{ mx: 2, mb: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <Button onClick={closeDialog}>No</Button>
        <LoadingButton loading={deleting} onClick={handleDelete} color="error">
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
