import { FC, forwardRef, useState } from 'react';
import { Cruise, CruisePayload } from '../../types/cruise.type';
import {
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { TransitionProps } from '@mui/material/transitions';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import { sortPayloads } from '../../api/cruises.api';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';
import { KeyedMutator } from 'swr';
import { getColor, getOrderStatusColors } from 'src/features/orders';
import { startCase } from 'lodash';

export const SortPayloadsButton: FC<{
  cruiseId: number;
  originalPayloads: CruisePayload[];
  refetchCruises: KeyedMutator<Cruise>;
}> = ({ cruiseId, originalPayloads, refetchCruises }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} endIcon={<SwapVertIcon />}>
        Arrange
      </Button>

      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={open}
        scroll="paper"
      >
        <SortPayloadsDialogContent
          cruiseId={cruiseId}
          originalPayloads={originalPayloads}
          closeDialog={() => setOpen(false)}
          refetchCruises={refetchCruises}
        />
      </Dialog>
    </>
  );
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',
  // styles we need to apply on draggables
  ...draggableStyle,
  cursor: 'default',
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: 12,
});

const reorder = (
  list: CruisePayload[],
  startIndex: number,
  endIndex: number
): CruisePayload[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SortPayloadsDialogContent: FC<{
  cruiseId: number;
  originalPayloads: CruisePayload[];
  closeDialog: () => void;
  refetchCruises: KeyedMutator<Cruise>;
}> = ({ cruiseId, originalPayloads, closeDialog, refetchCruises }) => {
  const [payloads, setPayloads] = useState<CruisePayload[]>(originalPayloads);
  const [saving, setSaving] = useState(false);

  const save = () => {
    setSaving(true);
    sortPayloads({ cruiseId, payloadIds: payloads.map((p) => p.id) })
      .then(() => {
        refetchCruises();
        toast.success('Items sorted successfully!');
        closeDialog();
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      payloads,
      result.source.index,
      result.destination.index
    );

    setPayloads(items);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        py={0.875}
        borderBottom="1px solid"
        borderColor="divider"
      >
        <LoadingButton
          endIcon={<SaveIcon />}
          disabled={!payloads.length}
          sx={{ width: 80 }}
          onClick={save}
          loading={saving}
          loadingPosition="end"
        >
          Save
        </LoadingButton>

        <Typography variant="h6">
          Drag and drop items to arrange them
        </Typography>

        <Box width={80} display="flex" justifyContent="end">
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <table
              className="custom-table"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {payloads.map((payload, index) => (
                <Draggable
                  key={payload.id}
                  draggableId={payload.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <td>{index + 1}</td>
                      <td>
                        <Chip
                          size="small"
                          label={payload.paymentStatus}
                          color={getColor(payload.paymentStatus)}
                        />
                      </td>
                      <td>
                        <Chip
                          size="small"
                          label={startCase(payload.orderStatus)}
                          sx={{
                            backgroundColor: getOrderStatusColors(
                              payload.orderStatus
                            ).bg,
                            color: getOrderStatusColors(payload.orderStatus).fg,
                          }}
                        />
                      </td>
                      <td>{payload.orderNo}</td>
                      <td>{payload.tariffName}</td>
                      <td>{payload.length}</td>
                      <td>{payload.totalAmount}</td>
                      <td>{payload.ticketType}</td>
                      <td>{payload.cargoName}</td>
                      <td>{payload.no}</td>
                      <td>{payload.driver}</td>
                      <td>{payload.shipper}</td>
                      <td>{payload.charterer}</td>
                      <td>{payload.orderComment}</td>
                      <td>{payload.payloadComment}</td>
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
