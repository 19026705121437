import { FC } from 'react';
import { useUsers } from '../hooks/useUsers.hook';
import { Autocomplete, TextField } from '@mui/material';
import { UserListItem } from '../types/user.type';

const width = 180;

export const UserSelector: FC<{
  userId: number | null;
  onChange: (userId: number | null) => void;
}> = ({ userId, onChange }) => {
  const { data } = useUsers();

  const valueObj = data?.find((country) => country.id === userId) || null;

  return (
    <Autocomplete
      value={valueObj}
      onChange={(_event: any, newValue: UserListItem | null) =>
        onChange(newValue?.id || null)
      }
      options={data || []}
      autoHighlight
      getOptionLabel={(o) => o.name}
      renderInput={(params) => (
        <TextField {...params} margin="none" sx={{ width }} />
      )}
      sx={{ width }}
    />
  );
};
