import { FC } from 'react';
import { useCities } from '../hooks/useCities.hook';
import { GenericMultiSelector } from 'src/components';
import { Skeleton } from '@mui/material';

const WIDTH = 110;

export const CitiesSelector: FC<{
  values: string[];
  onChange: (values: string[]) => void;
}> = ({ values, onChange }) => {
  const cities = useCities();
  return cities ? (
    <GenericMultiSelector
      options={cities.map((c) => c.name)}
      values={values}
      onChange={onChange}
      width={WIDTH}
    />
  ) : (
    <Skeleton width={WIDTH} />
  );
};
