import { Box } from '@mui/material';
import { FC } from 'react';
import {
  AmountInputRHF,
  CustomDatePicker,
  GenericSelector,
  PhoneInputRHF,
  TextFieldRHF,
  UpperTextFieldRHF,
} from 'src/components';
import { Controller } from 'react-hook-form';
import { CountrySelector } from 'src/features/countries';
import { PaymentStatusInputRHF } from '../PaymentStatusInputRHF';
import { OrderStatusSelectorRHF } from '../OrderStatusSelector';

export const PassengerFormFields: FC<{
  path: string;
}> = ({ path }) => {
  return (
    <Box display="flex" columnGap={1} px={2} flexWrap="wrap">
      <TextFieldRHF label="Ticket No" name={`${path}.ticketNo`} disabled />

      <AmountInputRHF
        name={`${path}.amounts.ticket`}
        label="Ticket Price"
        type="float"
      />

      <PaymentStatusInputRHF path={path} />
      <OrderStatusSelectorRHF path={`${path}.orderStatus`} />

      <UpperTextFieldRHF label="Name" name={`${path}.name`} />
      <UpperTextFieldRHF label="Surname" name={`${path}.surname`} />

      <Controller
        name={`${path}.birthDate`}
        render={({ field }) => (
          <CustomDatePicker
            label="Birth Date"
            initialValue={field.value}
            onChange={field.onChange}
            hideError
          />
        )}
      />

      <PhoneInputRHF
        phoneCountryCodePath={`${path}.phoneCountryCode`}
        phoneNoPath={`${path}.phoneNo`}
      />

      <TextFieldRHF label="Email" name={`${path}.email`} />

      <UpperTextFieldRHF label="Passport" name={`${path}.passport`} />

      <UpperTextFieldRHF label="Personal Id" name={`${path}.personalId`} />

      <Controller
        name={`${path}.citizenshipId`}
        render={({ field }) => (
          <CountrySelector
            label="Citizenship"
            countryId={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <Controller
        name={`${path}.sex`}
        render={({ field }) => (
          <GenericSelector
            label="Gender"
            value={field.value}
            onChange={field.onChange}
            options={['Male', 'Female']}
          />
        )}
      />

      <TextFieldRHF label="Comment" name={`${path}.comment`} />

      <AmountInputRHF
        label="Animal Count"
        name={`${path}.animalCount`}
        type="int"
      />

      <AmountInputRHF
        label="Animal Price"
        name={`${path}.amounts.animal`}
        type="int"
      />
    </Box>
  );
};
