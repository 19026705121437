import { FC } from 'react';
import { navbarLinks } from '../types/navbarLinks.type';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export const NavBarLinks: FC<{}> = () => (
  <>
    {navbarLinks.map(({ to, title }) => (
      <Button
        component={Link}
        variant="text"
        key={to}
        to={to}
        sx={{ color: 'white' }}
      >
        {title}
      </Button>
    ))}
  </>
);
