import { FC, useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { format, isValid } from 'date-fns';

export const CustomDatePicker: FC<{
  label?: string;
  initialValue: string | null;
  onChange?: (date: string) => void;
  disabled?: boolean;
  hideError?: boolean;
  width?: number;
  m?: number;
  listenToInitialValueChanges?: boolean;
}> = ({
  label,
  initialValue,
  onChange,
  disabled,
  hideError,
  width,
  m,
  listenToInitialValueChanges,
}) => {
  const [dateValue, setDateValue] = useState<Date | null>(
    new Date(initialValue?.substring(0, 10) || '')
  );

  useEffect(() => {
    if (listenToInitialValueChanges) {
      setDateValue(new Date(initialValue?.substring(0, 10) || ''));
    }
  }, [initialValue, listenToInitialValueChanges]);

  if (hideError)
    return (
      <DatePicker
        label={label}
        disabled={disabled}
        value={dateValue}
        sx={{ width, m }}
        onChange={(date) => {
          setDateValue(date);
          if (((isValid(date) && date?.getFullYear()) || 0) >= 1900) {
            onChange?.(toDateString(date));
          } else onChange?.('');
        }}
        slotProps={{ textField: { error: false } }}
      />
    );
  else
    return (
      <DatePicker
        label={label}
        disabled={disabled}
        value={dateValue}
        sx={{ width, m }}
        onChange={(date) => {
          setDateValue(date);
          if (((isValid(date) && date?.getFullYear()) || 0) >= 1900) {
            onChange?.(toDateString(date));
          } else onChange?.('');
        }}
      />
    );
};

const toDateString = (date: Date | null): string => {
  if (!date) return '';
  return format(date, 'yyyy-MM-dd');
};
