import { Box, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Order, getOrderTotalAmount } from '../types/order.type';
import { CustomDatePicker } from 'src/components';
import { ChartererSelector } from 'src/features/charterers';
import { PortSelector } from 'src/features/cities';
import { Controller, useFormContext } from 'react-hook-form';
import { CompanySelector } from 'src/features/companies';

export const OrderFormHeaderFields: FC<{ refetch?: () => void }> = () => {
  return (
    <Box display="flex" columnGap={1} px={2} flexWrap="wrap" pb={1}>
      <Controller
        name="departurePortId"
        render={({ field: { value } }) => (
          <PortSelector label="Departure Port" disabled cityId={value} />
        )}
      />

      <Controller
        name="destinationPortId"
        render={({ field: { value } }) => (
          <PortSelector label="Destination Port" disabled cityId={value} />
        )}
      />

      <Controller
        name="orderDate"
        render={({ field }) => (
          <CustomDatePicker
            label="Order Date"
            disabled={field.disabled}
            initialValue={field.value}
            onChange={field.onChange}
          />
        )}
        rules={{ required: true }}
      />

      <Controller
        name="chartererId"
        render={({ field }) => (
          <ChartererSelector
            chartererId={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <Controller
        name="comment"
        render={({ field }) => <TextField label="Comment" {...field} />}
      />

      <Controller
        name="lastModifiedBy"
        render={({ field }) =>
          field.value ? (
            <TextField label="Last Modified By" {...field} disabled />
          ) : (
            <></>
          )
        }
      />

      <Controller
        name="createdBy"
        render={({ field }) => (
          <TextField label="Created By" value={field.value} disabled />
        )}
      />

      <Controller
        name="createdOn"
        render={({ field }) => (
          <CustomDatePicker
            label="Created On"
            initialValue={field.value}
            disabled
            hideError
          />
        )}
      />

      <OrderTotalAmount />

      <Controller
        name="customerId"
        render={({ field }) => (
          <CompanySelector
            label="Payer"
            companyId={field.value}
            onCompanyIdChange={field.onChange}
            required
          />
        )}
      />
    </Box>
  );
};

const OrderTotalAmount: FC<{}> = () => {
  const { watch, getValues } = useFormContext<Order>();

  const [totalAmount, setTotalAmount] = useState(
    getOrderTotalAmount(getValues())
  );

  useEffect(() => {
    const subscription = watch((_value, { name }) => {
      if (!name) return;

      if (
        name.includes('amounts') ||
        name === 'payloads' ||
        name.includes('driver') ||
        name.includes('orderStatus')
      ) {
        setTotalAmount(getOrderTotalAmount(getValues()));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues]);

  return <TextField label="Total Amount" value={totalAmount} disabled />;
};
