import { Dialog } from '@mui/material';
import { FC, useState } from 'react';
import { EditCompanyDialogContent } from './EditCompanyDialogContent';
import { DeleteCompanyDialog } from './DeleteCompanyDialog';
import { MoreButton } from 'src/components';

export const MoreCompanyButton: FC<{
  refresh: () => void;
  companyId: number;
}> = ({ refresh, companyId }) => {
  // Edit Dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  // Delete Dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <>
      <MoreButton
        onEdit={() => setEditDialogOpen(true)}
        onDelete={() => setDeleteDialogOpen(true)}
      />

      <Dialog open={editDialogOpen}>
        <EditCompanyDialogContent
          companyId={companyId}
          closeDialog={() => {
            setEditDialogOpen(false);
          }}
          refresh={refresh}
        />
      </Dialog>

      <DeleteCompanyDialog
        companyId={companyId}
        refetch={refresh}
        open={deleteDialogOpen}
        closeDialog={() => setDeleteDialogOpen(false)}
      />
    </>
  );
};
