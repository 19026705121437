import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { Charterer } from '../types/charterer.type';
import { routes } from 'src/features/routes';
import { Link } from 'react-router-dom';
import { useCharterers } from '../hooks/useCharterers.hook';
import { Box, Button, Card, Typography } from '@mui/material';
import { CustomTable, Loading } from 'src/components';

const ch = createColumnHelper<Charterer>();

const columns = [
  ch.accessor('name', {
    header: 'Name',
    cell: (info) => (
      <Link to={routes.editCharterer(info.row.original.id)}>
        {info.getValue()}
      </Link>
    ),
  }),
];

export const ChartererList: FC<{}> = () => {
  const { data } = useCharterers();

  return (
    <>
      <Card
        sx={{
          p: 1,
          mb: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box width={64} />

        <Typography variant="h6">Charterers</Typography>

        <Button component={Link} to={routes.createCharterer}>
          Add
        </Button>
      </Card>

      {!data ? <Loading /> : <CustomTable columns={columns} data={data} />}
    </>
  );
};
