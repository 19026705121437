import { Cargo, initCargo } from './cargo.type';
import { FinancialData, initFinancialData } from './financialData.type';
import { PayloadType } from './payloadType.type';
import { v4 as uuid } from 'uuid';

export interface Payload {
  id: string;
  type: PayloadType;
  paymentStatus: PaymentStatus;
  orderStatus: OrderStatus;
  ticketType: TicketType;
  refPayloadId: string | null;
  cruiseId: number | null;
  cruiseNo: string | null;
  movedToCruise: string | null;
  ticketNo: string;
  comment: string;
  amounts: FinancialData;
  tariffId: number | null;
  consignorId: number | null;
  consigneeId: number | null;
  cargo: Cargo;
  no: string;
  charge: boolean;
  adr: boolean;
  vinCode: string;
  model: string;
  length: number;
  weight: number;
  fullWeight: number;
  height: number;
  width: number;
  driverId: string | null;
  driver: Payload | null;
  driver2Id: string | null;
  driver2: Payload | null;
  sealNo: string;
  tirCarnetNo: string;
  cmrNo: string;
  trailerNos: string;
  trailerWeight: number;
  // Car fields
  chassisNo: string;
  trailerChassisNos: string;
  // Deck cargo fields
  deckCargoType: string;
  // Container fields
  empty: boolean;
  tareWeight: number;
  // Passenger fields
  name: string;
  surname: string;
  passport: string;
  personalId: string;
  birthDate: string | null;
  cabin: string | null;
  phoneCountryCode: string | null;
  phoneNo: string;
  email: string;
  sex: 'Male' | 'Female' | '';
  citizenshipId: string | null;
  // Animal fields
  animalCount: number;
}

// Payment Status
export type PaymentStatus = 'Unpaid' | 'Pending' | 'Paid';
export const paymentStatuses: PaymentStatus[] = ['Unpaid', 'Pending', 'Paid'];
export const getColor = (
  status: string | PaymentStatus
): 'default' | 'error' | 'success' | 'warning' | 'primary' => {
  switch (status) {
    case 'Unpaid':
      return 'warning';
    case 'Pending':
      return 'primary';
    case 'Paid':
      return 'success';
  }
  return 'default';
};

// Order Status
export type OrderStatus =
  | 'NotArrived'
  | 'UnderProcess'
  | 'Declaration'
  | 'Problematic'
  | 'InManifest'
  | 'Cancelled';
export const orderStatuses: OrderStatus[] = [
  'NotArrived',
  'UnderProcess',
  'Declaration',
  'Problematic',
  'InManifest',
  'Cancelled',
];
export const getOrderStatusColors = (
  status: string | OrderStatus
): { bg: string | undefined; fg: string | undefined } => {
  switch (status) {
    case 'NotArrived':
      return { bg: '#aa0503', fg: 'white' };
    case 'UnderProcess':
      return { bg: '#e6cef2', fg: '#6e4e7f' };
    case 'Declaration':
      return { bg: '#c1e1f8', fg: '#2a5170' };
    case 'Problematic':
      return { bg: '#ffe3a9', fg: '#5f4910' };
    case 'InManifest':
      return { bg: '#13754e', fg: 'white' };
    case 'Cancelled':
      return { bg: '#d32f2f', fg: 'white' };
  }
  return { bg: undefined, fg: undefined };
};

// Ticket Type
export type TicketType = 'OneWay' | 'TwoWay' | 'Return';
export const ticketTypes: TicketType[] = ['OneWay', 'TwoWay', 'Return'];

// Functions
export const initPayload = (
  cruiseId: number | null,
  cruiseNo: string | null,
  type: PayloadType
): Payload => ({
  id: uuid(),
  type,
  paymentStatus: 'Unpaid',
  orderStatus: 'NotArrived',
  ticketType: 'OneWay',
  refPayloadId: null,
  cruiseId,
  cruiseNo,
  movedToCruise: null,
  ticketNo: '',
  comment: '',
  amounts: initFinancialData(),
  tariffId: null,
  consignorId: null,
  consigneeId: null,
  cargo: initCargo(),
  no: '',
  charge: false,
  adr: false,
  vinCode: '',
  model: '',
  length: 0,
  weight: 0,
  fullWeight: 0,
  height: 0,
  width: 0,
  driverId: null,
  driver: null,
  driver2Id: null,
  driver2: null,
  sealNo: '',
  tirCarnetNo: '',
  cmrNo: '',
  trailerNos: '',
  trailerWeight: 0,
  // Car fields
  chassisNo: '',
  trailerChassisNos: '',
  // Deck cargo fields
  deckCargoType: '',
  // Container fields
  empty: false,
  tareWeight: 0,
  // Passenger fields
  name: '',
  surname: '',
  passport: '',
  personalId: '',
  birthDate: null,
  cabin: '',
  phoneCountryCode: '',
  phoneNo: '',
  email: '',
  sex: '',
  citizenshipId: null,
  // Animal fields
  animalCount: 0,
});
