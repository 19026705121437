import { FC, useState } from 'react';
import { CreateUpdateCruiseDto } from '../../types/cruise.type';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { AmountInput, CustomDatePicker } from 'src/components';
import { PortSelector } from 'src/features/cities';
import { ShipSelector } from './ShipSelector';

export const CruiseForm: FC<{
  initialValue: CreateUpdateCruiseDto;
  operation: 'Add' | 'Edit';
  saving: boolean;
  onSave: (company: CreateUpdateCruiseDto) => void;
  closeDialog: () => void;
}> = ({ initialValue, operation, saving, onSave, closeDialog }) => {
  const [cruise, setCruise] = useState<CreateUpdateCruiseDto>(initialValue);

  return (
    <>
      <Box
        px={1.5}
        py={1}
        display="flex"
        justifyContent="space-between"
        gap={3}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography variant="h6">{operation} Cruise</Typography>

        <IconButton onClick={closeDialog}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box py={1} px={4} display="flex" flexDirection="column">
        {operation === 'Add' && (
          <AmountInput
            label="No."
            value={cruise.no}
            onChange={(v) => setCruise({ ...cruise, no: v })}
            type="int"
          />
        )}

        <CustomDatePicker
          label="Departure Date"
          initialValue={cruise.date}
          onChange={(v) => setCruise({ ...cruise, date: v })}
        />

        <ShipSelector
          shipId={cruise.shipId}
          onChange={(v) => setCruise({ ...cruise, shipId: v || 0 })}
        />

        <CustomDatePicker
          label="Arrival Date"
          initialValue={cruise.arrivalDate}
          onChange={(v) => setCruise({ ...cruise, arrivalDate: v })}
          hideError
        />

        <PortSelector
          label="Departure Port"
          cityId={cruise.departurePortId}
          disabled
        />

        <PortSelector
          label="Destination Port"
          cityId={cruise.destinationPortId}
          disabled
        />
      </Box>

      <Box
        px={1.5}
        py={1}
        display="flex"
        justifyContent="flex-end"
        gap={1.5}
        borderTop={1}
        borderColor="divider"
      >
        <LoadingButton
          onClick={() => onSave(cruise)}
          endIcon={<SaveIcon />}
          loadingPosition="end"
          loading={saving}
          disabled={cruise.shipId == null}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};
