import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { FC } from 'react';
import { payloadTypes } from '../types/payloadType.type';
import { startCase } from 'lodash';

export const PayloadTypeSelector: FC<{
  payloadTypes: string[];
  setPayloadTypes: (payloadTypes: string[]) => void;
}> = ({ payloadTypes: types, setPayloadTypes }) => {
  const handleChange = (event: SelectChangeEvent<typeof types>) => {
    const {
      target: { value },
    } = event;
    setPayloadTypes(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormControl margin="none" sx={{ width: 135 }}>
      <Select
        multiple
        value={types}
        onChange={handleChange}
        renderValue={(selected) =>
          payloadTypes
            .filter((pt) => selected.includes(pt))
            .map((pt) => pt.substring(0, 2))
            .join(', ')
        }
      >
        {payloadTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox
              sx={{ width: 24, height: 24 }}
              size="small"
              checked={types.includes(name)}
            />
            <ListItemText primary={startCase(name)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
