import { today } from 'src/utils';

export interface Rate {
  date: string;
  usd_to_gel: number;
  gel_to_usd: number;
  eur_to_gel: number;
  gel_to_eur: number;
  eur_to_usd: number;
  usd_to_eur: number;
}

export const initRate = (date?: string | null): Rate => ({
  date: date || today(),
  usd_to_gel: 0,
  gel_to_usd: 0,
  eur_to_gel: 0,
  gel_to_eur: 0,
  eur_to_usd: 0,
  usd_to_eur: 0,
});
