import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { startCase } from 'lodash';
import { FC } from 'react';
import { orderStatuses } from 'src/features/orders';

export const OrderStatusesSelector: FC<{
  label?: string;
  disabled?: boolean;
  selectedOrderStatuses: string[];
  onChange: (selectedOrderStatuses: string[]) => void;
  margin?: 'none' | 'dense' | 'normal';
  width?: number | string;
}> = ({
  disabled,
  selectedOrderStatuses,
  onChange,
  margin,
  width = 180,
  label = 'Order Statuses',
}) => {
  return (
    <FormControl disabled={disabled} margin={margin} sx={{ width }}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        multiple
        label={label}
        value={selectedOrderStatuses}
        onChange={(e) => onChange(e.target.value as string[])}
        notched
        renderValue={(selected) =>
          orderStatuses
            .filter((os) => selected.includes(os))
            .map(startCase)
            .join(', ')
        }
      >
        {orderStatuses?.map((orderStatus) => (
          <MenuItem key={orderStatus} value={orderStatus}>
            <Checkbox
              sx={{ width: 24, height: 24 }}
              size="small"
              checked={selectedOrderStatuses.includes(orderStatus)}
            />
            <ListItemText primary={startCase(orderStatus)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
