import { FC, useState } from 'react';
import { getReport } from '../api/reports.api';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReportType, reportTypes } from '../types/report.type';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CloseIcon from '@mui/icons-material/Close';
import { GenericSelector } from 'src/components';
import { CompanySelector } from 'src/features/companies';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

export const InvoiceReportButton: FC<{
  orderId?: number;
  cruiseId?: number;
  type: 'E60Invoice' | 'E60PayerInvoice' | 'WagonTermFeesInvoice';
  size?: 'default' | 'small';
  useIconButton?: boolean;
  useFilledButton?: boolean;
}> = ({
  orderId,
  cruiseId,
  type,
  size = 'default',
  useIconButton,
  useFilledButton,
}) => {
  const [open, setOpen] = useState(false);

  const label = {
    E60Invoice: 'Invoice',
    E60PayerInvoice: 'Payer Invoice',
    WagonTermFeesInvoice: 'Wagon Payer Invoice',
  }[type];

  const buttonComponent = (
    <Button
      color="success"
      endIcon={<ReceiptLongIcon />}
      onClick={() => setOpen(true)}
      sx={{
        textTransform: 'none',
        minHeight: size === 'small' ? 24 : undefined,
        maxHeight: size === 'small' ? 24 : undefined,
      }}
    >
      {label}
    </Button>
  );

  const iconButtonComponent = (
    <IconButton color="success" onClick={() => setOpen(true)}>
      <Tooltip title={label}>
        <ReceiptLongIcon />
      </Tooltip>
    </IconButton>
  );

  const filledIconButtonComponent = (
    <Tooltip title={label}>
      <IconButton
        color="success"
        onClick={() => setOpen(true)}
        sx={{
          color: 'whitesmoke',
          bgcolor: 'success.main',
          ':hover': { bgcolor: 'success.dark' },
          height: 30,
          mt: 0.125,
        }}
      >
        <ReceiptLongOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {useIconButton
        ? useFilledButton
          ? filledIconButtonComponent
          : iconButtonComponent
        : buttonComponent}

      <Dialog open={open}>
        <DialogContent
          cruiseId={cruiseId}
          orderId={orderId}
          type={type}
          onClose={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
};

export const DialogContent: FC<{
  orderId?: number;
  cruiseId?: number;
  type: 'E60Invoice' | 'E60PayerInvoice' | 'WagonTermFeesInvoice';
  onClose: () => void;
}> = ({ orderId, cruiseId: initialCruiseId, type, onClose }) => {
  const [cruiseId] = useState(0);
  const [currency, setCurrency] = useState<'GEL' | 'USD' | 'EUR'>('GEL');
  const [reportType, setReportType] = useState<ReportType>('Preview');
  const [running, setRunning] = useState(false);
  const [companyId, setCompanyId] = useState<number | null>(null);

  const onRun = () => {
    setRunning(true);
    getReport(
      type,
      {
        orderId,
        cruiseId:
          type === 'E60Invoice' ? cruiseId || undefined : initialCruiseId,
        currency,
        companyId,
      },
      reportType
    )
      .catch(() => toast.error('Error while generating report'))
      .finally(() => setRunning(false));
  };

  return (
    <Box minWidth={250}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        py={1}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography variant="h6">
          {type === 'E60PayerInvoice' && 'Payer '}Invoice
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={1.5}
      >
        {/* {orderId && type === 'E60Invoice' && (
          <OrderRelatedCruisesSelector
            orderId={orderId}
            cruiseId={cruiseId}
            onCruiseIdChange={setCruiseId}
          />
        )} */}

        {initialCruiseId && type !== 'E60Invoice' && (
          <CompanySelector
            label="Payer"
            companyId={companyId}
            onCompanyIdChange={setCompanyId}
            hide={['INN', 'Email', 'Phone']}
            hideButtons
          />
        )}

        <GenericSelector
          label="Currency"
          fullWidth
          value={currency}
          onChange={(v) => setCurrency(v as 'GEL' | 'USD' | 'EUR')}
          options={['GEL', 'USD', 'EUR']}
        />

        <GenericSelector
          label="Format"
          fullWidth
          value={reportType}
          onChange={(v) => setReportType(v as ReportType)}
          options={reportTypes.filter((t) => t === 'Preview' || t === 'Pdf')}
        />
      </Box>

      <Box my={2} display="flex" justifyContent="center">
        <LoadingButton
          loadingPosition="end"
          endIcon={<ReceiptLongIcon />}
          loading={running}
          onClick={onRun}
          sx={{ textTransform: 'none' }}
          disabled={type !== 'E60Invoice' && !companyId}
        >
          Run
        </LoadingButton>
      </Box>
    </Box>
  );
};
