import { FC, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { updateField } from 'src/features/orders/api/orders.api';
import { KeyedMutator } from 'swr';
import { Cruise } from '../../types/cruise.type';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: { color: theme.palette.common.black },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 16,
  },
}));

export const EditableCell: FC<{
  label: string;
  payloadId: string;
  orderId: number;
  field: 'PayloadComment' | 'OrderComment';
  value: string | null;
  refetchCruises: KeyedMutator<Cruise>;
}> = ({ label, payloadId, orderId, field, value, refetchCruises }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveChanges = () => {
    setSaving(true);
    updateField({
      payloadId,
      orderId,
      field,
      value: inputValue,
    })
      .then(() => {
        refetchCruises();
        toast.success(label + ' updated!');
        setOpen(false);
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  const handleKeyDown = (event: any) => {
    if ((event.keyCode === 10 || event.keyCode === 13) && event.ctrlKey) {
      saveChanges();
    }
  };

  return (
    <>
      <BootstrapTooltip title={value} placement="left" disableInteractive>
        <Box
          onDoubleClick={() => setOpen(true)}
          sx={{
            userSelect: 'none',
            maxWidth: 200,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {value}
          </Box>
        </Box>
      </BootstrapTooltip>

      <Dialog open={open} onClose={() => !saving && setOpen(false)}>
        <Box minWidth={400} p={1}>
          <Typography variant="h6" textAlign="center" mb={1}>
            {label}
          </Typography>
          <TextField
            margin="none"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            fullWidth
            multiline
            sx={{ width: 400 }}
            minRows={10}
            onKeyDown={handleKeyDown}
            autoFocus
            onFocus={(e) => e.target.select()}
          />
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Tooltip title="Esc" placement="right">
              <Button
                disabled={saving}
                color="error"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title="Ctrl + Enter" placement="left">
              <LoadingButton
                startIcon={<SaveIcon />}
                onClick={saveChanges}
                loading={saving}
                loadingPosition="start"
              >
                Save
              </LoadingButton>
            </Tooltip>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
