import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enAuLocale from 'date-fns/locale/en-AU';
import type {} from '@mui/lab/themeAugmentation';
import { CustomToaster } from './components';
import { AppRouter } from './features/routes';

const theme = createTheme({
  components: {
    MuiCard: { defaultProps: { variant: 'outlined' } },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        margin: 'normal',
        sx: { width: 180 },
        inputProps: { autoComplete: 'off' },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
        margin: 'normal',
        sx: { width: 180 },
      },
    },
    MuiSkeleton: { defaultProps: { variant: 'rounded', height: 40 } },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        size: 'small',
      },
    },
    MuiTab: { defaultProps: { sx: { textTransform: 'none' } } },
    MuiTabPanel: { defaultProps: { sx: { p: 1 } } },
    MuiIconButton: { defaultProps: { size: 'small' } },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={enAuLocale}
        >
          <AppRouter />
        </LocalizationProvider>
        <CustomToaster />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
