import { Box, Button, Card, Divider, Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  UpperTextFieldRHF,
  AmountInputRHF,
  TextFieldRHF,
  CheckboxRHF,
  PhoneInputRHF,
} from 'src/components';
import { FinancialDataForm } from './FinancialDataForm';
import { PassengerFormFields } from './PassengerFormFields';
import { PayloadBreadcrumbs } from './PayloadBreadcrumbs';
import { CompanySelector } from 'src/features/companies';
import { TariffSelectorRHF } from 'src/features/tariffs';
import { initPayload } from '../../types/payload.type';
import { TicketTypeSelector } from '../order-references/TicketTypeSelector';
import { PaymentStatusInputRHF } from '../PaymentStatusInputRHF';
import { OrderStatusSelectorRHF } from '../OrderStatusSelector';

export const DeckCargoTruckForm: FC<{
  path: string;
  onRemove: () => void;
}> = ({ path, onRemove }) => {
  const { watch, setValue, getValues } = useFormContext();
  const [
    driverId,
    driver,
    driver2Id,
    driver2,
    cruiseId,
    cruiseNo,
    type,
    ticketType,
    orderStatus,
  ] = watch([
    `${path}.driverId`,
    `${path}.driver`,
    `${path}.driver2Id`,
    `${path}.driver2`,
    `${path}.cruiseId`,
    `${path}.cruiseNo`,
    `${path}.type`,
    `${path}.ticketType`,
    `${path}.orderStatus`,
  ]);

  // Update tare weight when truck weight or trailer weight changes
  useEffect(() => {
    const subscription = watch((_value, { name }) => {
      if (!name) return;

      if (name.includes('.weight') || name.includes('.trailerWeight')) {
        setValue(
          `${path}.tareWeight`,
          getValues(`${path}.weight`) + getValues(`${path}.trailerWeight`)
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, getValues, path]);

  const addDriver = (driverNumber: number) => {
    const driver = initPayload(cruiseId, cruiseNo, 'Passenger');
    if (driverNumber === 1) {
      setValue(`${path}.driver`, driver);
      setValue(`${path}.driverId`, driver.id);
    } else if (driverNumber === 2) {
      setValue(`${path}.driver2`, driver);
      setValue(`${path}.driver2Id`, driver.id);
    }
  };

  const removeDriver = (driverNumber: number) => {
    if (driverNumber === 1) {
      setValue(`${path}.driver`, null);
      setValue(`${path}.driverId`, null);
    } else if (driverNumber === 2) {
      setValue(`${path}.driver2`, null);
      setValue(`${path}.driver2Id`, null);
    }
  };

  const label =
    type === 'Truck'
      ? (driverId == null && driver2Id == null ? 'Deck ' : '') + 'Truck'
      : 'Deck Cargo';

  return (
    <Card sx={{ pb: 1 }}>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <PayloadBreadcrumbs path={path} label={label} />

        {type === 'Truck' && (
          <Stack direction="row" spacing={2}>
            <Button disabled={driverId != null} onClick={() => addDriver(1)}>
              Add Driver 1
            </Button>
            <Button
              disabled={driverId == null || driver2Id != null}
              onClick={() => removeDriver(1)}
              color="error"
              tabIndex={-1}
            >
              Remove Driver 1
            </Button>
            <Button
              disabled={driverId == null || driver2Id != null}
              onClick={() => addDriver(2)}
            >
              Add Driver 2
            </Button>
            <Button
              disabled={driver2Id == null}
              onClick={() => removeDriver(2)}
              color="error"
              tabIndex={-1}
            >
              Remove Driver 2
            </Button>
          </Stack>
        )}

        <Button onClick={onRemove} color="error" tabIndex={-1}>
          Remove
        </Button>
      </Box>

      <Divider />

      <Box display="flex" columnGap={1} px={2} flexWrap="wrap">
        <TextFieldRHF label="Ticket No" name={`${path}.ticketNo`} disabled />

        <TariffSelectorRHF
          payloadType="Truck"
          typePath={`${path}.type`}
          path={path}
          required
        />

        <FinancialDataForm
          path={`${path}.amounts`}
          freightRateRequired={
            ticketType !== 'Return' && orderStatus !== 'Cancelled'
          }
        />

        <PaymentStatusInputRHF path={path} />
        <OrderStatusSelectorRHF path={`${path}.orderStatus`} />

        <Controller
          name={`${path}.refPayloadId`}
          render={({ field: { value: refPayloadId } }) => (
            <Controller
              name={`${path}.ticketType`}
              render={({ field }) => (
                <TicketTypeSelector
                  value={field.value}
                  onChange={field.onChange}
                  refPayloadId={refPayloadId}
                />
              )}
            />
          )}
        />

        <TextFieldRHF label="Model" name={`${path}.model`} />
        <UpperTextFieldRHF
          label={type === 'Truck' ? 'Truck No' : 'No'}
          name={`${path}.no`}
          required
        />
        {type === 'Truck' && (
          <UpperTextFieldRHF label="Trailer No" name={`${path}.trailerNos`} />
        )}
        <UpperTextFieldRHF label="Vin code" name={`${path}.vinCode`} />

        <AmountInputRHF
          label="Length m."
          name={`${path}.length`}
          type="float"
          required
        />
        <AmountInputRHF
          label="Width m."
          name={`${path}.width`}
          type="float"
          required
        />
        <AmountInputRHF
          label="Height m."
          name={`${path}.height`}
          type="float"
        />

        <AmountInputRHF
          label="Truck weight"
          name={`${path}.weight`}
          type="float"
        />
        <AmountInputRHF
          label="Trailer weight"
          name={`${path}.trailerWeight`}
          type="float"
        />
        <AmountInputRHF
          label="Tare weight kg."
          name={`${path}.tareWeight`}
          type="float"
        />
        <AmountInputRHF
          label="Full weight"
          name={`${path}.fullWeight`}
          type="float"
        />

        <TextFieldRHF label="Cargo name" name={`${path}.cargo.name`} required />

        <AmountInputRHF
          label="Cargo amount"
          name={`${path}.cargo.amount`}
          type="int"
        />
        <AmountInputRHF
          label="Cargo gross weight kg."
          name={`${path}.cargo.grossWeight`}
          type="float"
        />
        <AmountInputRHF
          label="Cargo net weight kg."
          name={`${path}.cargo.netWeight`}
          type="float"
        />
        <AmountInputRHF
          label="Cargo volume m3."
          name={`${path}.cargo.volume`}
          type="float"
        />

        <TextFieldRHF
          label="Cargo unit of measure"
          name={`${path}.cargo.unitOfMeasure`}
        />

        <UpperTextFieldRHF label="Seal No" name={`${path}.sealNo`} />
        <UpperTextFieldRHF
          label="TIR car net No"
          name={`${path}.tirCarnetNo`}
        />
        <UpperTextFieldRHF label="CMR No" name={`${path}.cmrNo`} />

        <TextFieldRHF label="Type" name={`${path}.deckCargoType`} />

        <CheckboxRHF label="Charge" name={`${path}.charge`} />
        <CheckboxRHF label="ADR" name={`${path}.adr`} />

        <PhoneInputRHF
          phoneCountryCodePath={`${path}.phoneCountryCode`}
          phoneNoPath={`${path}.phoneNo`}
          required
        />

        <TextFieldRHF label="Comment" name={`${path}.comment`} />

        <Controller
          name={`${path}.consignorId`}
          render={({ field: { value, onChange } }) => (
            <CompanySelector
              label="Shipper"
              companyId={value}
              onCompanyIdChange={onChange}
              hide={['Email', 'Phone']}
            />
          )}
        />

        <Controller
          name={`${path}.consigneeId`}
          render={({ field: { value, onChange } }) => (
            <CompanySelector
              label="Receiver"
              companyId={value}
              onCompanyIdChange={onChange}
              hide={['Email', 'Phone']}
            />
          )}
        />
      </Box>

      {driverId && driver && (
        <>
          <Divider sx={{ mt: 2 }}>Driver 1</Divider>
          <PassengerFormFields path={`${path}.driver`} />
        </>
      )}

      {driver2Id && driver2 && (
        <>
          <Divider sx={{ mt: 2 }}>Driver 2</Divider>
          <PassengerFormFields path={`${path}.driver2`} />
        </>
      )}
    </Card>
  );
};
