import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { FC } from 'react';

export const CustomCheckbox: FC<{
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  width?: number;
  mt?: number;
}> = ({ label, checked, onChange, width = 180, mt = 1 }) => (
  <Box width={width} display="flex">
    <FormControlLabel
      sx={{ ml: -0.25, mt }}
      label={label}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
    />
  </Box>
);
