import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CompanyType, initCompany } from '../type/company.type';
import { Box, IconButton, Typography } from '@mui/material';
import { CheckboxRHF, TextFieldRHF, UpperTextFieldRHF } from 'src/components';
import { CountrySelector } from 'src/features/countries';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

export const CompanyForm: FC<{
  initialCompany?: CompanyType;
  operation: 'Add' | 'Edit';
  saving: boolean;
  onSave: (company: CompanyType) => void;
  closeDialog: () => void;
}> = ({ initialCompany, operation, saving, onSave, closeDialog }) => {
  const form = useForm<CompanyType>({
    defaultValues: initialCompany || initCompany(),
  });

  return (
    <FormProvider {...form}>
      <Box
        px={1.5}
        py={1}
        display="flex"
        justifyContent="space-between"
        gap={3}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography variant="h6">{operation} Company</Typography>

        <IconButton onClick={closeDialog}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <Box p={1.5} display="flex" columnGap={3} flexWrap="wrap">
        <Box display="flex" flexDirection="column">
          <UpperTextFieldRHF label="Name" name="name" width={350} />
          <UpperTextFieldRHF label="INN" name="companyId" />
          <TextFieldRHF label="Email" name="email" width={350} />
          <TextFieldRHF label="Phone" name="phone" />
          <TextFieldRHF label="Address" name="address" width={350} />
          <TextFieldRHF label="Agreement No" name="agreementNo" />

          <Controller
            name={'countryCode'}
            render={({ field }) => (
              <CountrySelector
                label="Country"
                countryId={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={1.75} mt={0.75}>
          <CheckboxRHF label="Is Payer" name="isPayer" />
          <CheckboxRHF label="Is Shipper" name="isConsignor" />
          <CheckboxRHF label="Is Receiver" name="isConsignee" />
        </Box>
      </Box>

      <Box
        px={1.5}
        py={1}
        display="flex"
        justifyContent="flex-end"
        gap={1.5}
        borderTop={1}
        borderColor="divider"
      >
        <LoadingButton
          onClick={() => onSave(form.getValues())}
          endIcon={<SaveIcon />}
          loadingPosition="end"
          loading={saving}
        >
          Save
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};
