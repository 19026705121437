import { Chip } from '@mui/material';
import { startCase } from 'lodash';
import { FC } from 'react';
import { getOrderStatusColors } from 'src/features/orders';

export const OrderStatusChip: FC<{ children: string }> = ({ children }) => {
  const { bg, fg } = getOrderStatusColors(children);
  return (
    <Chip
      size="small"
      label={startCase(children)}
      sx={{ backgroundColor: bg, color: fg }}
    />
  );
};
