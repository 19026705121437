import { PayloadType } from 'src/features/orders';

export interface Cruise {
  id: number;
  no: string;
  date: string;
  arrivalDate: string | null;
  blocked: boolean;
  departurePortId: number;
  departurePort: string;
  destinationPortId: number;
  destinationPort: string;
  shipId: number;
  ship: string;
  payloads: CruisePayload[];
  cargoLength: number;
  cargoCount: number;
  passengerCount: number;
  cargoLengthByOrderStatus: { [key: string]: number };
}

export const initCruise = (
  departurePortId: number,
  destinationPortId: number,
  shipId: number | null
): CreateUpdateCruiseDto => ({
  id: 0,
  no: 0,
  date: new Date().toISOString().substring(0, 10),
  arrivalDate: null,
  departurePortId,
  destinationPortId,
  shipId,
});

export interface ListOfWaiting {
  id: undefined;
  no: undefined;
  formattedNo: undefined;
  departurePortId: number;
  departurePort: string;
  destinationPortId: number;
  destinationPort: string;
  payloads: CruisePayload[];
}

export interface CruisePayload {
  type: PayloadType;
  id: string;
  cruiseId: number | null;
  cruiseNo: number | null;
  movedToCruise: any;
  orderId: number;
  orderNo: string;
  orderType: number;
  paymentStatus: string;
  orderStatus: string;
  createdById: number;
  createdByName: string;
  charterer: string;
  name: string | null;
  surname: string | null;
  fullName: string;
  document: string | null;
  cabin: string | null;
  isDriver: boolean;
  driverId: string | null;
  driver: string | null;
  driverPhone: string | null;
  driver2Id: string | null;
  driver2: string | null;
  driver2Phone: string | null;
  no: string | null;
  trailerNo: string | null;
  model: string | null;
  cargoName: string | null;
  waybillNo: string | null;
  cargoGNGName: string | null;
  cargoFullWeight: number;
  length: number;
  tariffName: string | null;
  totalAmount: number;
  ticketType: string | null;
  shipper: string | null;
  payloadComment: string | null;
  orderComment: string | null;
  phoneNumber: string | null;
}

export const cruisePassegers = (cruise: Cruise | ListOfWaiting) =>
  cruise.payloads.filter((p) => p.type === 'Passenger');

export const cruiseTrucks = (cruise: Cruise | ListOfWaiting) =>
  cruise.payloads.filter(
    (p) => p.type === 'Truck' && (p.driverId != null || p.driver2Id != null)
  );

export const cruiseCars = (cruise: Cruise | ListOfWaiting) =>
  cruise.payloads.filter((p) => p.type === 'Car' && p.driverId != null);

export const cruiseDeckCars = (cruise: Cruise | ListOfWaiting) =>
  cruise.payloads.filter((p) => p.type === 'Car' && p.driverId == null);

export const cruiseWagons = (cruise: Cruise | ListOfWaiting) =>
  cruise.payloads.filter((p) => p.type === 'Wagon');

export const cruiseContainers = (cruise: Cruise | ListOfWaiting) =>
  cruise.payloads.filter((p) => p.type === 'Container');

export const cruiseDeckCargo = (cruise: Cruise | ListOfWaiting) =>
  cruise.payloads.filter(
    (p) =>
      p.type === 'DeckCargo' ||
      (p.type === 'Car' && p.driverId == null) ||
      (p.type === 'Truck' && p.driverId == null && p.driver2Id == null)
  );

export const cruiseAllCargo = (cruise: Cruise | ListOfWaiting) =>
  cruise.payloads.filter((p) => p.type !== 'Passenger');

export interface GetCruiseListResponse {
  cruises: CruiseListDto[];
  totalCount: number;
}

export interface CruiseListDto {
  id: number;
  no: number;
  date: string;
  arrivalDate: string;
  ship: string;
}

export interface CreateUpdateCruiseDto {
  id: number;
  no: number;
  date: string;
  arrivalDate: string | null;
  departurePortId: number;
  destinationPortId: number;
  shipId: number | null;
}

export type CruiseListPageMode = 'Payloads' | 'Parkings';
