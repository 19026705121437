import { Box, Button, Card } from '@mui/material';
import { FC } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { Order } from '../../types/order.type';
import { PassengerForm } from './PassengerForm';
import { CarForm } from './CarForm';
import { DeckCargoTruckForm } from './DeckCargoTruckForm';
import { ContainerForm } from './ContainerForm';
import { WagonForm } from './WagonForm';
import { initPayload } from '../../types/payload.type';
import { PayloadType } from '../../types/payloadType.type';
import { startCase } from 'lodash';

const buttons: PayloadType[] = [
  'DeckCargo',
  'Container',
  'Wagon',
  'Car',
  'Passenger',
];

export const OrderFormItemList: FC<{
  control: Control<Order, any>;
  cruiseId: number | null;
  cruiseNo: string | null;
}> = ({ control, cruiseId, cruiseNo }) => {
  const {
    fields: payloads,
    prepend: addPayload,
    remove: removePayloads,
  } = useFieldArray({
    control: control,
    name: 'payloads',
  });

  return (
    <>
      <Card
        sx={{
          mt: -0.25,
          mb: 0.25,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Box px={2} py={1} display="flex" gap={2} flexWrap="wrap">
          {buttons.map((button) => (
            <Button
              key={button}
              onClick={() =>
                addPayload(initPayload(cruiseId, cruiseNo, button), {
                  shouldFocus: false,
                })
              }
            >
              {button === 'DeckCargo'
                ? 'Add Cargo'
                : `Add ${startCase(button)}`}
            </Button>
          ))}
        </Box>
      </Card>

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        py={2}
        bgcolor="grey.200"
      >
        {payloads.map((payload, index) => (
          <PayloadForm
            key={payload.id}
            path={`payloads.${index}`}
            onRemove={() => removePayloads(index)}
            type={payload.type}
          />
        ))}
      </Box>
    </>
  );
};

const PayloadForm: FC<{
  type: PayloadType;
  path: string;
  onRemove: () => void;
}> = ({ type, path, onRemove }) => {
  switch (type) {
    case 'Truck':
    case 'DeckCargo':
      return <DeckCargoTruckForm path={path} onRemove={onRemove} />;
    case 'Car':
      return <CarForm path={path} onRemove={onRemove} />;
    case 'Passenger':
      return <PassengerForm path={path} onRemove={onRemove} />;
    case 'Wagon':
      return <WagonForm path={path} onRemove={onRemove} />;
    case 'Container':
      return <ContainerForm path={path} onRemove={onRemove} />;
    default:
      return null;
  }
};
