import { FC } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { searchColumns } from '../types/searchColumns.type';

export const SearchColumnSelector: FC<{
  columns: string[];
  setColumns: (columns: string[]) => void;
}> = ({ columns, setColumns }) => {
  const handleChange = (event: SelectChangeEvent<typeof columns>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const newColumns = typeof value === 'string' ? value.split(',') : value;
    setColumns(searchColumns.filter((column) => newColumns.includes(column)));
  };

  return (
    <FormControl margin="dense">
      <InputLabel>Columns</InputLabel>
      <Select
        multiple
        value={columns}
        onChange={handleChange}
        input={<OutlinedInput label="Columns" />}
        renderValue={(selected) => selected.join(', ')}
      >
        {searchColumns.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox
              sx={{ width: 24, height: 24 }}
              size="small"
              checked={columns.includes(name)}
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
