import { useState } from 'react';

export const usePreviousValidValue = <T>(current: T): T => {
  const [previous, setPrevious] = useState<T>(current);

  if (current != null && current !== previous) {
    setPrevious(current);
  }

  return previous;
};
