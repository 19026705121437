import { Box, Card, Divider, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { LoginRequest } from '../types/auth.types';
import { login } from '../api/auth.api';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';
import { auth } from '../utils/auth.utils';
import { LoadingButton } from '@mui/lab';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';

export const LoginPage: FC<{}> = () => {
  const [creds, setCreds] = useState<LoginRequest>({
    userName: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    login(creds)
      .then((data) => auth.login(data))
      .catch((err) => toast.error(getErrorMessage(err)))
      .finally(() => setLoading(false));
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <Card sx={{ width: 300 }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <DirectionsBoatFilledIcon />
          <Typography textAlign="center" variant="h5" sx={{ ml: 1, my: 2 }}>
            Ferry UI
          </Typography>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" p={3} gap={3}>
          <TextField
            margin="none"
            label="Login"
            fullWidth
            sx={{ width: 'auto' }}
            InputLabelProps={{ shrink: true }}
            value={creds.userName}
            onChange={(e) => setCreds({ ...creds, userName: e.target.value })}
            onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
          />
          <TextField
            margin="none"
            label="Password"
            type="password"
            fullWidth
            sx={{ width: 'auto' }}
            InputLabelProps={{ shrink: true }}
            value={creds.password}
            onChange={(e) => setCreds({ ...creds, password: e.target.value })}
            onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
          />

          <LoadingButton loading={loading} onClick={handleLogin}>
            Login
          </LoadingButton>
        </Box>
      </Card>
    </Box>
  );
};
