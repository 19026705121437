import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTariffs } from '../hooks/useTariffs.hook';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { PayloadType, TicketType } from 'src/features/orders';
import toast from 'react-hot-toast';
import { Tariff, TariffFinancialData } from '../types/tariff.type';

const getTariffFinancialData = (
  tariff: Tariff,
  depPortId: number,
  ticketType: TicketType
): TariffFinancialData => {
  if (ticketType === 'TwoWay') return tariff.twoWayTariffs;
  else if (depPortId === 1 || depPortId === 2) return tariff.geoTariffs;
  else return tariff.eurTariffs;
};

export const TariffSelectorRHF: FC<{
  payloadType: PayloadType;
  path: string;
  typePath?: string;
  required?: boolean;
}> = ({ payloadType, path, typePath, required }) => {
  const { data } = useTariffs();
  const { getValues, setValue, watch } = useFormContext();

  const ApplyTariff = async (tariffId: number | null) => {
    const tariff = data?.find((t) => t.id === tariffId);

    if (!tariff) return;

    const depPortId = getValues('departurePortId');
    const ticketType = getValues(`${path}.ticketType`);
    const tariffAmounts = getTariffFinancialData(tariff, depPortId, ticketType);

    setValue(`${path}.amounts.freight`, tariffAmounts.freight);
    setValue(`${path}.amounts.terminalDepPort`, tariffAmounts.terminalDepPort);
    setValue(`${path}.amounts.terminalDesPort`, tariffAmounts.terminalDesPort);

    setValue(`${path}.length`, tariff.length);
    setValue(`${path}.width`, tariff.width);
    setValue(`${path}.height`, tariff.height);

    // Change payload type based on selected Tariff for DeckCargo and Truck
    if (
      (payloadType === 'DeckCargo' || payloadType === 'Truck') &&
      typePath &&
      path
    ) {
      setValue(typePath, tariff.type);
      // Clear driver if type is set to DeckCargo
      if (tariff.type === 'DeckCargo') {
        setValue(`${path}.driver`, null);
        setValue(`${path}.driverId`, null);
        setValue(`${path}.driver2`, null);
        setValue(`${path}.driver2Id`, null);
      }
    }

    toast.success('Tariff Applied!');
  };

  // Apply tariff when ticketType changes
  const ticketType = watch(`${path}.ticketType`);
  const [previousTicketType, setPreviousTicketType] =
    useState<TicketType>(ticketType);
  useEffect(() => {
    if (previousTicketType !== ticketType) {
      setPreviousTicketType(ticketType);
      const tariffId = getValues(`${path}.tariffId`);
      ApplyTariff(tariffId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketType]);

  if (data == null) return <Skeleton sx={{ mt: 2 }} width={368} />;

  return (
    <Controller
      name={`${path}.tariffId`}
      render={({ field: { value, onChange } }) => (
        <Box display="flex" alignItems="center" width={368}>
          {/* Selector */}
          <FormControl fullWidth sx={{ width: '100%' }}>
            <InputLabel shrink>Tariff</InputLabel>
            <Select
              label="Tariff"
              value={value || ''}
              onChange={(e) => {
                const tariffId = (e.target.value as number) || null;
                onChange(tariffId);
                ApplyTariff(tariffId);
              }}
              notched
              error={required && !value}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              {data
                .filter((d) => {
                  if (payloadType === 'DeckCargo' || payloadType === 'Truck')
                    return d.type === 'DeckCargo' || d.type === 'Truck';
                  else return d.type === payloadType;
                })
                .map((tariff) => (
                  <MenuItem key={tariff.id} value={tariff.id}>
                    {tariff.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )}
    />
  );
};
