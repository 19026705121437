import { FC } from 'react';
import { Box } from '@mui/material';
import { CruiseDirections } from './CruiseDirections';
import { CruiseList } from './CruiseList';
import { Loading } from 'src/components';
import { useCruiseDirections } from '../../hooks/useCruiseDirections.hook';
import { ShipsToggle } from './ShipsToggle';
import { useShipsToggle } from '../../hooks/useShipsToggle.hook';
import { CruiseListPageMode } from '../../types/cruise.type';

export const CruiseListPage: FC<{ mode: CruiseListPageMode }> = ({
  mode = 'Payloads',
}) => {
  const {
    directions,
    directionsLoading,
    selectedDirectionId,
    setSelectedDirectionId,
    selectedDirection,
  } = useCruiseDirections();

  const {
    ships,
    shipsLoading,
    selectedShipId,
    setSelectedShipId,
    selectedShip,
  } = useShipsToggle();

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" gap={1.5}>
      <Box flexShrink={0} display="flex" flexDirection="column" gap={1.5}>
        <ShipsToggle
          ships={ships}
          shipId={selectedShipId}
          setShipId={setSelectedShipId}
        />
        <CruiseDirections
          dirs={directions}
          dirId={selectedDirectionId}
          setDirId={setSelectedDirectionId}
        />
      </Box>

      <Box flexGrow={1}>
        {directionsLoading || shipsLoading ? (
          <Loading />
        ) : (
          <CruiseList
            selectedDir={selectedDirection}
            selectedShip={selectedShip}
            mode={mode}
          />
        )}
      </Box>
    </Box>
  );
};
