import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRUD } from 'src/hooks';
import {
  createAccessGroup,
  getAccessGroup,
  updateAccessGroup,
} from '../api/accessGroups.api';
import { AccessGroup, initAccessGroup } from '../types/accessGroup.type';
import toast from 'react-hot-toast';
import { getErrorMessage, is404Error } from 'src/utils';
import { Alert } from '@mui/material';
import { Loading } from 'src/components';
import { AccessGroupForm } from './AccessGroupForm';
import { routes } from 'src/features/routes';

export const EditAccessGroupPage: FC<{}> = () => {
  const id = +(useParams().id || -1);

  const { loading, data, save, saving, refetch, error } = useRUD(
    () => getAccessGroup(id),
    (accessGroup: AccessGroup) =>
      updateAccessGroup(accessGroup)
        .then((data) => {
          toast.success('Access Group updated!');
          return data;
        })
        .catch((e) => {
          toast.error(getErrorMessage(e));
          return e;
        })
  );

  if (is404Error(error))
    return (
      <Alert variant="filled" severity="error">
        Access Group was not found! It may have been deleted.
      </Alert>
    );

  if (loading || data == null) return <Loading />;

  return (
    <AccessGroupForm
      initialValue={data}
      save={save}
      loading={saving}
      refetch={refetch}
    />
  );
};

export const CreateAccessGroupPage: FC<{}> = () => {
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const save = async (user: AccessGroup) => {
    setSaving(true);
    createAccessGroup(user)
      .then(() => {
        toast.success('Access Group created!');
        navigate(routes.access, { replace: true });
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  return (
    <AccessGroupForm
      initialValue={initAccessGroup()}
      save={save}
      loading={saving}
    />
  );
};
