import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { ReactElement } from 'react';

export const GenericMultiSelector: <T extends string | number>(p: {
  values: T[];
  onChange: (values: T[]) => void;
  width?: number | string;
  options: T[];
}) => ReactElement = ({ values, onChange, width, options }) => (
  <FormControl margin="none" sx={{ width }}>
    {/* <InputLabel shrink>{label}</InputLabel> */}
    <Select
      multiple
      // label={label}
      value={values}
      onChange={(e) => onChange(e.target.value as typeof values)}
      notched
      renderValue={(selected) =>
        options.filter((o) => selected.includes(o)).join(', ')
      }
    >
      {options?.map((value) => (
        <MenuItem key={value} value={value}>
          <Checkbox
            sx={{ width: 24, height: 24 }}
            size="small"
            checked={values.includes(value)}
          />
          <ListItemText primary={value} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
