import { axiosInstance } from 'src/config/axios.config';
import { AccessGroup, AccessGroupListItem } from '../types/accessGroup.type';

// Access Groups
export const getAccessGroups = async (): Promise<AccessGroupListItem[]> =>
  (await axiosInstance.get<AccessGroupListItem[]>('/api/accessGroups')).data;

export const getAccessGroup = async (id: number): Promise<AccessGroup> =>
  (await axiosInstance.get(`/api/accessGroups/${id}`)).data;

export const createAccessGroup = async (
  accessGroup: AccessGroup
): Promise<AccessGroup> =>
  (await axiosInstance.post('/api/accessGroups', accessGroup)).data;

export const updateAccessGroup = async (
  accessGroup: AccessGroup
): Promise<AccessGroup> =>
  (await axiosInstance.put(`/api/accessGroups`, accessGroup)).data;

export const deleteAccessGroup = async (id: number): Promise<void> =>
  (await axiosInstance.delete(`/api/accessGroups/${id}`)).data;
