import { FC } from 'react';
import { AccessGroupList } from './AccessGroupList';
import { UserList } from './UserList';
import Grid from '@mui/material/Unstable_Grid2';

export const AccessPage: FC<{}> = () => {
  return (
    <Grid container rowSpacing={4} columnSpacing={1.5}>
      <Grid xs={12} md={3.5}>
        <AccessGroupList />
      </Grid>
      <Grid xs={12} md={8.5}>
        <UserList />
      </Grid>
    </Grid>
  );
};
