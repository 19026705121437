import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState } from 'react';
import { auth } from 'src/features/auth';
import { Box, Divider } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

export const NavBarUserMenuButton: FC<{}> = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);
  const closeMenu = () => setAnchorElUser(null);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      width={{ xs: undefined, md: 130 }}
    >
      <IconButton onClick={openMenu} color="inherit" sx={{ p: 0 }}>
        <Avatar
          sx={{
            height: 30,
            width: 30,
            fontSize: '0.8rem',
            bgcolor: 'secondary.main',
          }}
        >
          {getInitials(auth.getUser())}
        </Avatar>
      </IconButton>

      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorElUser)}
        onClose={closeMenu}
        sx={{ mt: 1.25, textAlign: 'center' }}
      >
        <MenuItem
          disableRipple
          sx={{
            ':hover': { backgroundColor: 'transparent' },
            justifyContent: 'center',
            cursor: 'default',
            my: -0.5,
          }}
        >
          {auth.getUser()}
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => {
            auth.logout();
            closeMenu();
          }}
        >
          <LogoutIcon sx={{ mr: 1 }} />
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

function getInitials(fullName: string | null) {
  if (!fullName) return '';

  const names = fullName.split(' ');

  let initials = '';

  if (names.length > 0) {
    initials = names[0].substring(0, 1).toUpperCase();
  }

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
}
