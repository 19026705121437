import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { PhoneSelector } from '../selectors/PhoneSelector';
import { TextFieldRHF } from './TextFieldRHF';

export const PhoneInputRHF: FC<{
  phoneCountryCodePath: string;
  phoneNoPath: string;
  required?: boolean;
}> = ({ phoneCountryCodePath, phoneNoPath, required }) => (
  <>
    <Controller
      name={phoneCountryCodePath}
      render={({ field }) => (
        <PhoneSelector value={field.value} onChange={field.onChange} />
      )}
    />
    <TextFieldRHF label="Phone Number" name={phoneNoPath} required={required} />
  </>
);
