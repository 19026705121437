import { Box, Button, Card, Divider } from '@mui/material';
import { FC } from 'react';
import { PassengerFormFields } from './PassengerFormFields';
import { PayloadBreadcrumbs } from './PayloadBreadcrumbs';

export const PassengerForm: FC<{ path: string; onRemove?: () => void }> = ({
  onRemove,
  path,
}) => {
  return (
    <Card sx={{ pb: 1 }}>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <PayloadBreadcrumbs path={path} label="Passenger" />

        {onRemove && (
          <Button onClick={onRemove} color="error" tabIndex={-1}>
            Remove
          </Button>
        )}
      </Box>

      <Divider />

      <PassengerFormFields path={path} />
    </Card>
  );
};
