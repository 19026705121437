import { Dialog } from '@mui/material';
import { FC, useState } from 'react';
import { MoreButton } from 'src/components';
import { DeleteCruiseDialog } from './DeleteCruiseDialog';
import { CruiseListDto } from '../../types/cruise.type';
import { EditCruiseDialogContent } from './EditCruiseDialogContent';

export const MoreCruiseButton: FC<{
  refresh: () => void;
  cruise: CruiseListDto;
}> = ({ refresh, cruise }) => {
  // Edit Dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  // Delete Dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <>
      <MoreButton
        onEdit={() => setEditDialogOpen(true)}
        onDelete={() => setDeleteDialogOpen(true)}
      />

      <Dialog open={editDialogOpen}>
        <EditCruiseDialogContent
          cruiseId={cruise.id}
          closeDialog={() => setEditDialogOpen(false)}
          refresh={refresh}
        />
      </Dialog>

      <DeleteCruiseDialog
        cruise={cruise}
        refetch={refresh}
        open={deleteDialogOpen}
        closeDialog={() => setDeleteDialogOpen(false)}
      />
    </>
  );
};
