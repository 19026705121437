import { Box, Button, Card, TablePagination, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { routes } from 'src/features/routes';
import { capitalize } from '../../utils/cruise.utils';
import { Link } from 'react-router-dom';
import { CruiseDirection } from '../../types/direction.type';
import { Ship } from '../../types/ship.type';

export const CruiseListToolbar: FC<{
  selectedDir: CruiseDirection | undefined;
  selectedShip: Ship | undefined;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  size: number;
  setSize: Dispatch<SetStateAction<number>>;
  totalCount: number | undefined;
}> = ({
  selectedDir,
  selectedShip,
  page,
  setPage,
  size,
  setSize,
  totalCount: initialTotalCount,
}) => {
  // Total count for pagination
  const [totalCount, setTotalCount] = useState(initialTotalCount || -1);
  useEffect(() => {
    if (initialTotalCount) setTotalCount(initialTotalCount);
  }, [initialTotalCount]);

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 1,
        py: 0.5,
        mb: 1.5,
      }}
    >
      <Box width={140}>
        {selectedDir && (
          <Button
            component={Link}
            to={routes.listOfWaiting(
              selectedDir.depPortId,
              selectedDir.desPortId
            )}
          >
            List Of Waiting
          </Button>
        )}
      </Box>

      <Box display="flex" alignItems="center" color="black">
        {selectedDir ? (
          <>
            <Typography variant="h6">
              {capitalize(selectedDir?.depPortName)}
            </Typography>

            <ArrowForwardIcon fontSize="small" sx={{ mx: 0.5 }} />

            <Typography variant="h6">
              {capitalize(selectedDir?.desPortName)}
            </Typography>
          </>
        ) : (
          <Typography variant="h6">All Directions</Typography>
        )}
      </Box>

      <Typography variant="h6">{selectedShip?.name || 'All Ships'}</Typography>

      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={(_e, page) => setPage(page)}
        rowsPerPage={size}
        onRowsPerPageChange={(e) => setSize(+e.target.value)}
        labelRowsPerPage="Rows:"
        rowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
        showFirstButton
        showLastButton
      />
    </Card>
  );
};
