import { FC, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {
  AmountInput,
  CustomDatePicker,
  GenericSelector,
  Loading,
} from 'src/components';
import { getReport } from 'src/features/reports/api/reports.api';
import { useRate } from '../hooks/useRate.hook';
import { Bill, initBill } from '../types/bill.types';
import { Rate } from '../types/rates.types';
import { MoneyChip } from './MoneyChip';
import {
  getCommissionRate,
  getRemainingAmount,
  TransferType,
  transferTypes,
} from '../types/finances.types';
import { getBillRecord, saveBillRecord } from '../api/bill.api';
import { PortSelector } from 'src/features/cities';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

export const BillDialog: FC<{
  open: boolean;
  onClose: () => void;
  payloadId: string;
}> = ({ open, onClose, payloadId }) => {
  const [bill, setBill] = useState(initBill());

  const [isSaving, setIsSaving] = useState(false);

  const fetchBillData = async () => {
    try {
      const fetchedBill = await getBillRecord(payloadId);
      setBill(fetchedBill);
    } catch (err) {
      toast.error('Failed to load bill data');
    }
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await saveBillRecord(payloadId, bill);
      toast.success('Bill saved successfully');
    } catch (err) {
      toast.error('Error saving bill');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (open && payloadId) {
      fetchBillData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, payloadId]);

  const { rate, rateLoading, rateError } = useRate(bill.transactionDate);

  const credit = bill.credit;
  const totalInGel =
    bill.eurAmount * rate.eur_to_gel +
    bill.usdAmount * rate.usd_to_gel +
    bill.gelAmount;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Box sx={{ height: 580, width: 600 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSave}
            loading={isSaving}
            endIcon={<SaveIcon />}
            loadingPosition="end"
          >
            Save
          </LoadingButton>
          <Typography variant="h6" component="div" textAlign="center">
            {bill.ticketNo}
          </Typography>

          <Button
            variant="outlined"
            size="small"
            onClick={() => getReport('E60Bill', { payloadId }, 'Preview')}
          >
            Print
          </Button>
        </Box>

        <Divider />

        <Box sx={{ m: 2 }}>
          <Box display="flex" justifyContent={'space-between'}>
            <TextField
              label="Agent"
              value={bill.agentName}
              InputProps={{ readOnly: true }}
              variant="outlined"
              disabled
            />
            <TextField
              label="Payload No"
              value={bill.payloadNumber}
              InputProps={{ readOnly: true }}
              variant="outlined"
              disabled
            />
            <TextField
              label="Total GEL"
              value={totalInGel.toFixed(2)}
              InputProps={{ readOnly: true }}
              variant="outlined"
              disabled
            />
          </Box>

          <Typography variant="h6" textAlign="center">
            Transfers
          </Typography>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <PortSelector
                label="Place"
                cityId={bill.transactionPlaceId}
                onChange={(placeId) =>
                  setBill({ ...bill, transactionPlaceId: placeId })
                }
              />
              <Box width={16} />
              <CustomDatePicker
                label="Transaction Date"
                key={bill.transactionDate}
                initialValue={bill.transactionDate}
                onChange={(transactionDate) =>
                  setBill({ ...bill, transactionDate })
                }
              />
              <Box width={16} />

              <GenericSelector
                label="Transaction Type"
                value={bill.transactionType}
                onChange={(value) =>
                  setBill({ ...bill, transactionType: value as TransferType })
                }
                options={transferTypes}
              />
            </Box>

            {rateLoading ? (
              <Box sx={{ mt: 7 }}>
                <Loading />
              </Box>
            ) : rateError ? (
              <Alert variant="filled" severity="error" sx={{ mt: 2 }}>
                {rateError}
              </Alert>
            ) : (
              <BillAmounts
                bill={bill}
                setBill={setBill}
                rate={rate}
                credit={credit}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const BillAmounts: FC<{
  bill: Bill;
  setBill: (bill: Bill) => void;
  rate: Rate;
  credit: number;
}> = ({ bill, setBill, rate, credit }) => {
  // Dynamic calculations
  const debit =
    bill.eurAmount +
    bill.usdAmount * rate.usd_to_eur +
    bill.gelAmount * rate.gel_to_eur;

  const balance =
    debit * (1 - getCommissionRate(bill.transactionType)) + bill.debit - credit;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" gap={2}>
        <TextField label="Credit" value={credit.toFixed(2)} disabled />
        <TextField
          label="Debit"
          value={(
            bill.debit +
            debit * (1 - getCommissionRate(bill.transactionType))
          ).toFixed(2)}
          disabled
        />
        <TextField
          label="Commission"
          value={(debit * getCommissionRate(bill.transactionType)).toFixed(2)}
          disabled
        />
        <Box>
          <MoneyChip label="Balance" amount={balance} mt={2.5} decimals={4} />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={2}>
        {/* EUR Row */}
        <Box display="flex" alignItems="center" gap={1}>
          <AmountInput
            label="Amount"
            value={bill.eurAmount}
            onChange={(eurAmount) => setBill({ ...bill, eurAmount })}
            type="float"
          />
          <TextField sx={{ visibility: 'hidden' }} />
          <TextField
            label="Remaining"
            value={getRemainingAmount(
              balance,
              bill.transactionType,
              'EUR',
              rate
            ).toFixed(4)}
            InputLabelProps={{ shrink: true }}
            disabled
          />
          <TextField label="Currency" value="EUR" disabled />
        </Box>

        {/* USD Row */}
        <Box display="flex" alignItems="center" gap={1}>
          <AmountInput
            label="Amount"
            value={bill.usdAmount}
            onChange={(usdAmount) => setBill({ ...bill, usdAmount })}
            type="float"
          />
          <TextField label="Rate" value={rate.eur_to_usd} disabled />
          <TextField
            label="Remaining"
            value={getRemainingAmount(
              balance,
              bill.transactionType,
              'USD',
              rate
            ).toFixed(4)}
            disabled
          />
          <TextField label="Currency" value="USD" disabled />
        </Box>

        {/* GEL Row */}
        <Box display="flex" alignItems="center" gap={1}>
          <AmountInput
            label="Amount"
            value={bill.gelAmount}
            onChange={(gelAmount) => setBill({ ...bill, gelAmount })}
            type="float"
          />
          <TextField label="Rate" value={rate.eur_to_gel} disabled />
          <TextField
            label="Remaining"
            value={getRemainingAmount(
              balance,
              bill.transactionType,
              'GEL',
              rate
            ).toFixed(4)}
            disabled
          />
          <TextField label="Currency" value="GEL" disabled />
        </Box>
      </Box>
    </Box>
  );
};
