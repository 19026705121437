import { FC } from 'react';
import useSWR from 'swr';
import { getCountries } from '../api/countries.api';
import { Autocomplete, Box, TextField } from '@mui/material';
import { Country } from '../types/country.type';

export const CountrySelector: FC<{
  label?: string;
  countryId: string | null;
  onChange: (countryId: string | null) => void;
}> = ({ countryId, onChange, label }) => {
  const { data } = useSWR('/api/countries', getCountries);

  const valueObj = data?.find((country) => country.code === countryId) || null;

  return (
    <Autocomplete
      value={valueObj}
      onChange={(_event: any, newValue: Country | null) =>
        onChange(newValue?.code || null)
      }
      options={data || []}
      autoHighlight
      getOptionLabel={(o) => `${o.code} - ${o.name}`}
      renderOption={(props, o) => (
        <Box component="li" {...props}>
          {o.code} - {o.name}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label={label} />}
      // componentsProps={{ paper: { sx: { width: 360 } } }}
    />
  );
};
