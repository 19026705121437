import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useCruiseParkings } from '../hooks/useCruiseParkings.hook';
import { is404Error } from 'src/utils';
import { Alert, Card, Divider } from '@mui/material';
import { Loading } from 'src/components';
import { CruiseParkingsHeader } from './CruiseParkingsHeader';
import { CruiseParkingsList } from './CruiseParkingsList';

export const CruiseParkingsPage: FC<{}> = () => {
  const cruiseId = +(useParams().cruiseId || -1);
  const { cruiseParkings, error, refetchCruiseParkings } =
    useCruiseParkings(cruiseId);

  if (is404Error(error))
    return (
      <Alert variant="filled" severity="error">
        Cruise was not found! It may have been deleted.
      </Alert>
    );

  if (!cruiseParkings) return <Loading />;

  return (
    <Card>
      <CruiseParkingsHeader cruiseParkings={cruiseParkings} />

      <Divider />

      <CruiseParkingsList
        cruiseParkings={cruiseParkings.payloadParkings}
        refreshCruiseParkings={refetchCruiseParkings}
      />
    </Card>
  );
};
