import { PayloadType } from 'src/features/orders';
import { searchColumns } from './searchColumns.type';

export interface Search {
  paging: SearchPaging;
  filters: SearchFilters;
  visibleColumns: string[];
  showDeleted: boolean;
}

export interface SearchPaging {
  page: number;
  size: number;
}

export interface SearchFilters {
  payloadTypes: PayloadType[];
  cruiseNo: string | null;
  cruiseDate: SearchRangeFilterType;
  shipId: number | null;
  departurePortId: number | null;
  destinationPortId: number | null;
  orderNo: string | null;
  orderDate: SearchRangeFilterType;
  orderStatus: string | null;
  orderComment: string | null;
  paymentStatus: string | null;
  ticketType: string | null;
  chartererId: number | null;
  createdById: number | null;
  no: string;
  name: string;
  surname: string;
  passport: string;
  personalId: string;
  shipper: string;
  freightRate: number;
  tariffId: number | null;
  phoneNumber: string;
}

export interface SearchRangeFilterType {
  range: boolean;
  value: string;
  fromValue: string;
  toValue: string;
}

export const initSearch = (): Search => ({
  paging: { page: 0, size: 15 },
  filters: {
    payloadTypes: [],
    cruiseNo: '',
    cruiseDate: initSearchRangeFilter(),
    shipId: null,
    departurePortId: null,
    destinationPortId: null,
    orderNo: '',
    orderDate: initSearchRangeFilter(),
    orderStatus: '',
    orderComment: '',
    paymentStatus: '',
    ticketType: '',
    chartererId: null,
    createdById: null,
    no: '',
    name: '',
    surname: '',
    passport: '',
    personalId: '',
    shipper: '',
    freightRate: 0,
    tariffId: null,
    phoneNumber: '',
  },
  visibleColumns: searchColumns,
  showDeleted: false,
});

const initSearchRangeFilter = (): SearchRangeFilterType => ({
  range: false,
  value: '',
  fromValue: '',
  toValue: '',
});
