export interface SearchResponse {
  data: SearchResponseDataItem[];
  totalCount: number;
}

export interface SearchResponseDataItem {
  id: string;
  payloadType: string;
  cruiseId: number;
  cruiseNo: number;
  cruiseDate: string;
  ship: string;
  departurePort: string;
  destinationPort: string;
  orderId: number;
  orderNo: string;
  orderStatus: string;
  orderComment: string;
  paymentStatus: string;
  ticketType: string;
  orderDate: string;
  createdBy: string;
  charterer: string;
  isDeleted: boolean;
  no: string;
  name: string;
  surname: string;
  passport: string;
  personalId: string;
  shipper: string;
  freightRate: number;
  tariff: string;
  phoneNumber: string;
}

export const initSearchResponse = (): SearchResponse => ({
  data: [],
  totalCount: -1,
});
