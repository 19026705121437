export const getErrorMessage = (error: any): string => {
  if (error?.response?.status === 403) return 'Permission Denied!';
  else return error?.response?.data?.title;
};

export const getDuplicatePayloadError = (
  error: any
): {
  errorText: string;
  originalOrderId: number;
} => {
  return {
    errorText: error?.response?.data?.title,
    originalOrderId: error?.response?.data?.originalOrderId,
  };
};

export const is404Error = (error: any): boolean =>
  error && error?.response?.status === 404;
