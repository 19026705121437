import { useEffect, useState } from 'react';

export const useRUD = <T>(
  fetcher: () => Promise<T>,
  updater?: (data: T) => Promise<T>,
  deps?: any[]
) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [saving, setSaving] = useState(false);

  const save = (newData?: T) => {
    if (!updater) throw new Error('Saver was not passed as a parameter!');
    if (!data) throw new Error('Data is not set!');

    setSaving(true);

    return updater(newData || data)
      .then(() => {
        fetcher().then((data) => setData(data));
      })
      .catch((error) => setError(error))
      .finally(() => setSaving(false));
  };

  const fetchData = () => {
    setLoading(true);
    setError(null);
    setData(null);

    fetcher()
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);

  return { data, setData, loading, error, saving, save, refetch: fetchData };
};
