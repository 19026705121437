import { Box, Button, Card, Divider, Typography, Tooltip } from '@mui/material';
import { FC, useEffect } from 'react';
import { Order } from '../types/order.type';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { OrderFormHeaderFields } from './OrderFormHeaderFields';
import { OrderFormItemList } from './order-items/OrderFormItemList';
import { OrderFormSaveButton } from './OrderFormSaveButton';
import { OrderChangeDirectionButton } from './OrderChangeDirectionButton';
import { DeleteOrderButton } from './DeleteOrderButton';
import { GenericInvoiceReportButton } from 'src/features/reports';
import { FilesButton } from 'src/features/files';
import { AddReturnOrderButton } from './order-references/AddReturnOrderButton';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { usePayloadsCount } from '../hooks/usePayloadsCount.hook';

export const OrderForm: FC<{
  isNew: boolean;
  order: Order;
  save: (newData: Order) => void;
  loading: boolean;
  refetch?: () => void;
}> = ({ isNew, order, save, loading, refetch }) => {
  const form = useForm<Order>({ defaultValues: order });
  const { reset } = form;

  const payloadsCount = usePayloadsCount(form);

  useEffect(() => {
    if (!isNew) reset(order);
  }, [order, reset, isNew]);

  return (
    <FormProvider {...form}>
      <Card>
        <Box display="flex" justifyContent="space-between" px={2} py={1}>
          <Typography variant="h6" mr={4}>
            {isNew ? 'New Order' : order.no}
          </Typography>

          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            flexGrow={1}
            gap={2}
          >
            <Box display="flex" flexWrap="wrap" gap={2}>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                mr={1.5}
                minHeight={30.75}
              >
                {/* Cargo Count */}
                <Tooltip title="Cargo Count" placement="top">
                  <WidgetsIcon fontSize="small" sx={{ ml: 2.5, mr: 0.5 }} />
                </Tooltip>
                <Typography>{payloadsCount}</Typography>
              </Box>

              <OrderFormSaveButton
                loading={loading}
                isNew={isNew}
                save={save}
              />

              {!isNew && <OrderChangeDirectionButton refetch={refetch} />}
              {!isNew && <AddReturnOrderButton />}
            </Box>

            {!isNew && (
              <>
                <Box display="flex" gap={2}>
                  <GenericInvoiceReportButton orderId={order.id} />
                  <Button color="secondary" disabled={isNew}>
                    Logs
                  </Button>
                  <Controller
                    name="files"
                    render={({ field: { value, onChange } }) => (
                      <FilesButton
                        id={order.id}
                        dir="orders"
                        files={value}
                        setFiles={onChange}
                      />
                    )}
                  />
                </Box>

                <DeleteOrderButton
                  orderId={order.id}
                  cruiseId={order.cruiseId}
                />
              </>
            )}
          </Box>
        </Box>

        <Divider />

        <OrderFormHeaderFields refetch={refetch} />
      </Card>

      <OrderFormItemList
        control={form.control}
        cruiseId={order.cruiseId}
        cruiseNo={order.cruiseNo}
      />
    </FormProvider>
  );
};
