import { axiosInstance } from 'src/config/axios.config';

export const downloadFile = (
  method: 'POST' | 'PUT' | 'GET',
  url: string,
  params: any,
  fileName: string
) =>
  axiosInstance
    .request({
      url,
      method,
      data: params,
      responseType: 'blob',
    })
    .then((response) => {
      // create url for file
      const url = window.URL.createObjectURL(response.data);

      // download file
      const link = document.createElement('a');

      link.download = fileName;
      link.href = url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // clean up url after use
      window.URL.revokeObjectURL(url);

      return response;
    });
