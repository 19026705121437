import { FC, useState } from 'react';
import { getReport } from '../api/reports.api';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReportType, reportTypes } from '../types/report.type';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CloseIcon from '@mui/icons-material/Close';
import { GenericSelector } from 'src/components';

export const BSFInvoiceReportButton: FC<{
  orderId?: number;
  size?: 'default' | 'small';
  useIconButton?: boolean;
}> = ({ orderId, size = 'default', useIconButton }) => {
  const [open, setOpen] = useState(false);

  const buttonComponent = (
    <Button
      color="success"
      endIcon={<ReceiptLongIcon />}
      onClick={() => setOpen(true)}
      sx={{
        textTransform: 'none',
        minHeight: size === 'small' ? 24 : undefined,
        maxHeight: size === 'small' ? 24 : undefined,
      }}
    >
      Invoice
    </Button>
  );

  const iconButtonComponent = (
    <IconButton color="success" onClick={() => setOpen(true)}>
      <Tooltip title="Invoice">
        <ReceiptLongIcon />
      </Tooltip>
    </IconButton>
  );

  return (
    <>
      {useIconButton ? iconButtonComponent : buttonComponent}

      <Dialog open={open}>
        <DialogContent orderId={orderId} onClose={() => setOpen(false)} />
      </Dialog>
    </>
  );
};

const DialogContent: FC<{
  orderId?: number;
  onClose: () => void;
}> = ({ orderId, onClose }) => {
  const [lang, setLang] = useState<'Geo' | 'Rus'>('Geo');
  const [reportType, setReportType] = useState<ReportType>('Preview');
  const [running, setRunning] = useState(false);

  const onRun = () => {
    setRunning(true);
    getReport('BSFInvoice', { orderId, lang }, reportType)
      .catch(() => toast.error('Error while generating report'))
      .finally(() => setRunning(false));
  };

  return (
    <Box minWidth={250}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        py={1}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography variant="h6">Invoice</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={1.5}
      >
        <GenericSelector
          label="Language"
          fullWidth
          value={lang}
          onChange={(v) => setLang(v as 'Geo' | 'Rus')}
          options={[
            { id: 'Geo', name: 'Georgian' },
            { id: 'Eng', name: 'English' },
          ]}
        />

        <GenericSelector
          label="Format"
          fullWidth
          value={reportType}
          onChange={(v) => setReportType(v as ReportType)}
          options={reportTypes.filter((t) => t === 'Preview' || t === 'Pdf')}
        />
      </Box>

      <Box my={2} display="flex" justifyContent="center">
        <LoadingButton
          loadingPosition="end"
          endIcon={<ReceiptLongIcon />}
          loading={running}
          onClick={onRun}
          sx={{ textTransform: 'none' }}
        >
          Run
        </LoadingButton>
      </Box>
    </Box>
  );
};
