import {
  Badge,
  BadgeProps,
  Button,
  IconButton,
  styled,
  Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FilesDialog } from './FilesDialog';
import { Directory } from '../types/file.types';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 16,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export const FilesButton: FC<{
  id: number | string;
  dir: Directory;
  files: string[];
  setFiles: (files: string[]) => void;
  useIconButton?: boolean;
}> = ({ id, dir, files, setFiles, useIconButton }) => {
  const [open, setOpen] = useState(false);

  const ButtonComponent = (
    <Button
      endIcon={<AttachFileIcon sx={{ ml: -0.25, mr: 0.25 }} />}
      color="secondary"
      onClick={() => setOpen(true)}
    >
      files
    </Button>
  );

  const IconButtonComponent = (
    <Tooltip title="Files">
      <IconButton
        onClick={() => setOpen(true)}
        sx={{
          color: 'whitesmoke',
          bgcolor: 'secondary.main',
          ':hover': { bgcolor: 'secondary.dark' },
          height: 30,
          mt: 0.125,
        }}
      >
        <AttachFileIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <StyledBadge
        badgeContent={files.length}
        color="secondary"
        sx={{ mr: 1.25 }}
      >
        {useIconButton ? IconButtonComponent : ButtonComponent}
      </StyledBadge>

      <FilesDialog
        id={id}
        dir={dir}
        open={open}
        onClose={() => setOpen(false)}
        files={files}
        setFiles={setFiles}
      />
    </>
  );
};
