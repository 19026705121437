import useSWR from 'swr';
import { getListOfWaiting } from '../api/cruises.api';

export const useListOfWaiting = (depPortId: number, desPortId: number) => {
  const { data: listOfWaiting, mutate: refetch } = useSWR(
    `/list-of-waiting/${depPortId}/${desPortId}`,
    () => getListOfWaiting(depPortId, desPortId)
  );

  return { listOfWaiting, refetch };
};
