import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/features/routes';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import { TENANT } from 'src/config/app.config';

export const NavBarLogo: FC<{}> = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={() => navigate(routes.index)}
      width={130}
      sx={{ cursor: 'pointer' }}
    >
      <DirectionsBoatFilledIcon fontSize="small" />
      <Typography
        variant="h6"
        noWrap
        sx={{ ml: 1, mr: 2, display: 'flex', color: 'inherit' }}
      >
        {TENANT}
      </Typography>
    </Box>
  );
};
