import { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRUD } from 'src/hooks';
import { createOrder, getOrder, updateOrder } from '../api/orders.api';
import { Loading } from 'src/components';
import { OrderForm } from './OrderForm';
import { Order, initOrder } from '../types/order.type';
import { routes } from 'src/features/routes';
import { Alert, Box, Button, IconButton, Typography } from '@mui/material';
import { getDuplicatePayloadError, is404Error } from 'src/utils';
import toast from 'react-hot-toast';

export const EditOrderPage: FC<{}> = () => {
  const id = +(useParams().id || -1);

  const { loading, data, save, saving, refetch, error } = useRUD(
    () => getOrder(id),
    (d) =>
      updateOrder(d)
        .then(() => toast.success('Order updated successfully!'))
        .catch((err) => {
          showToastError(err);
          return err as any;
        }),
    [id]
  );

  if (is404Error(error))
    return (
      <Alert variant="filled" severity="error">
        Order was not found! It may have been deleted.
      </Alert>
    );

  if (loading || data == null) return <Loading />;

  return (
    <OrderForm
      isNew={false}
      order={data}
      save={save}
      loading={saving}
      refetch={refetch}
    />
  );
};

export const CreateOrderPage: FC<{}> = () => {
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  // Query params
  const [searchParams] = useSearchParams();
  const cruiseId = +(searchParams.get('cruiseId') || 0) || null;
  const cruiseNo = searchParams.get('cruiseNo') || '';
  const depPortId = +(searchParams.get('depPortId') || 0);
  const desPortId = +(searchParams.get('desPortId') || 0);

  if (!cruiseId) throw new Error('Cruise ID is required!');

  const save = async (order: Order) => {
    setSaving(true);
    createOrder(order)
      .then((newId) =>
        navigate(routes.editOrder(newId), {
          replace: true,
        })
      )
      .catch((err) => {
        showToastError(err);
      })
      .finally(() => setSaving(false));
  };

  return (
    <OrderForm
      isNew
      order={initOrder(depPortId, desPortId, cruiseId, cruiseNo)}
      save={save}
      loading={saving}
    />
  );
};

const showToastError = (error: any) => {
  const errorProps = getDuplicatePayloadError(error);
  toast.error(
    (t) => (
      <Box>
        <Box display="flex" alignItems="start" justifyContent="space-between">
          <Typography mb={1}>{errorProps.errorText}</Typography>
          <IconButton onClick={() => toast.dismiss(t.id)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
        <Button
          color="secondary"
          onClick={() => {
            const url = routes.editOrder(errorProps.originalOrderId);
            window.open(url, '_blank')?.focus();
          }}
        >
          Show the original order
        </Button>
      </Box>
    ),
    {
      duration: 5 * 60 * 1000, // 5 minutes
    }
  );
};
