import { axiosInstance } from 'src/config/axios.config';
import {
  GetFinancialRecordResponse,
  GetFinancialRecordsDto,
  GetFinancialRecordsRequest,
} from '../types/finances.types';
import { downloadFile } from 'src/utils';

const endpoint = '/api/finances';

export const getFinancialRecords = async (
  params: GetFinancialRecordsRequest
): Promise<GetFinancialRecordsDto> => {
  const data = await axiosInstance
    .put(`${endpoint}/records`, params)
    .then((response) => response.data);

  return data;
};

export const getFinancialRecordsExcel = async (
  params: GetFinancialRecordsRequest
) =>
  await downloadFile(
    'PUT',
    `${endpoint}/records/excel`,
    params,
    'finances.xlsx'
  );

export const getFinancialRecord = async (
  payloadId: string
): Promise<GetFinancialRecordResponse> => {
  const data = await axiosInstance
    .get(`${endpoint}/${payloadId}`)
    .then((response) => response.data);

  return data;
};

export const savePayloadTransfers = async (
  financialRecord: GetFinancialRecordResponse
): Promise<void> => {
  await axiosInstance.post(
    `${endpoint}/${financialRecord.id}`,
    financialRecord.transfers
  );
};
