import { Dispatch, FC, SetStateAction } from 'react';
import {
  Charterer,
  chartererHasDuplicateDates,
  ChartererPercent,
  chartererValid,
  initChartererPercent,
} from '../types/charterer.type';
import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { ChartererDeleteButton } from './ChartererDeleteButton';
import { AmountInput, CustomDatePicker } from 'src/components';
import { produce } from 'immer';
import DeleteIcon from '@mui/icons-material/Delete';

export const ChartererForm: FC<{
  charterer: Charterer;
  setCharterer:
    | Dispatch<SetStateAction<Charterer | null>>
    | Dispatch<SetStateAction<Charterer>>;
  save: () => void;
  onDelete?: () => void;
}> = ({ charterer, setCharterer, save, onDelete }) => {
  return (
    <Card>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <Button
          onClick={save}
          endIcon={<SaveIcon />}
          disabled={!chartererValid(charterer)}
        >
          Save
        </Button>

        {charterer.id && <ChartererDeleteButton onDelete={onDelete} />}
      </Box>

      <Divider />

      <Box px={2} py={1}>
        <TextField
          label="Name"
          value={charterer.name}
          onChange={(e) => setCharterer({ ...charterer, name: e.target.value })}
          error={charterer.name === ''}
        />

        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <Typography variant="h6" py={1}>
            Percents
          </Typography>

          <Button
            onClick={() =>
              setCharterer(
                produce(charterer, (draft) => {
                  draft.percents.splice(0, 0, initChartererPercent());
                })
              )
            }
          >
            Add
          </Button>

          {chartererHasDuplicateDates(charterer) && (
            <Alert severity="error">Duplicate dates are not allowed!</Alert>
          )}
        </Box>

        {charterer.percents.map((chartererPercent, index) => (
          <ChartererPercentLine
            key={index}
            chartererPercent={chartererPercent}
            setChartererPercent={(chartererPercent) =>
              setCharterer(
                produce(charterer, (draft) => {
                  draft.percents[index] = chartererPercent;
                })
              )
            }
            onDelete={() =>
              setCharterer(
                produce(charterer, (draft) => {
                  draft.percents.splice(index, 1);
                })
              )
            }
          />
        ))}
      </Box>
    </Card>
  );
};

export const ChartererPercentLine: FC<{
  chartererPercent: ChartererPercent;
  setChartererPercent: (percent: ChartererPercent) => void;
  onDelete: () => void;
}> = ({
  chartererPercent: { fromDate, percent },
  setChartererPercent,
  onDelete,
}) => {
  return (
    <Box display="flex" alignItems="center" my={1} gap={2}>
      <CustomDatePicker
        label="From Date"
        initialValue={fromDate}
        onChange={(v) => setChartererPercent({ fromDate: v, percent })}
        listenToInitialValueChanges
      />

      <AmountInput
        label="Percent"
        type="float"
        value={percent}
        onChange={(percent) => setChartererPercent({ fromDate, percent })}
        error={percent < 0 || percent > 100}
      />

      <IconButton onClick={onDelete} sx={{ mt: 0.5 }}>
        <DeleteIcon color="error" />
      </IconButton>
    </Box>
  );
};
