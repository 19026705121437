import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Order } from '../types/order.type';
import { PortSelector } from 'src/features/cities';
import CloseIcon from '@mui/icons-material/Close';
import { changeOrderDirection } from '../api/orders.api';
import { LoadingButton } from '@mui/lab';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';

export const OrderChangeDirectionButton: FC<{ refetch?: () => void }> = ({
  refetch,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button endIcon={<SwapHorizIcon />} onClick={() => setOpen(true)}>
        Change Direction
      </Button>

      <Dialog open={open}>
        <ChangeDirectionDialog
          closeDialog={() => setOpen(false)}
          refetch={refetch}
        />
      </Dialog>
    </>
  );
};

const ChangeDirectionDialog: FC<{
  closeDialog: () => void;
  refetch?: () => void;
}> = ({ closeDialog, refetch }) => {
  const { getValues } = useFormContext<Order>();
  const [orderId, depPortIdParam, desPortIdParam] = getValues([
    'id',
    'departurePortId',
    'destinationPortId',
  ]);
  const [depPortId, setDepPortId] = useState<number>(depPortIdParam);
  const [desPortId, setDesPortId] = useState<number>(desPortIdParam);
  const [changing, setChanging] = useState<boolean>(false);

  const handleChangeDirection = async () => {
    setChanging(true);
    changeOrderDirection(orderId, depPortId, desPortId)
      .then(() => {
        refetch && refetch();

        toast.success('Direction changed!');

        closeDialog();
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setChanging(false));
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography variant="h6">Change Direction</Typography>

        <IconButton disabled={changing} onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth={250}
        gap={2}
        p={2}
      >
        <PortSelector
          label="Departure Port"
          cityId={depPortId}
          onChange={(cityId) => setDepPortId(cityId || 0)}
        />

        <PortSelector
          label="Destination Port"
          cityId={desPortId}
          onChange={(cityId) => setDesPortId(cityId || 0)}
        />

        <LoadingButton
          sx={{ my: 1 }}
          disabled={
            depPortId === desPortId ||
            (desPortId === desPortIdParam && depPortId === depPortIdParam)
          }
          loading={changing}
          loadingPosition="end"
          endIcon={<SwapHorizIcon />}
          onClick={handleChangeDirection}
        >
          Change Direction
        </LoadingButton>
      </Box>
    </>
  );
};
