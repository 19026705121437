import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC } from 'react';
import { OrderStatus, orderStatuses } from '../types/payload.type';
import { Controller } from 'react-hook-form';
import { startCase } from 'lodash';

export const OrderStatusSelector: FC<{
  value: OrderStatus | null;
  onChange: (status: OrderStatus | null) => void;
  allowEmpty?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  width?: number;
}> = ({ value, onChange, allowEmpty, margin, width = 180 }) => {
  return (
    <FormControl margin={margin} sx={{ width }}>
      {!allowEmpty && <InputLabel>Order Status</InputLabel>}

      <Select
        label={allowEmpty ? '' : 'Order Status'}
        value={value || ''}
        onChange={(e) => onChange(e.target.value as OrderStatus)}
      >
        {allowEmpty && <MenuItem value={''}>All</MenuItem>}

        {orderStatuses.map((s) => (
          <MenuItem key={s} value={s}>
            {startCase(s)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const OrderStatusSelectorRHF: FC<{ path: string }> = ({ path }) => (
  <Controller
    name={path}
    render={({ field }) => (
      <OrderStatusSelector value={field.value} onChange={field.onChange} />
    )}
  />
);
