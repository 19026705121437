import { useState } from 'react';
import { CruisePayload } from '../types/cruise.type';

export const useSelectedPayloads = (payloads: CruisePayload[] | undefined) => {
  const [checks, setChecks] = useState<string[]>([]);

  const setChecked = (id: string, checked: boolean) => {
    let idsToCheck = [id];

    // Check/Uncheck Wehicle drivers if present
    const payload = payloads?.find((p) => p.id === id);
    if (payload?.driverId) idsToCheck.push(payload.driverId);
    if (payload?.driver2Id) idsToCheck.push(payload.driver2Id);

    // Do the check/uncheck
    if (checked) setChecks((chs) => [...chs, ...idsToCheck]);
    else setChecks((chs) => chs.filter((c) => !idsToCheck.includes(c)));
  };

  const clearChecks = () => setChecks([]);

  return { checks, setChecked, clearChecks };
};
