import { Autocomplete, Box, TextField } from '@mui/material';
import { FC } from 'react';
import { CruiseListDto } from '../../types/cruise.type';

export const CruiseSelector: FC<{
  cruises: CruiseListDto[];
  cruiseId: number | null;
  setCruiseId: (cruiseId: number | null) => void;
  disabled?: boolean;
  error?: boolean;
  margin?: 'none' | 'normal' | 'dense';
}> = ({ cruises, cruiseId, setCruiseId, disabled, error, margin }) => {
  const valueObj = cruises?.find((cruise) => cruise.id === cruiseId) || null;

  return (
    <Autocomplete
      fullWidth
      value={valueObj}
      onChange={(_event: any, newValue: CruiseListDto | null) =>
        setCruiseId(newValue?.id || null)
      }
      options={cruises}
      autoHighlight
      getOptionLabel={(c) => `${c.no} ${c.ship} ${c.date}`}
      renderOption={(props, c) => (
        <Box component="li" {...props}>
          <Box width={80}>{c.no}</Box>
          <Box width={80}>{c.ship}</Box>
          <Box>{c.date}</Box>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          margin={margin}
          fullWidth
          sx={{ width: '100%' }}
          error={error}
        />
      )}
      disabled={disabled}
    />
  );
};
