import {
  Box,
  Button,
  Card,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { Tariff, TariffFinancialData } from '../types/tariff.type';
import SaveIcon from '@mui/icons-material/Save';
import { TariffTypeSelector } from './TariffTypeSelector';
import { AmountInput, GenericSelector } from 'src/components';
import { TariffDeleteButton } from './TariffDeleteButton';
import { TENANT } from 'src/config/app.config';

export const TariffForm: FC<{
  tariff: Tariff;
  setTariff:
    | Dispatch<SetStateAction<Tariff | null>>
    | Dispatch<SetStateAction<Tariff>>;
  save: () => void;
  onDelete?: () => void;
}> = ({ tariff, setTariff, save, onDelete }) => {
  const parkingTariffTypes = [
    { id: 'Other', name: 'Other' },
    { id: 'CarAndMoto', name: 'Car and Moto' },
    { id: 'Container20', name: 'Container 20' },
    { id: 'Container20Ref', name: 'Container 20 Ref' },
    { id: 'Container40', name: 'Container 40' },
    { id: 'Container40Ref', name: 'Container 40 Ref' },
    { id: 'Oversize', name: 'Oversize' },
  ];

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <Button
          onClick={save}
          endIcon={<SaveIcon />}
          disabled={tariff.name === ''}
        >
          Save
        </Button>

        {tariff.id && <TariffDeleteButton onDelete={onDelete} />}
      </Box>

      <Divider />

      <Box px={2} py={1}>
        <TariffTypeSelector
          value={tariff.type}
          onChange={(type) => setTariff({ ...tariff, type })}
        />

        <TextField
          fullWidth
          sx={{ width: '100%' }}
          label="Name"
          value={tariff.name}
          onChange={(e) => setTariff({ ...tariff, name: e.target.value })}
          error={tariff.name === ''}
        />

        <TariffFinancialDataForm
          label={TENANT === 'E60' ? 'Constanta' : 'From Europe'}
          value={tariff.eurTariffs}
          onChange={(eurTariffs) => setTariff({ ...tariff, eurTariffs })}
        />
        <TariffFinancialDataForm
          label={TENANT === 'E60' ? 'Poti' : 'From Georgia'}
          value={tariff.geoTariffs}
          onChange={(geoTariffs) => setTariff({ ...tariff, geoTariffs })}
        />
        <TariffFinancialDataForm
          label="Two Way"
          value={tariff.twoWayTariffs}
          onChange={(twoWayTariffs) => setTariff({ ...tariff, twoWayTariffs })}
        />

        <AmountInput
          type="float"
          label="Length"
          value={tariff.length}
          onChange={(length) => setTariff({ ...tariff, length })}
        />
        <Box />
        <AmountInput
          type="float"
          label="Width"
          value={tariff.width}
          onChange={(width) => setTariff({ ...tariff, width })}
        />
        <Box />
        <AmountInput
          type="float"
          label="Height"
          value={tariff.height}
          onChange={(height) => setTariff({ ...tariff, height })}
        />

        <TextField
          fullWidth
          sx={{ width: '100%' }}
          label="Comment"
          value={tariff.comment}
          onChange={(e) => setTariff({ ...tariff, comment: e.target.value })}
        />

        <GenericSelector
          label="Parking Tariff Type"
          value={tariff.parkingTariffType}
          onChange={(value) =>
            setTariff({ ...tariff, parkingTariffType: value?.toString() || '' })
          }
          options={parkingTariffTypes}
        />
      </Box>
    </Card>
  );
};

const TariffFinancialDataForm: FC<{
  label: string;
  value: TariffFinancialData;
  onChange: (value: TariffFinancialData) => void;
}> = ({ label, value, onChange }) => (
  <Box display="flex" alignItems="center" gap={2}>
    <Typography mt={1} minWidth={101}>
      {label}
    </Typography>
    <AmountInput
      label="Freight"
      type="float"
      value={value.freight}
      onChange={(freight) => onChange({ ...value, freight })}
    />
    <AmountInput
      label="Term. Dep. Port"
      type="float"
      value={value.terminalDepPort}
      onChange={(terminalDepPort) => onChange({ ...value, terminalDepPort })}
    />
    <AmountInput
      label="Term. Des. Port"
      type="float"
      value={value.terminalDesPort}
      onChange={(terminalDesPort) => onChange({ ...value, terminalDesPort })}
    />
  </Box>
);
