export interface Cargo {
  name: string;
  unitOfMeasure: string;
  amount: number;
  netWeight: number;
  grossWeight: number;
  volume: number;
  gngCode: string;
  gngName: string;
}

export const initCargo = (): Cargo => ({
  name: '',
  unitOfMeasure: '',
  amount: 0,
  netWeight: 0,
  grossWeight: 0,
  volume: 0,
  gngCode: '',
  gngName: '',
});
