import { FC } from 'react';
import useSWR from 'swr';
import { getPorts } from '../api/cities.api';
import { CitySelectorProps } from '../types/city.type';
import { GenericCitySelector } from './GenericCitySelector';

export const PortSelector: FC<CitySelectorProps> = (props) => {
  const { data: cities } = useSWR('/api/cities/ports', getPorts);
  return <GenericCitySelector {...props} cities={cities} />;
};
