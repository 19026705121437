import { Dialog, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { CompanyForm } from './CompanyForm';
import { CompanyType } from '../type/company.type';
import { addCompany } from '../api/companies.api';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';

export const AddCompanyButton: FC<{
  refresh: (newCompany: CompanyType) => void;
  fontSize?: 'medium' | 'small';
  mt?: number;
}> = ({ refresh, mt = 0.25, fontSize = 'small' }) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleSave = (company: CompanyType) => {
    setSaving(true);
    addCompany(company)
      .then((newCompany) => {
        refresh(newCompany);
        toast.success('Company Added!');
        setOpen(false);
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  return (
    <>
      <IconButton tabIndex={-1} sx={{ mt }} onClick={() => setOpen(true)}>
        <AddIcon fontSize={fontSize} />
      </IconButton>

      <Dialog open={open}>
        <CompanyForm
          operation="Add"
          onSave={handleSave}
          saving={saving}
          closeDialog={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
};
