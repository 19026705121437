import { FC } from 'react';
import { SearchFilters } from '../types/search.type';
import { Box, TextField } from '@mui/material';
import { SearchColumnsType } from '../types/searchColumns.type';
import { SearchRangeFilter } from './SearchRangeFilter';
import { ShipSelector } from 'src/features/cruises';
import { PortSelector } from 'src/features/cities';
import {
  PaymentStatusInput,
  PayloadType,
  PayloadTypeSelector,
  TicketType,
  ticketTypes,
  PaymentStatus,
  OrderStatus,
} from 'src/features/orders';
import { ChartererSelector } from 'src/features/charterers';
import { UserSelector } from 'src/features/access';
import { AmountInput, GenericSelector } from 'src/components';
import { startCase } from 'lodash';
import { TariffSelectorForSearch } from 'src/features/tariffs';
import { OrderStatusSelector } from 'src/features/orders/components/OrderStatusSelector';

export const SearchTableFilter: FC<{
  column: SearchColumnsType;
  filters: SearchFilters;
  setFilters: (filters: SearchFilters) => void;
}> = ({ column, filters, setFilters }) =>
  ({
    'Payload Type': (
      <PayloadTypeSelector
        payloadTypes={filters.payloadTypes}
        setPayloadTypes={(payloadTypes) =>
          setFilters({
            ...filters,
            payloadTypes: payloadTypes as PayloadType[],
          })
        }
      />
    ),
    'Cruise No': (
      <TextField
        margin="none"
        sx={{ width: 100 }}
        value={filters.cruiseNo}
        onChange={(e) => setFilters({ ...filters, cruiseNo: e.target.value })}
      />
    ),
    'Cruise Date': (
      <SearchRangeFilter
        filter={filters.cruiseDate}
        setFilter={(filter) => setFilters({ ...filters, cruiseDate: filter })}
        type="date"
      />
    ),
    Ship: (
      <Box width={130}>
        <ShipSelector
          shipId={filters.shipId}
          onChange={(shipId) => setFilters({ ...filters, shipId })}
          allowEmpty
          margin="none"
          fullWidth
        />
      </Box>
    ),
    'Departure Port': (
      <PortSelector
        label=""
        cityId={filters.departurePortId}
        onChange={(departurePortId) =>
          setFilters({ ...filters, departurePortId })
        }
        allowEmpty
        width={135}
        margin="none"
      />
    ),
    'Destination Port': (
      <PortSelector
        label=""
        cityId={filters.destinationPortId}
        onChange={(destinationPortId) =>
          setFilters({ ...filters, destinationPortId })
        }
        allowEmpty
        width={135}
        margin="none"
      />
    ),
    'Order No': (
      <TextField
        margin="none"
        sx={{ width: 120 }}
        value={filters.orderNo}
        onChange={(e) => setFilters({ ...filters, orderNo: e.target.value })}
      />
    ),
    'Order Date': (
      <SearchRangeFilter
        filter={filters.orderDate}
        setFilter={(filter) => setFilters({ ...filters, orderDate: filter })}
        type="date"
      />
    ),
    'Order Status': (
      <OrderStatusSelector
        value={filters.orderStatus as OrderStatus}
        onChange={(orderStatus) => setFilters({ ...filters, orderStatus })}
        margin="none"
        allowEmpty
        width={160}
      />
    ),
    'Payment Comment': (
      <TextField
        margin="none"
        value={filters.orderComment}
        onChange={(e) =>
          setFilters({ ...filters, orderComment: e.target.value })
        }
      />
    ),
    'Payment Status': (
      <PaymentStatusInput
        value={filters.paymentStatus as PaymentStatus}
        onChange={(paymentStatus) => setFilters({ ...filters, paymentStatus })}
        margin="none"
        allowEmptyValue
      />
    ),
    'Ticket Type': (
      <Box width={100}>
        <GenericSelector
          value={filters.ticketType as TicketType}
          onChange={(ticketType) =>
            setFilters({ ...filters, ticketType: ticketType as any })
          }
          options={[{ id: '', name: 'All' }].concat(
            ticketTypes.map((tt) => ({ id: tt, name: startCase(tt) }))
          )}
          margin="none"
          fullWidth
        />
      </Box>
    ),
    Charterer: (
      <ChartererSelector
        chartererId={filters.chartererId}
        onChange={(chartererId) => setFilters({ ...filters, chartererId })}
        allowEmpty
        margin="none"
        width={135}
        label=""
      />
    ),
    'Created By': (
      <UserSelector
        userId={filters.createdById}
        onChange={(createdById) => setFilters({ ...filters, createdById })}
      />
    ),
    // Payload
    No: (
      <TextField
        margin="none"
        sx={{ minWidth: 100 }}
        value={filters.no}
        onChange={(e) => setFilters({ ...filters, no: e.target.value })}
      />
    ),
    Name: (
      <TextField
        margin="none"
        sx={{ width: 100 }}
        value={filters.name}
        onChange={(e) => setFilters({ ...filters, name: e.target.value })}
      />
    ),
    Surname: (
      <TextField
        margin="none"
        sx={{ width: 100 }}
        value={filters.surname}
        onChange={(e) => setFilters({ ...filters, surname: e.target.value })}
      />
    ),
    Passport: (
      <TextField
        margin="none"
        sx={{ width: 100 }}
        value={filters.passport}
        onChange={(e) => setFilters({ ...filters, passport: e.target.value })}
      />
    ),
    'Personal Id': (
      <TextField
        margin="none"
        sx={{ width: 100 }}
        value={filters.personalId}
        onChange={(e) => setFilters({ ...filters, personalId: e.target.value })}
      />
    ),
    Shipper: (
      <TextField
        margin="none"
        value={filters.shipper}
        onChange={(e) => setFilters({ ...filters, shipper: e.target.value })}
      />
    ),
    'Freight Rate': (
      <AmountInput
        type="float"
        margin="none"
        value={filters.freightRate}
        onChange={(freightRate) => setFilters({ ...filters, freightRate })}
      />
    ),
    Tariff: (
      <TariffSelectorForSearch
        value={filters.tariffId}
        onChange={(tariffId) => setFilters({ ...filters, tariffId })}
      />
    ),
    'Phone Number': (
      <TextField
        margin="none"
        sx={{ minWidth: 120 }}
        value={filters.phoneNumber}
        onChange={(e) =>
          setFilters({ ...filters, phoneNumber: e.target.value })
        }
      />
    ),
  }[column]);
