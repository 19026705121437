import { FC, useEffect, useState } from 'react';
import {
  currencies,
  FinancialRecord,
  getBalanceColor,
  GetFinancialRecordsRequest,
  transferTypes,
} from '../types/finances.types';
import { FilesButton } from 'src/features/files';
import { GenericInvoiceReportButton, ReportButton } from 'src/features/reports';
import { Box, TextField } from '@mui/material';
import { PayloadTransfersDialog } from './PayloadTransfersDialog';
import { Link } from 'react-router-dom';
import { customDateFormat, customMoneyFormat } from 'src/utils';
import { BillButton } from './BillButton';
import { routes } from 'src/features/routes';
import { ScrollableTableWrapper } from 'src/features/cruises/components/payload-items/ScrollableTableWrapper';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import {
  CustomDatePicker,
  GenericMultiSelector,
  Loading,
  OrderStatusChip,
  OrderStatusesSelector,
} from 'src/components';
import { DebouncedFunc } from 'lodash';
import { CharterersSelector } from 'src/features/charterers';
import { CitiesSelector } from 'src/features/cities';

export const FinancesTable: FC<{
  isLoading: boolean;
  financialRecords: FinancialRecord[];
  refresh: () => void;
  filters: GetFinancialRecordsRequest;
  setFilters: (field: keyof GetFinancialRecordsRequest, value: any) => void;
  setFiltersDebounced: DebouncedFunc<
    (field: keyof GetFinancialRecordsRequest, value: any) => void
  >;
}> = ({
  isLoading,
  financialRecords: data,
  refresh,
  filters,
  setFilters,
  setFiltersDebounced,
}) => {
  const [financialRecords, setFinancialRecords] = useState(data);
  const [selectedPayloadId, setSelectedPayloadId] = useState<string | null>(
    null
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setFinancialRecords(data);
  }, [data]);

  return (
    <>
      <PayloadTransfersDialog
        payloadId={selectedPayloadId}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          refresh();
        }}
      />

      <ScrollableTableWrapper minusVH={150}>
        <table className="custom-table">
          <thead onClick={() => setSelectedPayloadId(null)}>
            <tr>
              <th>
                Cruise No
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  onChange={(e) =>
                    setFiltersDebounced('cruiseNo', e.target.value)
                  }
                  sx={{ width: 85 }}
                />
              </th>
              <th>
                Order No
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  onChange={(e) =>
                    setFiltersDebounced('orderNo', e.target.value)
                  }
                  sx={{ width: 120 }}
                />
              </th>
              <th>
                Ticket No
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  onChange={(e) =>
                    setFiltersDebounced('ticketNo', e.target.value)
                  }
                  sx={{ width: 140 }}
                />
              </th>
              <th>
                Order Status
                <OrderStatusesSelector
                  label=""
                  selectedOrderStatuses={filters.orderStatuses}
                  onChange={(ids) => setFilters('orderStatuses', ids)}
                  margin="none"
                  width={120}
                />
              </th>
              <th style={{ minWidth: 150 }}>
                Agent
                <CharterersSelector
                  label=""
                  chartererIds={filters.chartererIds}
                  onChange={(ids) => setFilters('chartererIds', ids)}
                  margin="none"
                  width={150}
                />
              </th>
              <th>
                Payload/Passport No
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  onChange={(e) =>
                    setFiltersDebounced('payloadNo', e.target.value)
                  }
                  sx={{ width: 180 }}
                />
              </th>
              <th>
                Place
                <CitiesSelector
                  values={filters.transactionPlaces}
                  onChange={(values) => setFilters('transactionPlaces', values)}
                />
              </th>
              <th style={{ width: 90 }}>
                Date
                <CustomDatePicker
                  initialValue={filters.transactionDate || null}
                  onChange={(v) => setFilters('transactionDate', v)}
                  width={148}
                  hideError
                  m={0}
                  listenToInitialValueChanges
                />
              </th>
              <th>
                Payer
                <br />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  onChange={(e) =>
                    setFiltersDebounced('transactionPayer', e.target.value)
                  }
                  sx={{ width: 100 }}
                />
              </th>
              <th>
                Type
                <GenericMultiSelector
                  options={transferTypes}
                  values={filters.transactionTypes}
                  onChange={(values) => setFilters('transactionTypes', values)}
                  width={80}
                />
              </th>
              <th>
                Credit
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  onChange={(e) =>
                    setFiltersDebounced('credit', e.target.value)
                  }
                  sx={{ width: 80 }}
                />
              </th>
              <th>
                Debit
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  onChange={(e) => setFiltersDebounced('debit', e.target.value)}
                  sx={{ width: 80 }}
                />
              </th>
              <th>
                Currency
                <GenericMultiSelector
                  options={currencies}
                  values={filters.transactionCurrencies}
                  onChange={(values) =>
                    setFilters('transactionCurrencies', values)
                  }
                  width={80}
                />
              </th>
              <th>
                Comission
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  onChange={(e) =>
                    setFiltersDebounced('comission', e.target.value)
                  }
                  sx={{ width: 80 }}
                />
              </th>
              <th>Balance</th>
              <th style={{ width: 300, zIndex: 2 }}>Actions</th>
            </tr>
          </thead>

          {isLoading && (
            <tr>
              <th colSpan={16} style={{ height: 5, marginTop: 5 }}>
                <Loading />
              </th>
            </tr>
          )}

          {financialRecords.map((record) => {
            const rowSpan = Math.max(record.transfers.length, 1);
            return (
              <tbody
                key={record.id}
                className={
                  'tbody-hover ' +
                  (record.id === selectedPayloadId ? 'selected-row' : '')
                }
                onClick={() => setSelectedPayloadId(record.id)}
              >
                <tr key={record.id}>
                  <td rowSpan={rowSpan}>
                    {record.cruiseId && (
                      <Link to={routes.cruise(record.cruiseId)}>
                        {record.cruiseNo}
                      </Link>
                    )}
                  </td>
                  <td rowSpan={rowSpan}>
                    <Link to={routes.editOrder(record.orderId)}>
                      {record.orderNo}
                    </Link>
                  </td>
                  <td rowSpan={rowSpan}>
                    <Link to="" onClick={() => setDialogOpen(true)}>
                      {record.ticketNo}
                    </Link>
                  </td>
                  <td rowSpan={rowSpan}>
                    <OrderStatusChip>{record.orderStatus}</OrderStatusChip>
                  </td>
                  <td rowSpan={rowSpan}>{record.charterer}</td>
                  <td rowSpan={rowSpan}>{record.no}</td>
                  <td>{record.transfers[0]?.placeName}</td>
                  <td>{customDateFormat(record.transfers[0]?.date)}</td>
                  <td>{record.transfers[0]?.payer}</td>
                  <td>{record.transfers[0]?.type}</td>
                  <td rowSpan={rowSpan} style={moneyCellStyles}>
                    {customMoneyFormat(record.credit)}
                  </td>
                  <td style={moneyCellStyles}>
                    {customMoneyFormat(record.transfers[0]?.amount)}
                  </td>
                  <td>{record.transfers[0]?.currency}</td>
                  <td style={moneyCellStyles}>
                    {customMoneyFormat(record.transfers[0]?.comission)}
                  </td>
                  <td
                    rowSpan={rowSpan}
                    style={{
                      backgroundColor: getBalanceColor(record.balance),
                      ...moneyCellStyles,
                    }}
                  >
                    {customMoneyFormat(record.balance)}
                  </td>
                  <td rowSpan={rowSpan}>
                    <Box display="flex" justifyContent="center" gap={1} px={1}>
                      <BillButton payloadId={record.id} />
                      <GenericInvoiceReportButton
                        orderId={record.orderId}
                        useIconButton
                        useFilledButton
                      />
                      <ReportButton
                        label="Ticket"
                        name="E60Ticket"
                        params={{ payloadId: record.id }}
                        formats={['Pdf', 'Preview']}
                        useIconButton
                        iconButtonIcon={
                          <ConfirmationNumberOutlinedIcon fontSize="small" />
                        }
                        useFilledButton
                      />
                      <FilesButton
                        id={record.orderId}
                        dir="orders"
                        files={record.files}
                        setFiles={(files) => {
                          const newRecords = financialRecords.map((r) => {
                            if (r.orderId === record.orderId) {
                              return { ...r, files };
                            }
                            return r;
                          });
                          setFinancialRecords(newRecords);
                        }}
                        useIconButton
                      />
                    </Box>
                  </td>
                </tr>

                {record.transfers.slice(1).map((transfer) => (
                  <tr>
                    <td>{transfer.placeName}</td>
                    <td>{customDateFormat(transfer.date)}</td>
                    <td>{transfer.payer}</td>
                    <td>{transfer.type}</td>
                    <td style={moneyCellStyles}>
                      {customMoneyFormat(transfer.amount)}
                    </td>
                    <td>{transfer.currency}</td>
                    <td style={moneyCellStyles}>
                      {customMoneyFormat(transfer.comission)}
                    </td>
                  </tr>
                ))}
              </tbody>
            );
          })}
        </table>
      </ScrollableTableWrapper>
    </>
  );
};

const moneyCellStyles: {
  textAlign: 'right';
  paddingRight: number;
} = {
  textAlign: 'right',
  paddingRight: 5,
};
