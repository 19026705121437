export interface FinancialData {
  ticket: number;
  freight: number;
  terminalDepPort: number;
  terminalDesPort: number;
  animal: number;
}

export const initFinancialData = (): FinancialData => ({
  ticket: 0,
  freight: 0,
  terminalDepPort: 0,
  terminalDesPort: 0,
  animal: 0,
});

export const sumFinancialData = (data?: FinancialData): number => {
  if (!data) return 0;

  return (
    data.ticket +
    data.freight +
    data.terminalDepPort +
    data.terminalDesPort +
    data.animal
  );
};
