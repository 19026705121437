import { Skeleton } from '@mui/material';
import { FC } from 'react';
import { GenericSelector } from 'src/components';
import { Ship } from '../../types/ship.type';
import { useShips } from '../../hooks/useShips.hook';

export const ShipSelector: FC<{
  shipId: number | null;
  onChange: (shipId: number | null) => void;
  allowEmpty?: boolean;
  margin?: 'none' | 'dense' | 'normal' | undefined;
  fullWidth?: boolean;
}> = ({ shipId, onChange, margin, fullWidth, allowEmpty = false }) => {
  const { data: ships } = useShips();

  if (!ships)
    return (
      <Skeleton height={40} sx={{ mt: 2, mb: margin === 'none' ? 0 : 1 }} />
    );

  return (
    <GenericSelector
      label={allowEmpty ? '' : 'Ship'}
      value={shipId || ''}
      onChange={(shipId) => onChange(shipId as number)}
      options={getOptions(ships, allowEmpty)}
      margin={margin}
      fullWidth={fullWidth}
    />
  );
};

const getOptions = (ships: Ship[], allowEmpty: boolean) => {
  let options = ships.map((ship) => ({
    id: ship.id,
    name: ship.name,
  }));

  if (allowEmpty) options = [{ id: null, name: 'All' }, ...options];

  return options;
};
