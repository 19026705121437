import { Box, Checkbox, Dialog, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Directory } from '../types/file.types';
import { FilesUploadButton } from './FilesUploadButton';
import { useFilesDelete } from '../hooks/useFilesDelete';
import { FilesDeleteButton } from './FilesDeleteButton';
import { FileDownloadButton } from './FileDownloadButton';
import { FilePreviewButton } from './FilePreviewButton';

export const FilesDialog: FC<{
  id: number | string;
  dir: Directory;
  open: boolean;
  onClose: () => void;
  files: string[];
  setFiles: (files: string[]) => void;
}> = ({ id, dir, open, onClose, files, setFiles }) => {
  const uploadingState = useState(false);
  const [loading] = uploadingState;

  const {
    fileSelected,
    toggleFileSelection,
    deleteSelectedFiles,
    setSelectionForAllFiles,
    noFilesSelected,
    allFilesSelected,
    deleting,
  } = useFilesDelete(files, setFiles, dir, id);

  return (
    <Dialog
      open={open}
      onClose={(_, r) => !loading && r === 'escapeKeyDown' && onClose()}
    >
      {/* Title */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography variant="h6">Files</Typography>

        <IconButton onClick={onClose} disabled={loading}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box minHeight="50vh">
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr={1}
          borderBottom={1}
          borderColor="divider"
          py={0.5}
        >
          <Checkbox
            checked={allFilesSelected}
            onChange={(e) => setSelectionForAllFiles(e.target.checked)}
            disabled={loading}
            sx={{ visibility: files.length > 0 ? 'visible' : 'hidden' }}
          />
          <FilesUploadButton
            id={id}
            dir={dir}
            setFiles={setFiles}
            uploadingState={uploadingState}
            loading={loading}
          />
          <FilesDeleteButton
            disabled={noFilesSelected || loading}
            deleteSelectedFiles={deleteSelectedFiles}
            deleting={deleting}
          />
        </Box>

        {/* List */}
        <Box width={550}>
          {files.map((file) => (
            <Box
              key={file}
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 0.5,
              }}
            >
              <Checkbox
                disabled={loading}
                checked={fileSelected(file)}
                onChange={(e) => toggleFileSelection(file, e.target.checked)}
              />
              <Box width="100%">
                <Typography sx={{ px: 2 }}>{file}</Typography>
              </Box>
              <Box display="flex" gap={1} mr={1}>
                <FilePreviewButton dir={dir} id={id} name={file} />

                <FileDownloadButton
                  loading={loading}
                  dir={dir}
                  id={id}
                  name={file}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
