import { axiosInstance } from 'src/config/axios.config';
import { Order } from '../types/order.type';

const endpoint = '/api/orders';

export const getOrder = async (id: number): Promise<Order> => {
  const data = await axiosInstance
    .get(`${endpoint}/${id}`)
    .then((response) => response.data);
  
  return data;
};

export const createOrder = async (order: Order): Promise<number> => {
  const data = await axiosInstance
    .post(endpoint, order)
    .then((response) => response.data);

  return data;
};

export const updateOrder = async (order: Order): Promise<Order> => {
  const data = await axiosInstance
    .put(endpoint, order)
    .then((response) => response.data);

  return data;
};

export const changeOrderDirection = async (
  orderId: number,
  depPortId: number,
  desPortId: number
): Promise<void> => {
  await axiosInstance.put(`${endpoint}/direction`, null, {
    params: { orderId, depPortId, desPortId },
  });
};

export const addReturnOrder = async (
  orderId: number,
  cruiseId: number,
  payloadIds: string[]
): Promise<number> =>
  (
    await axiosInstance.post(
      `${endpoint}/${orderId}/add-return-ticket-payloads`,
      { cruiseId, payloadIds }
    )
  ).data;

export const getOrderRelatedCruises = async (
  orderId: number
): Promise<{ cruiseId: number; cruiseNo: number }[]> => {
  const data = (
    await axiosInstance.get(`${endpoint}/${orderId}/related-cruises`)
  ).data;

  return data;
};

export const deleteOrder = async (orderId: number): Promise<void> => {
  await axiosInstance.delete(`${endpoint}/${orderId}`);
};

export const getReferencedOrderId = async (
  payloadId: string
): Promise<number> =>
  (
    await axiosInstance.get(
      `${endpoint}/payloads/${payloadId}/referenced-order-id`
    )
  ).data;

export const updateField = async (request: {
  payloadId: string;
  orderId: number;
  field: 'PayloadComment' | 'OrderComment';
  value: string | null;
}): Promise<void> => await axiosInstance.patch(endpoint, request);
