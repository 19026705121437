import { LoadingButton } from '@mui/lab';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import { deleteOrder } from '../api/orders.api';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/features/routes';
import toast from 'react-hot-toast';

export const DeleteOrderButton: FC<{
  orderId: number;
  cruiseId: number | null;
}> = ({ orderId, cruiseId }) => {
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    setDeleting(true);
    deleteOrder(orderId)
      .then(() => {
        if (cruiseId) {
          navigate(routes.cruise(cruiseId));
        } else {
          navigate(routes.cruises);
        }
        toast.success('Order deleted!');
      })
      .catch(() => toast.error('Error deleting order!'))
      .finally(() => setDeleting(false));
  };

  return (
    <>
      <LoadingButton
        loading={deleting}
        loadingPosition="end"
        endIcon={<DeleteIcon />}
        color="error"
        onClick={() => setOpen(true)}
      >
        Delete
      </LoadingButton>

      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        title="Delete this Order?"
        onConfirm={handleDelete}
      />
    </>
  );
};
