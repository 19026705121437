import { FC, useMemo, useState } from 'react';
import { CruisePayload, cruisePassegers } from '../../types/cruise.type';
import { createColumnHelper } from '@tanstack/react-table';
import { CustomTable } from 'src/components';
import { columnDefs } from './columnDefs';
import { PayloadsToolbar } from './PayloadsToolbar';
import { PayloadListProps } from '../../types/payloadListProps.type';
import { compact } from 'lodash';
import { ReportButton } from 'src/features/reports';
import { ScrollableTableWrapper } from './ScrollableTableWrapper';

const columnHelper = createColumnHelper<CruisePayload>();

export const PassengerList: FC<PayloadListProps> = ({
  cruise,
  checks,
  setChecked,
  hideButtons,
}) => {
  const columns = useMemo(
    () =>
      compact([
        columnDefs.selected(checks, setChecked),
        hideButtons ? null : columnDefs.reports,
        columnDefs.paymentStatus,
        columnDefs.orderStatus,
        columnDefs.rowNumber,
        columnDefs.orderNo(hideButtons, cruise.no),
        columnHelper.accessor('fullName', {
          header: 'Full Name',
          cell: (info) => (
            <div style={{ textAlign: 'left', paddingLeft: '20px' }}>
              {info.getValue()}
            </div>
          ),
        }),
        columnHelper.accessor('document', { header: 'Document' }),
        columnHelper.accessor('cabin', { header: 'Cabin' }),
        columnHelper.accessor('charterer', { header: 'Charterer' }),
        columnHelper.accessor('createdByName', { header: 'Created By' }),
      ]),
    [checks, setChecked, hideButtons, cruise.no]
  );

  const data = cruisePassegers(cruise);

  const [globalFilter, setGlobalFilter] = useState('');

  return (
    <>
      <PayloadsToolbar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      >
        {cruise.id && (
          <>
            <ReportButton
              name="PassengerPayments"
              params={{ cruiseId: cruise.id }}
              formats={['Word', 'Excel']}
            />
            <ReportButton
              name="PassengerList"
              params={{ cruiseId: cruise.id }}
              formats={['Word', 'Excel']}
            />
            <ReportButton
              name="IMOPassengerList"
              params={{ cruiseId: cruise.id }}
              formats={['Word', 'Excel']}
            />
          </>
        )}
      </PayloadsToolbar>

      <ScrollableTableWrapper>
        <CustomTable
          data={data}
          columns={columns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </ScrollableTableWrapper>
    </>
  );
};
