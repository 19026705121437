import { Box, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { Cruise } from '../../types/cruise.type';
import { capitalize } from '../../utils/cruise.utils';
import { FromListOfWaitingButton } from './FromListOfWaitingButton';
import { MoveSelectedButton } from '../MoveSelectedButton';
import { customDateFormat } from 'src/utils';
import { ReportButton } from 'src/features/reports';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { TENANT } from 'src/config/app.config';
import { CargoLengthIcon } from './CargoLengthIcon';
import PersonIcon from '@mui/icons-material/Person';

export const CruiseHeader: FC<{
  cruise: Cruise;
  refetchCruises: () => void;
  checks: string[];
  clearChecks: () => void;
}> = ({ cruise, refetchCruises, checks, clearChecks }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      px={1}
      py={1}
      color="black"
      gap={1}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        mr={1.5}
        minHeight={30.75}
      >
        {/* Cruise No */}
        <Tooltip title="Cruise No" placement="top">
          <Typography variant="h6">{cruise.no}</Typography>
        </Tooltip>

        {/* Ship */}
        <Tooltip title="Ship" placement="top">
          <DirectionsBoatIcon fontSize="small" sx={{ ml: 2.5, mr: 0.5 }} />
        </Tooltip>
        <Typography sx={{ mr: 2.5 }}>{capitalize(cruise.ship)}</Typography>

        {/* Ports */}
        <Typography>
          {capitalize(cruise.departurePort)} ({customDateFormat(cruise.date)})
        </Typography>
        <Tooltip title="Direction" placement="top">
          <ArrowForwardIcon fontSize="small" sx={{ mx: 0.25 }} />
        </Tooltip>
        <Typography>
          {capitalize(cruise.destinationPort)}
          {cruise.arrivalDate && <>({customDateFormat(cruise.arrivalDate)})</>}
        </Typography>

        {/* Cargo Length */}
        <CargoLengthIcon
          cargoLength={cruise.cargoLength}
          cargoLengthByOrderStatus={cruise.cargoLengthByOrderStatus}
        />

        {/* Cargo Count */}
        <Tooltip title="Cargo Count" placement="top">
          <WidgetsIcon fontSize="small" sx={{ ml: 2.5, mr: 0.5 }} />
        </Tooltip>
        <Typography>{cruise.cargoCount}</Typography>

        {/* Active passenger count */}
        <Tooltip title="Passenger Count" placement="top">
          <PersonIcon fontSize="small" sx={{ ml: 2.5, mr: 0.5 }} />
        </Tooltip>
        <Typography>{cruise.passengerCount}</Typography>
      </Box>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        flexGrow={1}
        gap={1}
      >
        <Box display="flex" gap={1}>
          <ReportButton
            name={TENANT === 'E60' ? 'E60Registry' : 'Registry'}
            label="Registry"
            params={{ cruiseId: cruise.id }}
            formats={['Excel']}
          />
        </Box>

        <Box display="flex" gap={1}>
          <MoveSelectedButton
            currentCruiseId={cruise.id}
            depPortId={cruise.departurePortId}
            desPortId={cruise.destinationPortId}
            checks={checks}
            clearChecks={clearChecks}
            refetch={refetchCruises}
          />

          <FromListOfWaitingButton
            cruiseId={cruise.id}
            depPortId={cruise.departurePortId}
            desPortId={cruise.destinationPortId}
            refetchCruises={refetchCruises}
          />
        </Box>
      </Box>
    </Box>
  );
};
