import { IconButton } from '@mui/material';
import { FC, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Directory } from '../types/file.types';
import toast from 'react-hot-toast';
import { openFileForPreview } from '../api/files.api';

export const FilePreviewButton: FC<{
  dir: Directory;
  id: number | string;
  name: string;
}> = ({ dir, id, name }) => {
  const [previewing, setPreviewing] = useState(false);

  const preview = () => {
    setPreviewing(true);

    openFileForPreview(dir, id, name)
      .catch(async (err) =>
        toast.error(JSON.parse(await err.response.data.text()).title)
      )
      .finally(() => setPreviewing(false));
  };
  return (
    <IconButton disabled={previewing} onClick={preview} color="primary">
      <VisibilityIcon /> {}
    </IconButton>
  );
};
