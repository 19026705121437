import { useLocalStorage } from '@uidotdev/usehooks';
import { useShips } from './useShips.hook';

export const useShipsToggle = () => {
  const { data: ships, isLoading: shipsLoading } = useShips();
  const [selectedShipId, setSelectedShipId] = useLocalStorage('ships', 0);
  const selectedShip = ships?.find((ship) => ship.id === selectedShipId);

  return {
    ships,
    shipsLoading,
    selectedShipId,
    setSelectedShipId,
    selectedShip,
  };
};
