import useSWR from 'swr';
import { getRate } from '../api/rates.api';
import { initRate } from '../types/rates.types';
import { getErrorMessage } from 'src/utils';

export const useRate = (date: string | null | undefined) => {
  const { data, isLoading, error } = useSWR(['/rate/', date], ([, date]) =>
    getRate(date)
  );
  return {
    rate: data || initRate(date),
    rateLoading: isLoading,
    rateError: getErrorMessage(error),
  };
};
