import { Card, Divider } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ListOfWaitingHeader } from './ListOfWaitingHeader';
import { PayloadTabs } from '../payload-items/PayloadTabs';
import { Loading } from 'src/components';
import { ListOfWaiting } from '../../types/cruise.type';

export const ListOfWaitingCard: FC<{
  listOfWaiting: ListOfWaiting | undefined;
  checks: string[];
  setChecked: (id: string, checked: boolean) => void;
  hideButtons: boolean;
  moveSelectedButton?: ReactNode;
}> = ({
  listOfWaiting,
  checks,
  setChecked,
  hideButtons,
  moveSelectedButton,
}) => {
  if (!listOfWaiting) return <Loading />;

  return (
    <Card>
      <ListOfWaitingHeader
        listOfWaiting={listOfWaiting}
        moveSelectedButton={moveSelectedButton}
      />

      <Divider />

      <PayloadTabs
        cruise={listOfWaiting}
        checks={checks}
        setChecked={setChecked}
        hideButtons={hideButtons}
        refetchCruises={(() => {}) as any}
      />
    </Card>
  );
};
