import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getErrorMessage } from 'src/utils';
import { Loading } from 'src/components';
import { Alert } from '@mui/material';
import toast from 'react-hot-toast';
import { routes } from 'src/features/routes';
import { Charterer, initCharterer } from '../types/charterer.type';
import { ChartererForm } from './ChartererForm';
import {
  createCharterer,
  deleteCharterer,
  getCharterer,
  updateCharterer,
} from '../api/charterers.api';

export const EditChartererPage: FC<{}> = () => {
  const id = +(useParams().id || -1);

  const [charterer, setCharterer] = useState<Charterer | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const loadCharterer = (id: number) => {
    setLoading(true);
    getCharterer(id)
      .then(setCharterer)
      .catch((e) => setError(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  const saveCharterer = () => {
    if (charterer == null) return;

    setLoading(true);
    updateCharterer(charterer)
      .then(() => toast.success('Charterer updated successfully!'))
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  const onDelete = () => {
    if (charterer == null) return;

    setLoading(true);
    deleteCharterer(charterer.id)
      .then(() => {
        navigate(routes.contacts);
        toast.success('Charterer deleted successfully!');
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadCharterer(id);
  }, [id]);

  if (loading) return <Loading />;

  if (charterer == null || error)
    return (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    );

  return (
    <ChartererForm
      charterer={charterer}
      setCharterer={setCharterer}
      save={saveCharterer}
      onDelete={onDelete}
    />
  );
};

export const CreateChartererPage: FC<{}> = () => {
  const [charterer, setCharterer] = useState<Charterer>(initCharterer());
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const saveCharterer = () => {
    setLoading(true);
    createCharterer(charterer)
      .then(() => {
        navigate(routes.contacts);
        toast.success('Charterer created successfully!');
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  if (loading) return <Loading />;

  return (
    <ChartererForm
      charterer={charterer}
      setCharterer={setCharterer}
      save={saveCharterer}
    />
  );
};
