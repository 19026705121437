import { FC, useEffect, useMemo, useState } from 'react';
import { CustomTable, Loading } from 'src/components';
import { createColumnHelper } from '@tanstack/react-table';
import { routes } from 'src/features/routes';
import { Link } from 'react-router-dom';
import { CruiseListDto, CruiseListPageMode } from '../../types/cruise.type';
import { useCruiseList } from '../../hooks/useCruiseList.hook';
import { AddCruiseButton } from './AddCruiseButton';
import { MoreCruiseButton } from './MoreCruiseButton';
import { CruiseListToolbar } from './CruiseListToolbar';
import { CruiseDirection } from '../../types/direction.type';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Ship } from '../../types/ship.type';
import { compact } from 'lodash';

const ch = createColumnHelper<CruiseListDto>();

export const CruiseList: FC<{
  selectedDir: CruiseDirection | undefined;
  selectedShip: Ship | undefined;
  mode: CruiseListPageMode;
}> = ({ selectedDir, selectedShip, mode }) => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useLocalStorage('cruise-list-size', 15);
  const { data, refresh } = useCruiseList(
    selectedDir?.depPortId,
    selectedDir?.desPortId,
    selectedShip?.id || undefined,
    page,
    size
  );

  useEffect(() => {
    setPage(0);
  }, [selectedDir?.depPortId, selectedDir?.desPortId, selectedShip?.id]);

  const columns = useMemo(
    () =>
      compact([
        ch.accessor('no', {
          header: 'No.',
          cell: (info) => (
            <Link
              to={(mode === 'Payloads' ? routes.cruise : routes.cruiseParkings)(
                info.row.original.id
              )}
            >
              {info.getValue()}
            </Link>
          ),
        }),
        ch.accessor('date', { header: 'Departure' }),
        ch.accessor('ship', { header: 'Ship' }),
        ch.accessor('arrivalDate', { header: 'Arrival' }),
        mode === 'Payloads'
          ? ch.display({
              id: 'actions',
              header: () => (
                <AddCruiseButton
                  depPortId={selectedDir?.depPortId}
                  desPortId={selectedDir?.desPortId}
                  shipId={selectedShip?.id}
                  refresh={refresh}
                />
              ),
              cell: (info) => (
                <MoreCruiseButton
                  cruise={info.row.original}
                  refresh={refresh}
                />
              ),
            })
          : null,
      ]),
    [
      selectedDir?.depPortId,
      selectedDir?.desPortId,
      selectedShip?.id,
      refresh,
      mode,
    ]
  );

  return (
    <>
      <CruiseListToolbar
        selectedDir={selectedDir}
        selectedShip={selectedShip}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        totalCount={data?.totalCount}
      />

      {data?.cruises ? (
        <CustomTable columns={columns} data={data.cruises} />
      ) : (
        <Loading />
      )}
    </>
  );
};
