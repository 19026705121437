import { FC } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { customDateFormat } from 'src/utils';
import { Box, Tooltip, Typography } from '@mui/material';
import { CruiseParkings } from '../types/parkings.types';
import { capitalize } from 'lodash';

export const CruiseParkingsHeader: FC<{
  cruiseParkings: CruiseParkings;
}> = ({ cruiseParkings }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      px={1}
      py={1}
      color="black"
      gap={1}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        mr={1.5}
        minHeight={30.75}
      >
        {/* Cruise No */}
        <Tooltip title="Cruise No" placement="top">
          <Typography variant="h6">{cruiseParkings.no}</Typography>
        </Tooltip>

        {/* Ship */}
        <Tooltip title="Ship" placement="top">
          <DirectionsBoatIcon fontSize="small" sx={{ ml: 2.5, mr: 0.5 }} />
        </Tooltip>
        <Typography sx={{ mr: 2.5 }}>
          {capitalize(cruiseParkings.ship)}
        </Typography>

        {/* Ports */}
        <Typography>
          {capitalize(cruiseParkings.departurePort)} (
          {customDateFormat(cruiseParkings.date)})
        </Typography>
        <Tooltip title="Direction" placement="top">
          <ArrowForwardIcon fontSize="small" sx={{ mx: 0.25 }} />
        </Tooltip>
        <Typography>
          {capitalize(cruiseParkings.destinationPort)}
          {cruiseParkings.arrivalDate && (
            <>({customDateFormat(cruiseParkings.arrivalDate)})</>
          )}
        </Typography>
      </Box>
    </Box>
  );
};
