import { today } from 'src/utils';

export interface Charterer {
  id: number;
  name: string;
  percents: ChartererPercent[];
}

export interface ChartererPercent {
  fromDate: string;
  percent: number;
}

export const initCharterer = (): Charterer => ({
  id: 0,
  name: '',
  percents: [],
});

export const initChartererPercent = (): ChartererPercent => ({
  fromDate: today(),
  percent: 0,
});

export const chartererValid = (charterer: Charterer): boolean => {
  // Check if name is empty
  if (charterer.name === '') return false;

  // Check if dates and percents are valid
  if (
    !charterer.percents.every(
      (cp) => cp.fromDate !== '' && cp.percent >= 0 && cp.percent <= 100
    )
  )
    return false;

  // Check if there are no duplicate dates
  if (chartererHasDuplicateDates(charterer)) return false;

  return true;
};

export const chartererHasDuplicateDates = (charterer: Charterer): boolean => {
  const dates = charterer.percents.map((cp) => cp.fromDate);
  return new Set(dates).size !== dates.length;
};
