import { FC } from 'react';
import { Search, SearchFilters } from '../types/search.type';
import { Loading } from 'src/components';
import { SearchResponseDataItem } from '../types/searchResponse.type';
import { searchColumns } from '../types/searchColumns.type';
import { SearchTableFilter } from './SearchTableFilter';
import { SearchTableCell } from './SearchTableCell';

export const SearchTable: FC<{
  search: Search;
  setFilters: (filters: SearchFilters) => void;
  data: SearchResponseDataItem[];
}> = ({ search, setFilters, data }) => {
  const visibleColumns = searchColumns.filter((sc) =>
    search.visibleColumns.includes(sc)
  );

  return (
    <table
      className="custom-table"
      style={{
        padding: 8,
        paddingTop: 1,
        display: 'block',
        overflowX: 'auto',
        // overflowY: 'auto', whiteSpace: 'nowrap', maxHeight: 'calc(100vh - 147px)',
      }}
    >
      <thead>
        <tr>
          {visibleColumns.map((column) => (
            <th
              key={column}
              style={{
                padding: '5px 0',
                verticalAlign: 'bottom',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
              }}
            >
              <SearchTableFilter
                column={column}
                filters={search.filters}
                setFilters={setFilters}
              />
            </th>
          ))}
        </tr>
      </thead>

      <thead>
        <tr>
          {visibleColumns.map((column) => (
            <th key={column}>{column}</th>
          ))}
        </tr>
      </thead>

      {data ? (
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {visibleColumns.map((column) => (
                <td key={column} style={{ padding: '0 5px', minWidth: 90 }}>
                  <SearchTableCell column={column} row={row} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      ) : (
        <Loading />
      )}
    </table>
  );
};
