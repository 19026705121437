import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CompanyType } from '../type/company.type';
import { CompanyForm } from './CompanyForm';
import { Loading } from 'src/components';
import { getCompany, updateCompany } from '../api/companies.api';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';

export const EditCompanyDialogContent: FC<{
  refresh: () => void;
  companyId: number;
  closeDialog: () => void;
}> = ({ refresh, companyId, closeDialog }) => {
  const [company, setCompany] = useState<CompanyType>();
  const [saving, setSaving] = useState(false);

  const handleSave = (company: CompanyType) => {
    setSaving(true);
    updateCompany(company)
      .then(() => {
        refresh();
        toast.success('Company Updated!');
        closeDialog();
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    getCompany(companyId).then(setCompany);
  }, [companyId]);

  if (!company)
    return (
      <Box width={200}>
        <Loading />
      </Box>
    );

  return (
    <CompanyForm
      initialCompany={company}
      operation="Edit"
      onSave={handleSave}
      saving={saving}
      closeDialog={closeDialog}
    />
  );
};
