import CloseIcon from '@mui/icons-material/Close';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { GenericSelector } from 'src/components';
import { getReport } from '../../reports/api/reports.api';
import { ReportType } from '../../reports/types/report.type';

export const ParkingReportButton: FC<{
  orderId?: number | string;
  cruiseId?: number;
  useIconButton?: boolean;
}> = ({ orderId, cruiseId, useIconButton }) => {
  const [open, setOpen] = useState(false);
  const type = 'E60ParkingInvoice';

  const buttonComponent = (
    <Button
      color="success"
      endIcon={<ReceiptLongIcon />}
      onClick={() => setOpen(true)}
    ></Button>
  );

  const iconButtonComponent = (
    <IconButton color="success" onClick={() => setOpen(true)}>
      <Tooltip title={type}>
        <ReceiptLongIcon />
      </Tooltip>
    </IconButton>
  );

  return (
    <>
      {useIconButton ? iconButtonComponent : buttonComponent}

      <Dialog open={open}>
        <ParkingDialog
          cruiseId={cruiseId}
          orderId={orderId}
          type={type}
          onClose={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
};

export const ParkingDialog: FC<{
  orderId?: number | string;
  cruiseId?: number;
  type: 'E60ParkingInvoice';
  onClose: () => void;
}> = ({ orderId, cruiseId, type, onClose }) => {
  const [currency, setCurrency] = useState<'GEL' | 'USD' | 'EUR'>('GEL');
  const [reportType, setReportType] = useState<ReportType>('Preview');
  const [running, setRunning] = useState(false);
  const [companyId] = useState<number | null>(null);

  const onRun = () => {
    setRunning(true);

    getReport(type, { orderId, currency, cruiseId, companyId }, reportType)
      .catch(() => toast.error('Error while generating report'))
      .finally(() => setRunning(false));
  };

  return (
    <Box minWidth={250}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        py={1}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography variant="h6">{type} Invoice</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={1.5}
      >
        <GenericSelector
          label="Currency"
          fullWidth
          value={currency}
          onChange={(v) => setCurrency(v as 'GEL' | 'USD' | 'EUR')}
          options={['GEL', 'USD', 'EUR']}
        />

        <GenericSelector
          label="Format"
          fullWidth
          value={reportType}
          onChange={(v) => setReportType(v as ReportType)}
          options={['Preview', 'Pdf']}
        />
      </Box>

      <Box my={2} display="flex" justifyContent="center">
        <LoadingButton
          loadingPosition="end"
          endIcon={<ReceiptLongIcon />}
          loading={running}
          onClick={onRun}
        >
          Run
        </LoadingButton>
      </Box>
    </Box>
  );
};
