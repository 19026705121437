import { Box, TextField } from '@mui/material';
import { FC } from 'react';
import { SearchRangeFilterType } from '../types/search.type';
import { CustomCheckbox, CustomDatePicker } from 'src/components';

export const SearchRangeFilter: FC<{
  filter: SearchRangeFilterType;
  setFilter: (filter: SearchRangeFilterType) => void;
  type: 'number' | 'date';
}> = ({ filter, setFilter, type }) => {
  return (
    <>
      <Box ml={type === 'number' ? 0 : 2}>
        <CustomCheckbox
          label="Range"
          checked={filter.range}
          onChange={(v) => setFilter({ ...filter, range: v })}
          width={100}
          mt={0}
        />
      </Box>

      {type === 'number' && (
        <>
          {filter.range ? (
            <>
              <TextField
                margin="none"
                sx={{ width: 100 }}
                label="From"
                value={filter.fromValue}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    fromValue: e.target.value.replace(/\D/g, ''),
                  })
                }
              />
              <Box my={1} />
              <TextField
                margin="none"
                sx={{ width: 100 }}
                label="To"
                value={filter.toValue}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    toValue: e.target.value.replace(/\D/g, ''),
                  })
                }
              />
            </>
          ) : (
            <TextField
              margin="none"
              sx={{ width: 100 }}
              value={filter.value}
              onChange={(e) => setFilter({ ...filter, value: e.target.value })}
            />
          )}
        </>
      )}

      {type === 'date' && (
        <>
          {filter.range ? (
            // Make sure this part stays in a Fragment component
            <>
              <CustomDatePicker
                label="From"
                initialValue={filter.fromValue}
                onChange={(v) => setFilter({ ...filter, fromValue: v })}
                width={148}
                hideError
                m={0}
                listenToInitialValueChanges
              />
              <Box my={1} />
              <CustomDatePicker
                label="To"
                initialValue={filter.toValue}
                onChange={(v) => setFilter({ ...filter, toValue: v })}
                width={148}
                hideError
                m={0}
                listenToInitialValueChanges
              />
            </>
          ) : (
            // Make sure this part stays in a Box component
            <Box>
              <CustomDatePicker
                initialValue={filter.value || null}
                onChange={(v) => setFilter({ ...filter, value: v })}
                width={148}
                hideError
                m={0}
                listenToInitialValueChanges
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};
