import { createColumnHelper } from '@tanstack/react-table';
import { FC, useState } from 'react';
import { CustomTable } from 'src/components';
import { ScrollableTableWrapper } from '../../cruises/components/payload-items/ScrollableTableWrapper';
import { PayloadParking } from '../types/parkings.types';
import { columnDefs } from 'src/features/cruises/components/payload-items/columnDefs';
import { PayloadsToolbar } from 'src/features/cruises/components/payload-items/PayloadsToolbar';
import { Box, Checkbox } from '@mui/material';
import { ParkingEditDialog } from './ParkingEditDialog';
import { Link } from 'react-router-dom';
import { customDateFormat } from 'src/utils';
import { ParkingReportButton } from './ParkingReportButton';

const ch = createColumnHelper<PayloadParking>();

export const CruiseParkingsList: FC<{
  cruiseParkings: PayloadParking[];
  refreshCruiseParkings: () => void;
}> = ({ cruiseParkings, refreshCruiseParkings }) => {
  const [dialogPayloadId, setDialogPayloadId] = useState<string | null>(null);
  const [globalFilter, setGlobalFilter] = useState('');

  const columns = [
    columnDefs.rowNumber,
    ch.display({
      id: 'invoice',
      header: 'Invoice',
      cell: ({ row }) => (
        <ParkingReportButton orderId={row.original.id} useIconButton />
      ),
      enableSorting: false,
    }),
    columnDefs.orderStatus,
    ch.accessor('ticketNo', {
      header: 'Ticket No',
      cell: (info) => (
        <Link to="" onClick={() => setDialogPayloadId(info.row.original.id)}>
          {info.getValue()}
        </Link>
      ),
    }),
    ch.accessor('tariffName', { header: 'Product Type' }),
    ch.accessor('plateNumber', { header: 'Plate Number' }),
    ch.accessor('startDate', {
      header: 'Start Date',
      cell: (info) => customDateFormat(info.getValue()),
    }),
    ch.accessor('endDate', {
      header: 'End Date',
      cell: (info) => customDateFormat(info.getValue()),
    }),
    ch.accessor('realEndDate', {
      header: 'Real End Date',
      cell: (info) => customDateFormat(info.getValue()),
    }),
    ch.accessor('comment', {
      header: 'Comment',
      cell: (info) => {
        const comment = info.getValue() as string | undefined;
        if (!comment) return ''; 
        return comment.length > 40 ? comment.substring(0, 40) + "..." : comment;
      },
    }),
    ch.accessor('price', { header: 'Price' }),
    ch.accessor('extraPrice', { header: 'Extra Price' }),
    ch.accessor('portCost', { header: 'Port Cost' }),
    ch.accessor('paidPrice', {
      header: 'Paid Price',
      cell: (info) => (
        <Checkbox
          checked={info.getValue()}
          sx={{ width: 24, height: 24 }}
          size="small"
          readOnly
        />
      ),
    }),
    ch.accessor('paidExtraPrice', {
      header: 'Paid Extra Price',
      cell: (info) => (
        <Checkbox
          checked={info.getValue()}
          sx={{ width: 24, height: 24 }}
          size="small"
          readOnly
        />
      ),
    }),
    ch.accessor('terminal', { header: 'Terminal' }),
    ch.accessor('payer', { header: 'Payer' }),
  ];

  return (
    <Box m={1}>
      <ParkingEditDialog
        payloadId={dialogPayloadId}
        onClose={() => setDialogPayloadId(null)}
        refreshCruiseParkings={refreshCruiseParkings}
      />

      <PayloadsToolbar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        children={null}
      />

      <ScrollableTableWrapper minusVH={180}>
        <CustomTable
          data={cruiseParkings}
          columns={columns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          style={{ height: 1 }}
        />
      </ScrollableTableWrapper>
    </Box>
  );
};
