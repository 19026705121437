import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { CustomCheckbox } from '../forms/CustomCheckbox';

export const CheckboxRHF: FC<{
  label: string;
  name: string;
}> = ({ label, name }) => (
  <Controller
    name={name}
    render={({ field: { value, onChange } }) => (
      <CustomCheckbox label={label} checked={value} onChange={onChange} />
    )}
  />
);
