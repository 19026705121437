import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FC } from 'react';
import { Loading } from 'src/components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { capitalize } from '../../utils/cruise.utils';
import { CruiseDirection } from '../../types/direction.type';

export const CruiseDirections: FC<{
  dirs: CruiseDirection[] | undefined;
  dirId: string;
  setDirId: (dirId: string) => void;
}> = ({ dirs, dirId, setDirId }) => {
  return (
    <ToggleButtonGroup
      sx={{ bgcolor: 'white', minWidth: 250 }}
      orientation="vertical"
      color="primary"
      value={dirId}
      exclusive
      onChange={(_e, v) => v != null && setDirId(v)}
    >
      {dirs && (
        <ToggleButton value={''} sx={{ color: 'black', textTransform: 'none' }}>
          <Typography fontWeight={'' === dirId ? 'bold' : 'normal'}>
            All
          </Typography>
        </ToggleButton>
      )}

      {dirs ? (
        dirs.map((dir) => (
          <ToggleButton
            key={dir.id}
            value={dir.id}
            sx={{ color: 'black', textTransform: 'none' }}
          >
            <Typography fontWeight={dir.id === dirId ? 'bold' : 'normal'}>
              {capitalize(dir.depPortName)}
            </Typography>
            <ArrowForwardIcon fontSize="small" sx={{ mx: 0.5 }} />
            <Typography fontWeight={dir.id === dirId ? 'bold' : 'normal'}>
              {capitalize(dir.desPortName)}
            </Typography>
          </ToggleButton>
        ))
      ) : (
        <Loading />
      )}
    </ToggleButtonGroup>
  );
};
