import { Box, Dialog, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';
import { addCruise } from '../../api/cruises.api';
import { CreateUpdateCruiseDto, initCruise } from '../../types/cruise.type';
import { CruiseForm } from './CruiseForm';

export const AddCruiseButton: FC<{
  depPortId: number | undefined;
  desPortId: number | undefined;
  shipId: number | null | undefined;
  refresh: () => void;
}> = ({ depPortId, desPortId, shipId, refresh }) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleSave = (cruse: CreateUpdateCruiseDto) => {
    setSaving(true);
    addCruise(cruse)
      .then(() => {
        refresh();
        toast.success('Cruise Added!');
        setOpen(false);
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  if (depPortId == null || desPortId == null)
    return <Box width={30} height={30} />;

  return (
    <>
      <IconButton tabIndex={-1} onClick={() => setOpen(true)}>
        <AddIcon fontSize="small" />
      </IconButton>

      <Dialog open={open}>
        <CruiseForm
          initialValue={initCruise(depPortId, desPortId, shipId || null)}
          operation="Add"
          onSave={handleSave}
          saving={saving}
          closeDialog={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
};
