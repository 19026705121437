export type PayloadType =
  | 'Passenger'
  | 'Truck'
  | 'Car'
  | 'Wagon'
  | 'Container'
  | 'DeckCargo';

export const payloadTypes: PayloadType[] = [
  'Passenger',
  'Truck',
  'Car',
  'Wagon',
  'Container',
  'DeckCargo',
];
