import { Box, Button, Card, Divider } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import {
  AmountInputRHF,
  CustomCheckbox,
  TextFieldRHF,
  UpperTextFieldRHF,
} from 'src/components';
import { FinancialDataForm } from './FinancialDataForm';
import { PayloadBreadcrumbs } from './PayloadBreadcrumbs';
import { CompanySelector } from 'src/features/companies';
import { TariffSelectorRHF } from 'src/features/tariffs';
import { PaymentStatusInputRHF } from '../PaymentStatusInputRHF';
import { OrderStatusSelectorRHF } from '../OrderStatusSelector';

export const WagonForm: FC<{
  path: string;
  onRemove: () => void;
}> = ({ path, onRemove }) => {
  return (
    <Card sx={{ pb: 1 }}>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <PayloadBreadcrumbs path={path} label="Wagon" />

        <Button onClick={onRemove} color="error" tabIndex={-1}>
          Remove
        </Button>
      </Box>

      <Divider />

      <Box display="flex" columnGap={1} px={2} flexWrap="wrap">
        <TextFieldRHF label="Ticket No" name={`${path}.ticketNo`} disabled />

        <TariffSelectorRHF payloadType="Wagon" path={path} />

        <FinancialDataForm path={`${path}.amounts`} />

        <PaymentStatusInputRHF path={path} />
        <OrderStatusSelectorRHF path={`${path}.orderStatus`} />

        <UpperTextFieldRHF label="No" name={`${path}.no`} />
        <UpperTextFieldRHF label="Waybill No" name={`${path}.waybillNo`} />

        <Controller
          name={`${path}.empty`}
          render={({ field: { value, onChange } }) => (
            <CustomCheckbox label="Empty" checked={value} onChange={onChange} />
          )}
        />

        <AmountInputRHF
          label="Length m."
          name={`${path}.length`}
          type="float"
        />
        <AmountInputRHF
          label="Wagon weight kg."
          name={`${path}.weight`}
          type="float"
        />
        <AmountInputRHF
          label="Wagon tare weight kg."
          name={`${path}.tareWeight`}
          type="float"
        />

        {/* Cargo */}
        <TextFieldRHF label="Cargo name" name={`${path}.cargo.name`} />
        <AmountInputRHF
          label="Cargo amount"
          name={`${path}.cargo.amount`}
          type="int"
        />
        <AmountInputRHF
          label="Cargo gross weight kg."
          name={`${path}.cargo.grossWeight`}
          type="float"
        />
        <AmountInputRHF
          label="Cargo net weight kg."
          name={`${path}.cargo.netWeight`}
          type="float"
        />
        <AmountInputRHF
          label="Cargo volume m3."
          name={`${path}.cargo.volume`}
          type="float"
        />
        <TextFieldRHF
          label="Cargo unit of measure"
          name={`${path}.cargo.unitOfMeasure`}
        />

        {/* Other */}
        <TextFieldRHF label="Comment" name={`${path}.comment`} />

        <Controller
          name={`${path}.consignorId`}
          render={({ field: { value, onChange } }) => (
            <CompanySelector
              label="Shipper"
              companyId={value}
              onCompanyIdChange={onChange}
              hide={['Email', 'Phone']}
            />
          )}
        />

        <Controller
          name={`${path}.consigneeId`}
          render={({ field: { value, onChange } }) => (
            <CompanySelector
              label="Receiver"
              companyId={value}
              onCompanyIdChange={onChange}
              hide={['Email', 'Phone']}
            />
          )}
        />
      </Box>
    </Card>
  );
};
