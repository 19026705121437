import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { FC } from 'react';
import { useCharterers } from '../hooks/useCharterers.hook';

export const ChartererSelector: FC<{
  label?: string;
  disabled?: boolean;
  chartererId: number | null;
  onChange: (chartererId: number | null) => void;
  allowEmpty?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  width?: number | string;
}> = ({
  disabled,
  chartererId,
  onChange,
  allowEmpty,
  margin,
  width = 180,
  label = 'Charterer',
}) => {
  const { data: charterers } = useCharterers();

  return (
    <FormControl
      disabled={disabled || charterers == null}
      margin={margin}
      sx={{ width }}
    >
      {charterers == null ? (
        <Skeleton />
      ) : (
        <>
          <InputLabel shrink>{label}</InputLabel>
          <Select
            label={label}
            value={chartererId || ''}
            onChange={(e) => onChange(e.target.value as number)}
            notched
          >
            {allowEmpty && <MenuItem value={''}>All</MenuItem>}

            {charterers?.map((charterer) => (
              <MenuItem key={charterer.id} value={charterer.id}>
                {charterer.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </FormControl>
  );
};
