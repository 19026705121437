import { Button } from '@mui/material';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'src/components/ConfirmDialog';

export const ChartererDeleteButton: FC<{
  onDelete?: () => void;
}> = ({ onDelete }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color="error"
        endIcon={<DeleteIcon />}
      >
        Delete
      </Button>

      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        title="Delete this Charterer?"
        onConfirm={onDelete || (() => {})}
      />
    </>
  );
};
