import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';
import { FC } from 'react';
import { ChartererList } from 'src/features/charterers';
import { CompanyList } from 'src/features/companies';

const dicts: ('Companies' | 'Charterers')[] = ['Companies', 'Charterers'];

export const ContactsListPage: FC<{}> = () => {
  const [selected, setSelected] = useLocalStorage('contacts', dicts[0]);

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" gap={1.5}>
      <Box flexShrink={0}>
        <ToggleButtonGroup
          sx={{ bgcolor: 'white', minWidth: 150 }}
          orientation="vertical"
          color="primary"
          value={selected}
          exclusive
          onChange={(_e, v) => v != null && setSelected(v)}
        >
          {dicts.map((dict) => (
            <ToggleButton
              key={dict}
              value={dict}
              sx={{ color: 'black', textTransform: 'none' }}
            >
              <Typography fontWeight={dict === selected ? 'bold' : 'normal'}>
                {dict}
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>

      <Box flexGrow={1}>
        {selected === 'Companies' && <CompanyList />}
        {selected === 'Charterers' && <ChartererList />}
      </Box>
    </Box>
  );
};
