import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { navbarLinks } from '../types/navbarLinks.type';

export const NavBarMenuButton: FC<{}> = () => {
  const navigate = useNavigate();

  // Menu
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElNav(event.currentTarget);
  const closeMenu = () => setAnchorElNav(null);

  return (
    <>
      <IconButton onClick={openMenu} color="inherit" sx={{ p: 0 }}>
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorElNav}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorElNav)}
        onClose={closeMenu}
        sx={{ mt: 1.5 }}
      >
        {navbarLinks.map(({ to, title }) => (
          <MenuItem
            key={to}
            onClick={() => {
              navigate(to);
              closeMenu();
            }}
          >
            <Typography textAlign="center">{title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
