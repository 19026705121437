import { Box, Button, Card, Divider } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import {
  AmountInputRHF,
  CheckboxRHF,
  TextFieldRHF,
  UpperTextFieldRHF,
} from 'src/components';
import { FinancialDataForm } from './FinancialDataForm';
import { PayloadBreadcrumbs } from './PayloadBreadcrumbs';
import { CompanySelector } from 'src/features/companies';
import { TariffSelectorRHF } from 'src/features/tariffs';
import { TicketTypeSelector } from '../order-references/TicketTypeSelector';
import { PaymentStatusInputRHF } from '../PaymentStatusInputRHF';
import { OrderStatusSelectorRHF } from '../OrderStatusSelector';

export const ContainerForm: FC<{
  path: string;
  onRemove: () => void;
}> = ({ path, onRemove }) => {
  return (
    <Card sx={{ pb: 1 }}>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <PayloadBreadcrumbs path={path} label="Container" />

        <Button onClick={onRemove} color="error" tabIndex={-1}>
          Remove
        </Button>
      </Box>
      <Divider />
      <Box display="flex" columnGap={1} px={2} flexWrap="wrap">
        <TextFieldRHF label="Ticket No" name={`${path}.ticketNo`} disabled />
        <TariffSelectorRHF payloadType="Container" path={path} />
        <FinancialDataForm path={`${path}.amounts`} />

        <PaymentStatusInputRHF path={path} />
        <OrderStatusSelectorRHF path={`${path}.orderStatus`} />

        <Controller
          name={`${path}.refPayloadId`}
          render={({ field: { value: refPayloadId } }) => (
            <Controller
              name={`${path}.ticketType`}
              render={({ field }) => (
                <TicketTypeSelector
                  value={field.value}
                  onChange={field.onChange}
                  refPayloadId={refPayloadId}
                />
              )}
            />
          )}
        />

        <UpperTextFieldRHF label="No" name={`${path}.no`} />

        <AmountInputRHF
          label="Length m."
          name={`${path}.length`}
          type="float"
          disabled
          required
        />
        <AmountInputRHF
          label="Width m."
          name={`${path}.width`}
          type="float"
          disabled
          required
        />

        <AmountInputRHF
          label="Tare weight kg."
          name={`${path}.tareWeight`}
          type="float"
        />

        <TextFieldRHF label="Cargo name" name={`${path}.cargo.name`} required />
        <AmountInputRHF
          label="Cargo amount"
          name={`${path}.cargo.amount`}
          type="int"
        />
        <TextFieldRHF
          label="Cargo unit of measure"
          name={`${path}.cargo.unitOfMeasure`}
        />
        <AmountInputRHF
          label="Cargo net weight kg."
          name={`${path}.cargo.netWeight`}
          type="float"
        />
        <AmountInputRHF
          label="Cargo gross weight kg."
          name={`${path}.cargo.grossWeight`}
          type="float"
        />
        <AmountInputRHF
          label="Cargo volume m3."
          name={`${path}.cargo.volume`}
          type="float"
        />

        <AmountInputRHF
          label="Full weight"
          name={`${path}.fullWeight`}
          type="float"
        />

        <CheckboxRHF label="Charge" name={`${path}.charge`} />
        <CheckboxRHF label="ADR" name={`${path}.adr`} />

        <UpperTextFieldRHF label="Seal no" name={`${path}.sealNo`} />

        <TextFieldRHF label="Comment" name={`${path}.comment`} />

        <Controller
          name={`${path}.consignorId`}
          render={({ field: { value, onChange } }) => (
            <CompanySelector
              label="Shipper"
              companyId={value}
              onCompanyIdChange={onChange}
              hide={['Email', 'Phone']}
            />
          )}
        />

        <Controller
          name={`${path}.consigneeId`}
          render={({ field: { value, onChange } }) => (
            <CompanySelector
              label="Receiver"
              companyId={value}
              onCompanyIdChange={onChange}
              hide={['Email', 'Phone']}
            />
          )}
        />
      </Box>
    </Card>
  );
};
