import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { deleteCompany } from '../api/companies.api';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';

export const DeleteCompanyDialog: FC<{
  companyId: number;
  open: boolean;
  closeDialog: () => void;
  refetch: () => void;
}> = ({ companyId, open, closeDialog, refetch }) => {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    deleteCompany(companyId)
      .then(() => {
        toast.success('Company deleted!');
        refetch();
        closeDialog();
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setDeleting(false));
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Delete this Company?</DialogTitle>
      <DialogContent sx={{ minWidth: 250 }} />
      <DialogActions
        sx={{ mx: 2, mb: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <Button onClick={closeDialog}>No</Button>
        <LoadingButton loading={deleting} onClick={handleDelete} color="error">
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
