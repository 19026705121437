import { createColumnHelper } from '@tanstack/react-table';
import { FC, useMemo, useState } from 'react';
import { CruisePayload, cruiseWagons } from '../../types/cruise.type';
import { columnDefs } from './columnDefs';
import { PayloadsToolbar } from './PayloadsToolbar';
import { CustomTable } from 'src/components';
import { PayloadListProps } from '../../types/payloadListProps.type';
import { compact } from 'lodash';
import { ReportButton } from 'src/features/reports';
import { ScrollableTableWrapper } from './ScrollableTableWrapper';

const columnHelper = createColumnHelper<CruisePayload>();

export const WagonList: FC<PayloadListProps> = ({
  cruise,
  checks,
  setChecked,
  hideButtons,
}) => {
  const columns = useMemo(
    () =>
      compact([
        columnDefs.selected(checks, setChecked),
        hideButtons ? null : columnDefs.reports,
        columnDefs.paymentStatus,
        columnDefs.orderStatus,
        columnDefs.rowNumber,
        columnDefs.orderNo(hideButtons, cruise.no),
        columnHelper.accessor('no', { header: 'No' }),
        columnHelper.accessor('waybillNo', { header: 'Waybill No' }),
        columnHelper.accessor('cargoName', { header: 'Cargo Name' }),
        columnHelper.accessor('cargoFullWeight', { header: 'Full Weight' }),
        columnHelper.accessor('shipper', { header: 'Payer' }),
        columnHelper.accessor('charterer', { header: 'Charterer' }),
        columnHelper.accessor('createdByName', { header: 'Created By' }),
      ]),
    [checks, setChecked, hideButtons, cruise.no]
  );

  const data = cruiseWagons(cruise);

  const [globalFilter, setGlobalFilter] = useState('');

  return (
    <>
      <PayloadsToolbar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      >
        {cruise.id && (
          <>
            <ReportButton
              name="WagonManifest"
              params={{ cruiseId: cruise.id }}
              formats={['Word', 'Excel']}
            />
          </>
        )}
      </PayloadsToolbar>

      <ScrollableTableWrapper>
        <CustomTable
          data={data}
          columns={columns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </ScrollableTableWrapper>
    </>
  );
};
