import { Box, Card, Stack, TablePagination, TextField } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { GetCompanyListParams, getCompanyList } from '../api/companies.api';
import { createColumnHelper } from '@tanstack/react-table';
import { CompanyType } from '../type/company.type';
import { CustomTable, Loading } from 'src/components';
import { debounce } from 'lodash';
import { AddCompanyButton } from './AddCompanyButton';
import { MoreCompanyButton } from './MoreCompanyButton';

const ch = createColumnHelper<CompanyType>();

export const CompanyList: FC<{}> = () => {
  const [params, setParams] = useState<GetCompanyListParams>({
    page: { page: 0, size: 15 },
    name: '',
    companyId: '',
  });
  const {
    data: companies,
    mutate: refetch,
    isLoading,
  } = useSWR(['/api/companies', params], ([, params]) =>
    getCompanyList(params)
  );

  // Total count for pagination
  const [totalCount, setTotalCount] = useState(companies?.totalCount || -1);
  useEffect(() => {
    if (companies?.totalCount) {
      setTotalCount(companies.totalCount);
    }
  }, [companies?.totalCount]);

  const columns = useMemo(
    () => [
      ch.accessor('name', { header: 'Name', maxSize: 500 }),
      ch.accessor('companyId', { header: 'INN', maxSize: 500 }),
      ch.accessor('address', { header: 'Address' }),
      ch.accessor('countryName', { header: 'Country' }),
      ch.display({
        id: 'actions',
        header: () => <AddCompanyButton refresh={refetch} />,
        cell: (info) => (
          <MoreCompanyButton
            refresh={refetch}
            companyId={info.row.original.id}
          />
        ),
      }),
    ],
    [refetch]
  );

  const handleChangePage = (_event: any, newPage: number) => {
    setParams((p) => ({ ...p, page: { ...p.page, page: newPage } }));
  };

  const handleChangeRowsPerPage = (event: any) => {
    setParams((p) => ({ ...p, page: { page: 0, size: +event.target.value } }));
  };

  return (
    <Box>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          px: 1,
          py: 0.5,
          mb: 1.5,
        }}
      >
        <Stack direction="row" gap={2} flexWrap="wrap">
          <TextField
            margin="none"
            label="Name Filter"
            onChange={debounce(
              (e) =>
                setParams((p) => ({
                  ...p,
                  page: { page: 0, size: p.page.size },
                  name: e.target.value,
                })),
              800
            )}
          />
          <TextField
            margin="none"
            label="INN Filter"
            onChange={debounce(
              (e) =>
                setParams((p) => ({
                  ...p,
                  page: { page: 0, size: p.page.size },
                  companyId: e.target.value,
                })),
              800
            )}
          />
        </Stack>

        <TablePagination
          component="div"
          count={totalCount}
          page={params.page.page}
          onPageChange={handleChangePage}
          rowsPerPage={params.page.size}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows:"
          rowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
          showFirstButton
          showLastButton
        />
      </Card>

      {companies?.data && !isLoading ? (
        <CustomTable columns={columns} data={companies.data} />
      ) : (
        <Loading />
      )}
    </Box>
  );
};
