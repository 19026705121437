import { axiosInstance } from 'src/config/axios.config';
import { Tariff } from '../types/tariff.type';

export const getTariffs = async (): Promise<Tariff[]> =>
  (await axiosInstance.get<Tariff[]>('/api/tariffs')).data;

export const getTariff = async (id: number): Promise<Tariff> =>
  (await axiosInstance.get(`/api/tariffs/${id}`)).data;

export const createTariff = async (tariff: Tariff): Promise<number> =>
  (await axiosInstance.post('/api/tariffs', tariff)).data;

export const updateTariff = async (tariff: Tariff): Promise<Tariff> =>
  (await axiosInstance.put(`/api/tariffs`, tariff)).data;

export const deleteTariff = async (id: number): Promise<void> =>
  (await axiosInstance.delete(`/api/tariffs/${id}`)).data;
