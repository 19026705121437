import { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export const CustomToaster: FC = () => (
  <Toaster
    position="bottom-right"
    toastOptions={{
      style: { borderRadius: '4px' },
      error: {
        duration: 5000,
        icon: <ErrorOutlineIcon />,
        style: { backgroundColor: '#d32f2f', color: 'white' },
      },
      success: {
        icon: <TaskAltIcon />,
        style: { backgroundColor: '#2e7d32', color: 'white' },
      },
    }}
  />
);
