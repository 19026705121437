import { FC } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { startCase } from 'lodash';
import { TicketType, ticketTypes } from '../../types/payload.type';
import { OpenReferenceButton } from './OpenReferenceButton';

export const TicketTypeSelector: FC<{
  value: TicketType | null;
  onChange: (value: TicketType | null) => void;
  refPayloadId: string | null;
}> = ({ value, onChange, refPayloadId }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width={180}>
      <FormControl disabled={value === 'Return' || refPayloadId != null}>
        <InputLabel>Ticket Type</InputLabel>
        <Select
          label="Ticket Type"
          value={value}
          onChange={(e) => onChange(e.target.value as TicketType)}
        >
          {ticketTypes.map((s) => (
            <MenuItem key={s} value={s} disabled={s === 'Return'}>
              {startCase(s)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <OpenReferenceButton refPayloadId={refPayloadId} />
    </Box>
  );
};
