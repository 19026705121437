import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PaymentStatusInput } from './PaymentStatusInput';

export const PaymentStatusInputRHF: FC<{
  path: string;
}> = ({ path }) => {
  const { watch, setValue } = useFormContext();
  const [files, paymentStatus, ticketType] = watch([
    'files',
    path + '.paymentStatus',
    path + '.ticketType',
  ]);
  // Set payment status to 'Pending' if there are files attached
  useEffect(() => {
    if (
      files.length > 0 &&
      paymentStatus === 'Unpaid' &&
      ticketType !== 'Return'
    ) {
      setValue(path + '.paymentStatus', 'Pending');
    }
  }, [files, path, paymentStatus, ticketType, setValue]);

  return (
    <Controller
      name={path + '.paymentStatus'}
      render={({ field }) => (
        <PaymentStatusInput
          value={field.value}
          onChange={field.onChange}
          margin="normal"
          width={180}
        />
      )}
    />
  );
};
