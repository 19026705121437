import { LoadingButton } from '@mui/lab';
import { FC, useState } from 'react';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { GetFinancialRecordsRequest } from '../types/finances.types';
import { getFinancialRecordsExcel } from '../api/finances.api';

export const FinancesExcelButton: FC<{
  params: GetFinancialRecordsRequest;
}> = ({ params }) => {
  const [loading, setLoading] = useState(false);

  const onExport = () => {
    setLoading(true);
    getFinancialRecordsExcel(params).finally(() => setLoading(false));
  };

  return (
    <LoadingButton
      loading={loading}
      loadingPosition="end"
      endIcon={<RiFileExcel2Fill fontSize={18} />}
      color="success"
      onClick={onExport}
    >
      Excel
    </LoadingButton>
  );
};
