import { Button } from '@mui/material';
import { FC } from 'react';
import { Link, createSearchParams } from 'react-router-dom';
import { routes } from 'src/features/routes';

export const NewOrderButton: FC<{
  depPortId: number;
  desPortId: number;
  cruiseId: number | undefined;
  cruiseNo: string | undefined;
  disabled?: boolean;
}> = ({ disabled, depPortId, desPortId, cruiseId, cruiseNo }) => {
  if (!cruiseId) return null;

  return (
    <Button
      component={Link}
      disabled={disabled}
      to={
        routes.createOrder +
        `?${createSearchParams({
          depPortId: depPortId.toString(),
          desPortId: desPortId.toString(),
          cruiseId: cruiseId?.toString() || '',
          cruiseNo: cruiseNo || '',
        })}`
      }
    >
      Add
    </Button>
  );
};
