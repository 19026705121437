import { Alert, Card, Divider } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Loading } from 'src/components';
import { CruiseHeader } from './CruiseHeader';
import { PayloadTabs } from '../payload-items/PayloadTabs';
import { useSelectedPayloads } from '../../hooks/useSelectedPayloads.hook';
import { getCruise } from '../../api/cruises.api';
import { is404Error } from 'src/utils';

export const CruisePage: FC<{}> = () => {
  const id = +(useParams().id || -1);
  const {
    data: cruise,
    mutate: refetchCruises,
    error,
  } = useSWR(`/cruises/${id}`, () => getCruise(id));

  const { checks, setChecked, clearChecks } = useSelectedPayloads(
    cruise?.payloads
  );

  if (is404Error(error))
    return (
      <Alert variant="filled" severity="error">
        Cruise was not found! It may have been deleted.
      </Alert>
    );

  if (!cruise) return <Loading />;

  return (
    <Card>
      <CruiseHeader
        cruise={cruise}
        refetchCruises={refetchCruises}
        checks={checks}
        clearChecks={clearChecks}
      />

      <Divider />

      <PayloadTabs
        cruise={cruise}
        checks={checks}
        setChecked={setChecked}
        hideButtons={false}
        refetchCruises={refetchCruises}
      />
    </Card>
  );
};
