import { FC } from 'react';
import { useAccessGroups } from '../hooks/useAccessGroups.hook';
import { Skeleton } from '@mui/material';
import { GenericSelector } from 'src/components';

export const AccessGroupSelector: FC<{
  accessGroupId: number | null;
  onChange: (accessGroupId: number | null) => void;
}> = ({ accessGroupId, onChange }) => {
  const { data: accessGroups } = useAccessGroups();

  if (!accessGroups) return <Skeleton height={40} sx={{ mt: 2, mb: 1 }} />;

  return (
    <GenericSelector
      label="Access Group"
      value={accessGroupId}
      onChange={(accessGroupId) => onChange(accessGroupId as number)}
      options={
        accessGroups?.map((accessGroup) => ({
          id: accessGroup.id,
          name: accessGroup.name,
        })) || []
      }
    />
  );
};
