import {
  Box,
  Chip,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { FC } from 'react';
import StraightenIcon from '@mui/icons-material/Straighten';
import { getOrderStatusColors } from 'src/features/orders';
import { startCase } from 'lodash';

export const CargoLengthIcon: FC<{
  cargoLength: number;
  cargoLengthByOrderStatus: { [key: string]: number };
}> = ({ cargoLength, cargoLengthByOrderStatus }) => (
  <>
    <HtmlTooltip
      title={
        <CargoLengthByIcon
          cargoLengthByOrderStatus={cargoLengthByOrderStatus}
        />
      }
      placement="bottom"
    >
      <StraightenIcon fontSize="small" sx={{ ml: 2.5, mr: 0.5 }} />
    </HtmlTooltip>
    <Typography>{cargoLength}m</Typography>
  </>
);

const CargoLengthByIcon: FC<{
  cargoLengthByOrderStatus: { [key: string]: number };
}> = ({ cargoLengthByOrderStatus }) => {
  return (
    <>
      {Object.entries(cargoLengthByOrderStatus).map(([key, value]) => {
        const { bg, fg } = getOrderStatusColors(key);
        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width={175}
            my={0.5}
          >
            <Chip
              size="small"
              label={startCase(key)}
              sx={{ backgroundColor: bg, color: fg }}
            />
            <Typography fontWeight="bold">{value.toFixed(1)}</Typography>
          </Box>
        );
      })}
    </>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
