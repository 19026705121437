import { FC, useState } from 'react';
import { Box, Button, Card, Divider, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { routes } from 'src/features/routes';
import { User } from '../types/user.type';
import { AccessGroupSelector } from './AccessGroupSelector';

export const UserForm: FC<{
  initialValue: User;
  save: (newData: User) => void;
  loading: boolean;
  refetch?: () => void;
}> = ({ initialValue, save, loading, refetch }) => {
  const [user, setUser] = useState(initialValue);

  return (
    <Card>
      <Box px={2} py={1} display="flex" flexWrap="wrap" columnGap={2}>
        <TextField
          label="Full Name"
          value={user.name}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
          error={user.name === ''}
        />

        <TextField
          label="Email"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          error={user.email === ''}
        />

        <TextField
          label="Password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          error={user.password === ''}
          type="password"
        />

        <AccessGroupSelector
          accessGroupId={user.accessGroupId}
          onChange={(accessGroupId) => setUser({ ...user, accessGroupId })}
        />
      </Box>

      <Divider />

      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <Button
          component={Link}
          to={routes.access}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          onClick={() => save(user)}
          endIcon={<SaveIcon />}
          disabled={
            user.name === '' || user.email === '' || user.password === ''
          }
        >
          Save
        </LoadingButton>
      </Box>
    </Card>
  );
};
