import { SxProps, TextField, Theme } from '@mui/material';
import { FC, useEffect, useState } from 'react';

export const AmountInput: FC<{
  label?: string;
  value: number;
  onChange: (value: number) => void;
  type: 'int' | 'float';
  disabled?: boolean;
  margin?: 'none' | 'dense' | 'normal' | undefined;
  required?: boolean;
  width?: number;
  sx?: SxProps<Theme>;
  error?: boolean;
}> = ({
  label,
  value,
  onChange,
  type,
  disabled,
  margin,
  required,
  width,
  sx,
  error,
}) => {
  const [inputValue, setInputValue] = useState(value.toString());

  useEffect(() => {
    const newValue = parseFloat(inputValue || '0');
    if (newValue !== value) onChange(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  return (
    <TextField
      disabled={disabled}
      label={label}
      value={inputValue}
      onChange={(e) => {
        let newValue =
          type === 'float'
            ? e.target.value
                .replaceAll(',', '.')
                .replaceAll(/[^\d.]/g, '')
                .substring(0, 15)
            : e.target.value.replaceAll(/\D/g, '').substring(0, 15);

        if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.')
          newValue = newValue.substring(1);

        setInputValue(newValue);
      }}
      inputProps={{ autoComplete: 'off' }}
      margin={margin}
      error={error || (required && value === 0)}
      sx={{ width, ...sx }}
    />
  );
};
