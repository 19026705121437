import { useState } from 'react';
import { deleteFiles } from '../api/files.api';
import { Directory } from '../types/file.types';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';

export const useFilesDelete = (
  files: string[],
  setFiles: (files: string[]) => void,
  table: Directory,
  id: number | string
) => {
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [deleting, setDeleting] = useState(false);

  const deleteSelectedFiles = () => {
    setDeleting(true);
    return deleteFiles(selectedFiles, table, id)
      .then((res) => {
        setFiles(res);
        setSelectedFiles([]);
        toast.success('Files deleted successfully!');
      })
      .catch((err) => toast.error(getErrorMessage(err)))
      .finally(() => setDeleting(false));
  };

  const fileSelected = (fileName: string) => selectedFiles.includes(fileName);

  const toggleFileSelection = (fileName: string, selected: boolean) => {
    if (selected) setSelectedFiles([...selectedFiles, fileName]);
    else setSelectedFiles(selectedFiles.filter((name) => name !== fileName));
  };

  const setSelectionForAllFiles = (selected: boolean) => {
    if (selected) setSelectedFiles(files);
    else setSelectedFiles([]);
  };

  const noFilesSelected = selectedFiles.length === 0;
  const allFilesSelected = selectedFiles.length === files.length;

  return {
    fileSelected,
    toggleFileSelection,
    deleteSelectedFiles,
    setSelectionForAllFiles,
    noFilesSelected,
    allFilesSelected,
    deleting,
  };
};
