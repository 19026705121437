import { axiosInstance } from 'src/config/axios.config';
import { Charterer } from '../types/charterer.type';

const endpoint = '/api/charterers';

export const getCharterers = async (): Promise<Charterer[]> =>
  (await axiosInstance.get(endpoint)).data;

export const getCharterer = async (id: number): Promise<Charterer> =>
  (await axiosInstance.get(`${endpoint}/${id}`)).data;

export const createCharterer = async (charterer: Charterer): Promise<number> =>
  (await axiosInstance.post(endpoint, charterer)).data;

export const updateCharterer = async (
  charterer: Charterer
): Promise<Charterer> => (await axiosInstance.put(endpoint, charterer)).data;

export const deleteCharterer = async (id: number): Promise<void> =>
  (await axiosInstance.delete(`${endpoint}/${id}`)).data;
