import {
  Autocomplete,
  Box,
  Dialog,
  IconButton,
  Skeleton,
  TextField,
} from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { getCompany, getCompanyList } from '../api/companies.api';
import { CompanyType } from '../type/company.type';
import { debounce } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import { EditCompanyDialogContent } from './EditCompanyDialogContent';
import { AddCompanyButton } from './AddCompanyButton';

const CompanySelectorMain: FC<{
  label: string;
  initialCompanies: CompanyType[];
  onCompanyIdChange: (companyId: number | null) => void;
  hide?: ('INN' | 'Email' | 'Phone')[];
  hideButtons?: boolean;
  required?: boolean;
}> = ({
  label,
  initialCompanies,
  onCompanyIdChange,
  hide,
  hideButtons,
  required,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [company, setCompany] = useState<CompanyType | null>(
    initialCompanies[0]
  );
  const [companies, setCompanies] = useState<CompanyType[]>(initialCompanies);
  const [loading, setLoading] = useState(false);

  // Edit Dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const fetchCompanies = useMemo(
    () =>
      debounce(async (filter: string) => {
        let response = (
          await getCompanyList({
            name: filter,
            page: { page: 0, size: 20 },
            companyId: '',
          })
        ).data;
        if (company && !response.some((c) => c.id === company.id))
          response = response.concat(company);
        setCompanies(response);
        setLoading(false);
      }, 800),
    [company]
  );

  useEffect(() => {
    setLoading(true);
    setCompanies([]);
    fetchCompanies(inputValue);
  }, [fetchCompanies, inputValue]);

  return (
    <>
      <Box display="block">
        <Box display="flex">
          <Autocomplete
            // Input Value
            inputValue={inputValue || ''}
            onInputChange={(_e, nv) => setInputValue(nv)}
            // Value
            value={company || null}
            onChange={(_e, nv) => {
              setCompany(nv);
              onCompanyIdChange(nv?.id || null);
            }}
            // Options
            options={companies || []}
            autoHighlight
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(o) => o.name}
            filterOptions={(x) => x}
            // Loading
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label + ' Name'}
                sx={{ width: 300 }}
                error={required && !company}
              />
            )}
          />

          {!hideButtons && (
            <Box pt={2.5}>
              <IconButton
                tabIndex={-1}
                onClick={() => setEditDialogOpen(true)}
                disabled={!company}
              >
                <EditIcon />
              </IconButton>

              <AddCompanyButton
                fontSize="medium"
                mt={0}
                refresh={(newCompany) => {
                  onCompanyIdChange(newCompany.id);
                  setCompany(newCompany);
                  setInputValue(newCompany.name);
                  setLoading(true);
                  setCompanies([]);
                  getCompanyList({
                    name: inputValue,
                    companyId: '',
                    page: { page: 0, size: 20 },
                  })
                    .then((response) => response.data)
                    .then((response) => {
                      response = response.concat(newCompany);
                      setCompanies(response);
                      setLoading(false);
                    });
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      {!hide?.includes('INN') && (
        <TextField
          label={label + ' INN'}
          value={company?.companyId || ''}
          disabled
        />
      )}

      {!hide?.includes('Email') && (
        <TextField
          label={label + ' Email'}
          value={company?.email || ''}
          disabled
        />
      )}

      {!hide?.includes('Phone') && (
        <TextField
          label={label + ' Phone'}
          value={company?.phone || ''}
          disabled
        />
      )}

      <Dialog open={editDialogOpen}>
        <EditCompanyDialogContent
          closeDialog={() => setEditDialogOpen(false)}
          companyId={company?.id || 0}
          refresh={async () => {
            const updatedCompany = await getCompany(company?.id || 0);
            setCompany(updatedCompany);
            setLoading(true);
            setCompanies([]);
            fetchCompanies(inputValue);
          }}
        />
      </Dialog>
    </>
  );
};

export const CompanySelector: FC<{
  label: string;
  companyId: number | null;
  onCompanyIdChange: (companyId: number | null) => void;
  hide?: ('INN' | 'Email' | 'Phone')[];
  hideButtons?: boolean;
  required?: boolean;
}> = ({ label, companyId, onCompanyIdChange, hide, hideButtons, required }) => {
  const [loading, setLoading] = useState(true);
  const [initialCompanies, setInitialCompanies] = useState<CompanyType[]>([]);

  useEffect(() => {
    if (!companyId) {
      setLoading(false);
    } else {
      getCompany(companyId).then((company) => {
        setInitialCompanies([company]);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <>
        <Skeleton width={hideButtons ? 300 : 368} sx={{ mt: 2, mb: 1 }} />
        {!hide?.includes('INN') && (
          <Skeleton width={180} sx={{ mt: 2, mb: 1 }} />
        )}
        {!hide?.includes('Email') && (
          <Skeleton width={180} sx={{ mt: 2, mb: 1 }} />
        )}
        {!hide?.includes('Phone') && (
          <Skeleton width={180} sx={{ mt: 2, mb: 1 }} />
        )}
      </>
    );

  return (
    <CompanySelectorMain
      label={label}
      initialCompanies={initialCompanies}
      onCompanyIdChange={onCompanyIdChange}
      hide={hide}
      hideButtons={hideButtons}
      required={required}
    />
  );
};
