import { PayloadType } from 'src/features/orders';

export interface Tariff {
  id: number;
  type: PayloadType;
  name: string;
  geoTariffs: TariffFinancialData;
  eurTariffs: TariffFinancialData;
  twoWayTariffs: TariffFinancialData;
  length: number;
  width: number;
  height: number;
  comment: string;
  parkingTariffType: string; 
}

export interface TariffFinancialData {
  freight: number;
  terminalDepPort: number;
  terminalDesPort: number;
  sum: number;
}

export const initTariff = (): Tariff => ({
  id: 0,
  type: 'Truck',
  name: '',
  geoTariffs: {
    freight: 0,
    terminalDepPort: 0,
    terminalDesPort: 0,
    sum: 0,
  },
  eurTariffs: {
    freight: 0,
    terminalDepPort: 0,
    terminalDesPort: 0,
    sum: 0,
  },
  twoWayTariffs: {
    freight: 0,
    terminalDepPort: 0,
    terminalDesPort: 0,
    sum: 0,
  },
  length: 0,
  width: 0,
  height: 0,
  comment: '',
  parkingTariffType: 'Other',
});
