import { createColumnHelper } from '@tanstack/react-table';
import { CruisePayload } from '../../types/cruise.type';
import { Box, Checkbox, Chip } from '@mui/material';
import { getColor } from 'src/features/orders';
import { routes } from 'src/features/routes';
import { Link } from 'react-router-dom';
import { GenericInvoiceReportButton, ReportButton } from 'src/features/reports';
import { TENANT } from 'src/config/app.config';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { OrderStatusChip } from 'src/components';

const columnHelper = createColumnHelper<CruisePayload>();

export const columnDefs = {
  rowNumber: columnHelper.display({
    id: 'RowNumber',
    header: '№',
    cell: (info) => info.row.index + 1,
  }),

  orderNo: (hideLink: boolean, _cruiseNo: string | null | undefined) =>
    columnHelper.accessor('orderNo', {
      header: 'Order No.',
      cell: (info) =>
        hideLink ? (
          info.getValue()
        ) : (
          <Link
            style={{ whiteSpace: 'nowrap', margin: '0 5px' }}
            to={routes.editOrder(info.row.original.orderId)}
          >
            {info.getValue()}
          </Link>
        ),
    }),

  paymentStatus: columnHelper.accessor('paymentStatus', {
    header: 'Payment Status',
    cell: (info) => (
      <Chip
        size="small"
        label={info.getValue()}
        color={getColor(info.getValue())}
      />
    ),
  }),

  orderStatus: columnHelper.accessor('orderStatus', {
    header: 'Order Status',
    cell: (info) => <OrderStatusChip>{info.getValue()}</OrderStatusChip>,
  }),

  reports: columnHelper.display({
    id: 'reports',
    header: 'Reports',
    cell: (info) => (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={0.25}
      >
        <GenericInvoiceReportButton
          orderId={info.row.original.orderId}
          size="small"
          useIconButton
        />

        {
          <ReportButton
            label="Ticket"
            name={TENANT === 'E60' ? 'E60Ticket' : 'BSFTicket'}
            params={{ payloadId: info.row.original.id }}
            size="small"
            formats={['Pdf', 'Preview']}
            useIconButton
            iconButtonIcon={<ConfirmationNumberIcon />}
          />
        }
      </Box>
    ),
  }),

  selected: (
    checks: string[],
    setChecked: (id: string, checked: boolean) => void
  ) =>
    columnHelper.display({
      id: 'checks',
      cell: (info) => (
        <Checkbox
          disabled={info.row.original.isDriver}
          sx={{ width: 24, height: 24 }}
          size="small"
          checked={checks.includes(info.row.original.id)}
          onChange={(e) => setChecked(info.row.original.id, e.target.checked)}
        />
      ),
    }),
};
