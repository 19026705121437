export type SearchColumnsType =
  | 'Payload Type'
  | 'Cruise No'
  | 'Cruise Date'
  | 'Ship'
  | 'Departure Port'
  | 'Destination Port'
  | 'Order No'
  | 'Order Date'
  | 'Payment Status'
  | 'Order Status'
  | 'Ticket Type'
  | 'Charterer'
  | 'Shipper'
  | 'Created By'
  | 'Payment Comment'
  // Payload
  | 'No'
  | 'Name'
  | 'Surname'
  | 'Passport'
  | 'Personal Id'
  | 'Freight Rate'
  | 'Tariff'
  | 'Phone Number';

export const searchColumns: SearchColumnsType[] = [
  'Payload Type',
  'Cruise No',
  'Cruise Date',
  'Ship',
  'Departure Port',
  'Destination Port',
  'Order No',
  'Order Date',
  'Order Status',
  'Payment Status',
  'Payment Comment', 
  'Ticket Type',
  'Charterer',
  'Shipper',
  'Created By',
  // Payload
  'No',
  'Name',
  'Surname',
  'Passport',
  'Personal Id',
  'Freight Rate',
  'Tariff',
  'Phone Number'
];
