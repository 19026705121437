import {
  Box,
  Chip,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Cruise,
  ListOfWaiting,
  cruiseAllCargo,
  cruiseCars,
  cruiseContainers,
  cruiseDeckCargo,
  cruisePassegers,
  cruiseTrucks,
  cruiseWagons,
} from '../../types/cruise.type';
import { useLocalStorage } from '@uidotdev/usehooks';
import { PassengerList } from './PassengerList';
import { TruckList } from './TruckList';
import { CarList } from './CarList';
import { ContainerList } from './ContainerList';
import { WagonList } from './WagonList';
import { DeckCargoList } from './DeckCargoList';
import { AllCargoList } from './AllCargoList';
import { KeyedMutator } from 'swr';

const tabs = [
  { label: 'All Cargo', selector: cruiseAllCargo, Component: AllCargoList },
  { label: 'Trucks', selector: cruiseTrucks, Component: TruckList },
  { label: 'Deck Cargo', selector: cruiseDeckCargo, Component: DeckCargoList },
  { label: 'Containers', selector: cruiseContainers, Component: ContainerList },
  { label: 'Wagons', selector: cruiseWagons, Component: WagonList },
  { label: 'Cars', selector: cruiseCars, Component: CarList },
  { label: 'Passengers', selector: cruisePassegers, Component: PassengerList },
];

export const PayloadTabs: FC<{
  cruise: Cruise | ListOfWaiting;
  checks: string[];
  setChecked: (id: string, checked: boolean) => void;
  hideButtons: boolean;
  refetchCruises: KeyedMutator<Cruise>;
}> = ({ cruise, checks, setChecked, hideButtons, refetchCruises }) => {
  const [tab, setTab] = useLocalStorage('tabs', 'Passengers');

  const theme = useTheme();
  const scrollableTabs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <TabList
          onChange={(_e, v) => setTab(v)}
          // allowScrollButtonsMobile
          scrollButtons={scrollableTabs}
          variant={scrollableTabs ? 'scrollable' : 'fullWidth'}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {tabs.map(({ label, selector }) => (
            <Tab
              key={label}
              value={label}
              label={<TabLabel text={label} count={selector(cruise).length} />}
              disableRipple
              sx={{
                backgroundColor: tab === label ? 'white' : 'whitesmoke',
                textTransform: 'none',
                borderBottom: tab === label ? 0 : 1,
                borderLeft: tab === label && tab !== 'All Cargo' ? 1 : 0,
                borderRight: tab === label && tab !== 'Passengers' ? 1 : 0,
                borderColor: 'divider',
              }}
            />
          ))}
        </TabList>
      </Box>

      {tabs.map(({ label, Component }) => (
        <TabPanel key={label} value={label}>
          <Component
            cruise={cruise}
            checks={checks}
            setChecked={setChecked}
            hideButtons={hideButtons}
            refetchCruises={refetchCruises}
          />
        </TabPanel>
      ))}
    </TabContext>
  );
};

const TabLabel: FC<{ text: string; count: number }> = ({ text, count }) => (
  <Box display="flex" alignItems="center" gap={0.5} color="black">
    <Typography color="black">{text}</Typography>
    <Chip
      size="small"
      sx={{ fontWeight: 'bold', minWidth: 25 }}
      label={count}
    />
  </Box>
);
