import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { GlobalFilterInput } from './GlobalFilterInput';

export const PayloadsToolbar: FC<{
  children: ReactNode;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}> = ({ children, globalFilter, setGlobalFilter }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    flexWrap="wrap"
    gap={1}
    mb={1}
  >
    <Box display="flex" flexWrap="wrap" gap={1}>
      {children}
    </Box>

    <GlobalFilterInput
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
    />
  </Box>
);
