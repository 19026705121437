import { FC, useEffect, useState } from 'react';
import { SearchToolbar } from './SearchToolbar';
import { SearchTable } from './SearchTable';
import { Search, SearchFilters, initSearch } from '../types/search.type';
import { exportPayloads, searchPayloads } from '../api/search.api';
import {
  SearchResponse,
  initSearchResponse,
} from '../types/searchResponse.type';
import { Box } from '@mui/material';
import toast from 'react-hot-toast';
import { useLocalStorage } from '@uidotdev/usehooks';

export const SearchPage: FC<{}> = () => {
  const [search, setSearch] = useLocalStorage<Search>('search_4', initSearch());
  const [data, setData] = useState<SearchResponse>(initSearchResponse());
  const [loading, setLoading] = useState(false);

  const fetchData = (providedSearch: Search) => {
    setLoading(true);
    searchPayloads(providedSearch)
      .then((d) => setData(d))
      .catch((e) => toast.error(JSON.stringify(e)))
      .finally(() => setLoading(false));
  };

  const onSearch = () => {
    const searchWithResetPaging: Search = {
      ...search,
      paging: { page: 0, size: search.paging.size },
    };
    setSearch(searchWithResetPaging);
    fetchData(searchWithResetPaging);
  };

  const onClearFilters = () => {
    const searchWithClearFilters: Search = {
      ...search,
      paging: { page: 0, size: search.paging.size },
      filters: initSearch().filters,
    };
    setSearch(searchWithClearFilters);
    fetchData(searchWithClearFilters);
  };

  const onExport = () => {
    setLoading(true);
    exportPayloads(search).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.paging.page, search.paging.size]);

  return (
    <Box>
      <SearchToolbar
        search={search}
        setPaging={(paging) => setSearch((s) => ({ ...s, paging }))}
        setColumns={(columns) => {
          const searchWithChangedColumns: Search = {
            ...initSearch(),
            paging: { page: 0, size: search.paging.size },
            visibleColumns: columns,
          };
          setSearch(searchWithChangedColumns);
          fetchData(searchWithChangedColumns);
        }}
        setShowDeleted={(showDeleted) =>
          setSearch((s) => ({ ...s, showDeleted }))
        }
        totalCount={data?.totalCount}
        loading={loading}
        onSearch={onSearch}
        clearFilters={onClearFilters}
        onExport={onExport}
      />

      <SearchTable
        search={search}
        setFilters={(filters: SearchFilters) =>
          setSearch((s) => ({ ...s, filters }))
        }
        data={data.data}
      />
    </Box>
  );
};
