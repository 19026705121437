import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC } from 'react';
import { PaymentStatus, paymentStatuses } from '../types/payload.type';

export const PaymentStatusInput: FC<{
  value: PaymentStatus | null;
  onChange: (status: PaymentStatus | null) => void;
  allowEmptyValue?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  width?: number;
}> = ({ value, onChange, allowEmptyValue, margin, width = 110 }) => {
  return (
    <FormControl margin={margin} sx={{ width }}>
      {!allowEmptyValue && <InputLabel>Payment Status</InputLabel>}
      <Select
        value={value || ''}
        onChange={(e) => onChange(e.target.value as PaymentStatus)}
        label={allowEmptyValue ? '' : 'Payment Status'}
      >
        {allowEmptyValue && <MenuItem value={''}>All</MenuItem>}

        {paymentStatuses.map((s) => (
          <MenuItem key={s} value={s}>
            {s}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
