import { axiosInstance } from 'src/config/axios.config';
import { City } from '../types/city.type';

export const getPorts = async (): Promise<City[]> => {
  const data = await axiosInstance
    .get('/api/cities/ports')
    .then((response) => response.data);
  return data;
};

export const getCities = async (): Promise<City[]> => {
  const data = await axiosInstance
    .get('/api/cities')
    .then((response) => response.data);
  return data;
};
