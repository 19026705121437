import { axiosInstance } from 'src/config/axios.config';
import { Bill } from '../types/bill.types';
import { today } from 'src/utils';
import { transferTypes } from '../types/finances.types';

const endpoint = '/api/bill';

export const getBillRecord = async (payloadId: string): Promise<Bill> => {
  const response = await axiosInstance.get(`${endpoint}/${payloadId}`);
  const data = response.data;

  const bill: Bill = {
    ticketNo: data?.ticketNo || '',
    agentName: data?.agentName || '',
    payloadNumber: data?.payloadNumber || '',
    credit: data?.credit || 0,
    debit: data?.debit || 0,
    transactionDate: data?.transactionDate || today(),
    transactionType: data?.transactionType || transferTypes[0],
    transactionPlaceId: data?.transactionPlaceId || 1,
    eurAmount: data?.eurAmount || 0,
    usdAmount: data?.usdAmount || 0,
    gelAmount: data?.gelAmount || 0,
  };

  return bill;
};

export const saveBillRecord = async (
  payloadId: string,
  bill: Bill
): Promise<void> => await axiosInstance.put(`${endpoint}/${payloadId}`, bill);
