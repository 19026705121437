import { sumFinancialData } from './financialData.type';
import { Payload } from './payload.type';

export interface Order {
  id: number;
  cruiseId: number;
  cruiseNo: string;
  no: string;
  orderDate: string;
  departurePortId: number;
  destinationPortId: number;
  chartererId: number;
  comment: string;
  lastModifiedBy: string | null;
  createdBy: string | null;
  createdOn: string | null;
  customerId: number | null;
  payloads: Payload[];
  files: string[];
}

// Order Type
export type OrderType =
  | 'Passenger'
  | 'Truck'
  | 'DeckCar'
  | 'Wagon'
  | 'Conatiner'
  | 'DeckCargo';

// Order funcs
export const initOrder = (
  departurePortId: number,
  destinationPortId: number,
  cruiseId: number,
  cruiseNo: string
): Order => ({
  id: 0,
  cruiseId,
  cruiseNo,
  no: '',
  orderDate: new Date().toISOString().slice(0, 10),
  departurePortId: departurePortId,
  destinationPortId: destinationPortId,
  chartererId: 1,
  comment: '',
  lastModifiedBy: '',
  createdBy: '',
  createdOn: null,
  customerId: null,
  payloads: [],
  files: [],
});

export const getOrderTotalAmount = (order: Order): number => {
  return order.payloads.reduce((acc, payload) => {
    let sum = acc;
    if (payload.orderStatus !== 'Cancelled')
      sum += sumFinancialData(payload.amounts);
    if (payload.driver?.orderStatus !== 'Cancelled')
      sum += sumFinancialData(payload.driver?.amounts);
    if (payload.driver2?.orderStatus !== 'Cancelled')
      sum += sumFinancialData(payload.driver2?.amounts);
    return sum;
  }, 0);
};

export const orderValid = (order: Order): boolean => {
  return (
    isValidDate(order.orderDate) && // Order date valid
    !!order.customerId && // Order Payer is selected
    order.payloads.length > 0 && // Order has at least one Payload
    // Payload fields valid
    order.payloads
      .filter((p) =>
        ['Container', 'Car', 'DeckCargo', 'Truck'].includes(p.type)
      )
      .every(
        (p) =>
          p.length > 0 && // Length
          p.width > 0 // Width
      ) &&
    // Container fields valid
    order.payloads
      .filter((p) => p.type === 'Container')
      .every(
        (p) => p.cargo.name.length > 0 // Cargo name
      ) &&
    // Truck/Deck Cargo fields valid
    order.payloads
      .filter((p) => p.type === 'DeckCargo' || p.type === 'Truck')
      .every(isValidTruckOrDeckCargo)
  );
};

const isValidTruckOrDeckCargo = (payload: Payload): boolean => {
  if (payload.tariffId == null) return false; // Tariff must be selected
  if (payload.no.length === 0) return false; // No must be set
  if (payload.cargo.name.length === 0) return false; // Cargo name must be set
  if (payload.phoneNo.length === 0) return false; // Phone no must be set
  // Freight must be set for non-return non-cancelled payloads
  if (
    payload.ticketType !== 'Return' &&
    payload.orderStatus !== 'Cancelled' &&
    payload.amounts.freight === 0
  )
    return false;
  return true;
};

const isValidDate = (dateString: string | null | undefined): boolean => {
  if (dateString == null) return false;
  if (dateString.length < 8) return false;

  return true;
};
