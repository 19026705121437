import { Card, Stack, TablePagination } from '@mui/material';
import { FC } from 'react';
import { Search, SearchPaging } from '../types/search.type';
import { SearchColumnSelector } from './SearchColumnSelector';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import { RiFileExcel2Fill } from 'react-icons/ri';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

export const SearchToolbar: FC<{
  search: Search;
  setPaging: (paging: SearchPaging) => void;
  setColumns: (columns: string[]) => void;
  setShowDeleted: (showDeleted: boolean) => void;
  totalCount: number;
  loading: boolean;
  onSearch: () => void;
  clearFilters: () => void;
  onExport: () => void;
}> = ({
  search,
  setPaging,
  setColumns,
  totalCount,
  loading,
  onSearch,
  clearFilters,
  onExport,
}) => {
  const handleChangePage = (_event: any, newPage: number) => {
    setPaging({ ...search.paging, page: newPage });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPaging({ ...search.paging, size: +event.target.value });
  };

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        px: 1,
        py: 0.5,
        mb: 1.5,
      }}
    >
      <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
        {/* <CustomCheckbox label="Show Deleted" checked={search.showDeleted} 
        onChange={setShowDeleted} mt={0} /> */}

        <SearchColumnSelector
          columns={search.visibleColumns}
          setColumns={setColumns}
        />

        <LoadingButton
          loading={loading}
          loadingPosition="end"
          endIcon={<RiFileExcel2Fill fontSize={18} />}
          color="success"
          onClick={onExport}
        >
          Excel
        </LoadingButton>

        <LoadingButton
          loading={loading}
          loadingPosition="end"
          endIcon={<FilterAltOffIcon />}
          onClick={clearFilters}
          color="warning"
        >
          Clear Filters
        </LoadingButton>

        <LoadingButton
          loading={loading}
          loadingPosition="end"
          endIcon={<SearchIcon />}
          onClick={onSearch}
        >
          Search
        </LoadingButton>
      </Stack>

      <TablePagination
        component="div"
        count={totalCount}
        page={search.paging.page}
        onPageChange={handleChangePage}
        rowsPerPage={search.paging.size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows:"
        rowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
        showFirstButton
        showLastButton
      />
    </Card>
  );
};
