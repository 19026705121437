import { ChangeEvent, FC } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from '@mui/lab';
import { uploadFiles } from '../api/files.api';
import toast from 'react-hot-toast';
import { Directory } from '../types/file.types';
import { getErrorMessage } from 'src/utils';

export const FilesUploadButton: FC<{
  id: number | string;
  dir: Directory;
  setFiles: (files: string[]) => void;
  uploadingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  loading: boolean;
}> = ({
  id,
  dir,
  setFiles,
  loading,
  uploadingState: [uploading, setUploading],
}) => {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // Check if number of files exceeds 10
      if (e.target.files.length > 10) {
        toast.error('Number of files exceeds 10!');
        return;
      }

      // Check if any file's size exceeds 10MB
      for (let file of Array.from(e.target.files)) {
        if (file.size > 10 * 1024 * 1024) {
          toast.error(`File: "${file.name}" exceeds 10MB!`);
          return;
        }
      }

      // Upload files
      setUploading(true);
      uploadFiles(e.target.files, dir, id)
        .then((res) => {
          setFiles(res);
          toast.success('Files uploaded successfully!');
        })
        .catch((err) => toast.error(getErrorMessage(err)))
        .finally(() => {
          e.target.value = '';
          setUploading(false);
        });
    }
  };

  return (
    <LoadingButton
      loading={uploading}
      loadingPosition="end"
      endIcon={<UploadIcon />}
      component="label"
      disabled={loading}
    >
      Upload
      <input type="file" multiple onChange={handleFileChange} hidden />
    </LoadingButton>
  );
};
