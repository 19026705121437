import { axiosInstance } from 'src/config/axios.config';
import { Directory } from '../types/file.types';

const endpoint = '/api/files';

export const uploadFiles = async (
  files: FileList,
  dir: Directory,
  id: number | string
): Promise<string[]> => {
  // Generate formdata object
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) formData.append('files', files[i]);
  formData.append('dir', dir);
  formData.append('id', id.toString());

  // Send request
  return (
    await axiosInstance.post(endpoint, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  ).data;
};

export const openFileForPreview = async (
  dir: Directory,
  id: number | string,
  name: string
) => {
  await axiosInstance
    .get(`${endpoint}/${dir}/${id}/${name}`, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);

      const previewWindow = window.open(url, '_blank');
      if (previewWindow) {
        previewWindow.focus();
      }

      window.URL.revokeObjectURL(url);
      return response;
    });
};

export const downloadFile = async (
  dir: Directory,
  id: number | string,
  name: string
) =>
  await axiosInstance
    .get(`${endpoint}/${dir}/${id}/${name}`, { responseType: 'blob' })
    .then((response) => {
      // create url for file
      const url = window.URL.createObjectURL(response.data);

      // download file
      const link = document.createElement('a');
      link.download = name;
      link.href = url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // clean up url after use
      window.URL.revokeObjectURL(url);

      return response;
    });

export const deleteFiles = async (
  files: string[],
  dir: Directory,
  id: number | string
): Promise<string[]> =>
  (await axiosInstance.delete(endpoint, { data: { files, dir, id } })).data;
