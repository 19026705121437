import axios from 'axios';
import { auth } from 'src/features/auth';
import { API_URL } from './app.config';

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Get token from local storage
    const token = auth.getToken();

    // Set token in request header if it exists
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      auth.logout();
    }

    return Promise.reject(error);
  }
);
