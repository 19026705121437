import { FC } from 'react';
import { GenericCitySelectorProps } from '../types/city.type';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';

export const GenericCitySelector: FC<GenericCitySelectorProps> = ({
  cityId,
  onChange,
  disabled,
  label,
  allowEmpty,
  margin,
  width = 180,
  cities,
}) => (
  <FormControl
    disabled={disabled || cities == null}
    sx={{ width }}
    margin={margin}
  >
    {cities == null ? (
      <Skeleton width={width} />
    ) : (
      <>
        <InputLabel shrink>{label}</InputLabel>
        <Select
          label={label}
          value={cityId || ''}
          onChange={(e) => onChange?.(e.target.value as number)}
          notched
        >
          {allowEmpty && <MenuItem value={''}>All</MenuItem>}

          {cities?.map((city) => (
            <MenuItem key={city.id} value={city.id}>
              {city.name}
            </MenuItem>
          ))}
        </Select>
      </>
    )}
  </FormControl>
);
