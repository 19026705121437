import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CustomTable, Loading } from 'src/components';
import { Box, Button, Card, Typography } from '@mui/material';
import { routes } from 'src/features/routes';
import { UserListItem } from '../types/user.type';
import { useUsers } from '../hooks/useUsers.hook';

const ch = createColumnHelper<UserListItem>();

const columns = [
  ch.accessor('name', {
    header: 'Name',
    cell: (info) => (
      <Link to={routes.user(info.row.original.id)}>{info.getValue()}</Link>
    ),
  }),
  ch.accessor('email', { header: 'Email' }),
  ch.accessor('accessGroupName', {
    header: 'Access Group',
    cell: (info) => (
      <Link to={routes.accessGroup(info.row.original.accessGroupId)}>
        {info.getValue()}
      </Link>
    ),
  }),
];

export const UserList: FC<{}> = () => {
  const { data } = useUsers();

  return (
    <Box>
      <Card
        sx={{
          p: 1,
          mb: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box width={64} />

        <Typography variant="h6">Users</Typography>

        <Button component={Link} to={routes.createUser}>
          Add
        </Button>
      </Card>

      {!data ? <Loading /> : <CustomTable columns={columns} data={data} />}
    </Box>
  );
};
