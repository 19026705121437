export type UserListItem = {
  id: number;
  name: string;
  email: string;
  accessGroupId: number;
  accessGroupName: string;
};

export type User = {
  id: number;
  name: string;
  email: string;
  password: string;
  accessGroupId: number | null;
};

export const initUser = (): User => ({
  id: 0,
  name: '',
  email: '',
  password: '',
  accessGroupId: null,
});
