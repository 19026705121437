import useSWR from 'swr';
import { getCruiseDirections } from '../api/cruises.api';
import { useLocalStorage } from '@uidotdev/usehooks';

export const useCruiseDirections = () => {
  const { data: directions, isLoading: directionsLoading } = useSWR(
    `/cruises/directions`,
    getCruiseDirections
  );
  const [selectedDirectionId, setSelectedDirectionId] = useLocalStorage(
    'directions',
    ''
  );
  const selectedDirection = directions?.find(
    (dir) => dir.id === selectedDirectionId
  );

  return {
    directions,
    directionsLoading,
    selectedDirectionId,
    setSelectedDirectionId,
    selectedDirection,
  };
};
