import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Loading } from 'src/components';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';
import { CreateUpdateCruiseDto } from '../../types/cruise.type';
import { getCruiseShort, updateCruise } from '../../api/cruises.api';
import { CruiseForm } from './CruiseForm';

export const EditCruiseDialogContent: FC<{
  refresh: () => void;
  cruiseId: number;
  closeDialog: () => void;
}> = ({ refresh, cruiseId, closeDialog }) => {
  const [cruise, setCruise] = useState<CreateUpdateCruiseDto>();
  const [saving, setSaving] = useState(false);

  const handleSave = (cruise: CreateUpdateCruiseDto) => {
    setSaving(true);
    updateCruise(cruise)
      .then(() => {
        refresh();
        toast.success('Cruise Updated!');
        closeDialog();
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    getCruiseShort(cruiseId).then(setCruise);
  }, [cruiseId]);

  if (!cruise)
    return (
      <Box width={200}>
        <Loading />
      </Box>
    );

  return (
    <CruiseForm
      initialValue={cruise}
      operation="Edit"
      onSave={handleSave}
      saving={saving}
      closeDialog={closeDialog}
    />
  );
};
