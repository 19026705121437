import { FC } from 'react';
import { Box } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NavBar } from './NavBar';
import { routes } from 'src/features/routes';
import { EditOrderPage, CreateOrderPage } from 'src/features/orders';
import {
  CruiseListPage,
  CruisePage,
  ListOfWaitingPage,
} from 'src/features/cruises';
import { LoginPage, useAuth } from 'src/features/auth';
import { ContactsListPage } from 'src/features/contacts';
import {
  EditAccessGroupPage,
  AccessPage,
  CreateAccessGroupPage,
  CreateUserPage,
  EditUserPage,
} from 'src/features/access';
import { SearchPage } from 'src/features/search';
import {
  CreateTariffPage,
  EditTariffPage,
  TariffListPage,
} from 'src/features/tariffs';
import {
  CreateChartererPage,
  EditChartererPage,
} from 'src/features/charterers';
import { FinancesPage } from 'src/features/finances';
import { CruiseParkingsPage } from 'src/features/parkings';
import { RatesPage } from 'src/features/finances/components/RatesPage';

export const AppRouter: FC<{}> = () => {
  const authenticated = useAuth();

  if (!authenticated)
    return (
      <Routes>
        <Route path={routes.login} element={<LoginPage />} />
        <Route path="*" element={<Navigate to={routes.login} replace />} />
      </Routes>
    );

  return (
    <Box>
      <NavBar />

      <Box p={1.5}>
        <Routes>
          {/* Orders */}
          <Route path={routes.createOrder} element={<CreateOrderPage />} />
          <Route path={routes.editOrder(':id')} element={<EditOrderPage />} />

          {/* Cruises */}
          <Route
            path={routes.cruises}
            element={<CruiseListPage mode="Payloads" />}
          />
          <Route path={routes.cruise(':id')} element={<CruisePage />} />
          <Route
            path={routes.listOfWaiting(':depPortId', ':desPortId')}
            element={<ListOfWaitingPage />}
          />

          {/* Search */}
          <Route path={routes.search} element={<SearchPage />} />

          {/* Tariffs */}
          <Route path={routes.tariffs} element={<TariffListPage />} />
          <Route path={routes.createTariff} element={<CreateTariffPage />} />
          <Route path={routes.editTariff(':id')} element={<EditTariffPage />} />

          {/* Contacts */}
          <Route path={routes.contacts} element={<ContactsListPage />} />
          <Route
            path={routes.createCharterer}
            element={<CreateChartererPage />}
          />
          <Route
            path={routes.editCharterer(':id')}
            element={<EditChartererPage />}
          />

          {/* Finances */}
          <Route path={routes.finances} element={<FinancesPage />} />

          {/* Parkings */}
          <Route
            path={routes.parkings}
            element={<CruiseListPage mode="Parkings" />}
          />
          <Route
            path={routes.cruiseParkings(':cruiseId')}
            element={<CruiseParkingsPage />}
          />

          {/* Access */}
          <Route path={routes.access} element={<AccessPage />} />
          <Route
            path={routes.createAccessGroup}
            element={<CreateAccessGroupPage />}
          />
          <Route
            path={routes.accessGroup(':id')}
            element={<EditAccessGroupPage />}
          />
          <Route path={routes.createUser} element={<CreateUserPage />} />
          <Route path={routes.user(':id')} element={<EditUserPage />} />

          {/* Default Route */}
          <Route path="*" element={<Navigate to={routes.cruises} replace />} />
        
          {/* Rates */}
          <Route path={routes.rates} element={<RatesPage />} />
        </Routes>
      </Box>
    </Box>
  );
};
