import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useSelectedPayloads } from '../../hooks/useSelectedPayloads.hook';
import { useListOfWaiting } from '../../hooks/useListOfWaiting.hook';
import { ListOfWaitingCard } from './ListOfWaitingCard';
import { MoveSelectedButton } from '../MoveSelectedButton';

export const ListOfWaitingPage: FC<{}> = () => {
  const depPortId = +(useParams().depPortId || -1);
  const desPortId = +(useParams().desPortId || -1);

  const { listOfWaiting, refetch } = useListOfWaiting(depPortId, desPortId);

  const { checks, setChecked, clearChecks } = useSelectedPayloads(
    listOfWaiting?.payloads
  );

  const moveSelectedButton = (
    <MoveSelectedButton
      checks={checks}
      clearChecks={clearChecks}
      refetch={refetch}
      depPortId={depPortId}
      desPortId={desPortId}
      hideListOfWaitingToggle
    />
  );

  return (
    <ListOfWaitingCard
      listOfWaiting={listOfWaiting}
      checks={checks}
      setChecked={setChecked}
      hideButtons={false}
      moveSelectedButton={moveSelectedButton}
    />
  );
};
