import { axiosInstance } from 'src/config/axios.config';
import { Country } from '../types/country.type';

export const getCountries = async (): Promise<Country[]> => {
  const data = await axiosInstance
    .get('api/countries')
    .then((response) => response.data);

  return data;
};
