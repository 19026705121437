import { TextField } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

export const UpperTextFieldRHF: FC<{
  label?: string;
  name: string;
  width?: number;
  required?: boolean;
}> = ({ label, name, width, required }) => (
  <Controller
    name={name}
    render={({ field: { value, onChange } }) => (
      <TextField
        label={label}
        value={value}
        sx={{ width }}
        onChange={(e) => {
          e.target.value = e.target.value.toUpperCase();
          onChange(e);
        }}
        error={required && !value}
      />
    )}
  />
);
