import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

export const ScrollableTableWrapper: FC<{
  children: ReactNode;
  minusVH?: number;
}> = ({ children, minusVH = 230 }) => (
  <Box sx={{ maxHeight: `calc(100vh - ${minusVH}px)`, overflow: 'auto' }}>
    {children}
  </Box>
);
