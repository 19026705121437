import { axiosInstance } from 'src/config/axios.config';
import { Rate } from '../types/rates.types';

export const getRate = async (date: string | null | undefined): Promise<Rate> =>
  axiosInstance
    .get<Rate>(`/api/rates/${date}`)
    .then((response) => response.data);

export const getRates = async (): Promise<Rate[]> =>
  axiosInstance.get<Rate[]>(`/api/rates/`).then((response) => response.data);
