const throwExpression = (errorMessage: string): never => {
  throw new Error(errorMessage);
};

export const API_URL: string =
  process.env.REACT_APP_API_URL ||
  throwExpression('API_URL config was not provided!');

export type TenantType = 'E60' | 'BSF';
export const TENANT: TenantType =
  (process.env.REACT_APP_TENANT as TenantType) ||
  throwExpression('TENANT config was not provided!');
