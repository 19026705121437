import {
  Box,
  Button,
  Dialog,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import CloseIcon from '@mui/icons-material/Close';
import { Loading } from 'src/components';
import { movePayloads } from '../api/cruises.api';
import { LoadingButton } from '@mui/lab';
import { useCruiseList } from '../hooks/useCruiseList.hook';
import { CruiseSelector } from './cruise/CruiseSelector';

export const MoveSelectedButton: FC<{
  currentCruiseId?: number;
  depPortId: number;
  desPortId: number;
  checks: string[];
  clearChecks: () => void;
  refetch: () => void;
  hideListOfWaitingToggle?: boolean;
}> = ({
  currentCruiseId,
  depPortId,
  desPortId,
  checks,
  clearChecks,
  refetch,
  hideListOfWaitingToggle,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={!checks.length}
        endIcon={<MoveUpIcon />}
      >
        Move Selected
      </Button>

      <Dialog open={open}>
        <MoveSelectedDialogContent
          currentCruiseId={currentCruiseId}
          depPortId={depPortId}
          desPortId={desPortId}
          checks={checks}
          clearChecks={clearChecks}
          refetch={refetch}
          closeDialog={() => setOpen(false)}
          hideListOfWaitingToggle={hideListOfWaitingToggle}
        />
      </Dialog>
    </>
  );
};

export const MoveSelectedDialogContent: FC<{
  currentCruiseId?: number;
  depPortId: number;
  desPortId: number;
  checks: string[];
  clearChecks: () => void;
  refetch: () => void;
  closeDialog: () => void;
  hideListOfWaitingToggle?: boolean;
}> = ({
  currentCruiseId,
  depPortId,
  desPortId,
  checks,
  clearChecks,
  refetch,
  closeDialog,
  hideListOfWaitingToggle,
}) => {
  const [to, setTo] = useState<'lof' | 'cruise'>(
    hideListOfWaitingToggle ? 'cruise' : 'lof'
  );
  const [cruiseId, setCruiseId] = useState<number | null>(null);
  const [moving, setMoving] = useState(false);

  const cruises = useCruiseList(
    depPortId,
    desPortId,
    undefined,
    0,
    50
  )?.data?.cruises?.filter((c) => c.id !== currentCruiseId);

  const handleMoveSelected = async () => {
    setMoving(true);
    await movePayloads({ cruiseId, payloadIds: checks });
    clearChecks();
    refetch();
    closeDialog();
  };

  return (
    <Box minWidth={330}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        py={1}
        borderBottom={1}
        borderColor="divider"
      >
        <Typography variant="h6">Move Selected Items To</Typography>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box minHeight={110} mt={2}>
        <Box display="flex" justifyContent="center" px={1.5}>
          <ToggleButtonGroup
            size="small"
            value={to}
            color="primary"
            onChange={(_e, v) => {
              if (v == null) return;

              setTo(v);
              if (v === 'lof') setCruiseId(null);
            }}
            exclusive
            fullWidth
          >
            {!hideListOfWaitingToggle && (
              <ToggleButton value={'lof'}>
                <Typography fontWeight={to === 'lof' ? 'bold' : 'normal'}>
                  List of Waiting
                </Typography>
              </ToggleButton>
            )}
            <ToggleButton value={'cruise'}>
              <Typography fontWeight={to === 'cruise' ? 'bold' : 'normal'}>
                Cruise
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {to === 'cruise' && (
          <Box display="flex" justifyContent="center" px={1.5}>
            {cruises ? (
              <CruiseSelector
                cruises={cruises}
                cruiseId={cruiseId}
                setCruiseId={setCruiseId}
                error={to === 'cruise' && !cruiseId}
              />
            ) : (
              <Loading />
            )}
          </Box>
        )}
      </Box>

      <Box mb={2} display="flex" justifyContent="center">
        <LoadingButton
          loading={moving}
          loadingPosition="end"
          onClick={handleMoveSelected}
          endIcon={<MoveUpIcon />}
          disabled={!checks.length || (to === 'cruise' && !cruiseId)}
        >
          Move Selected
        </LoadingButton>
      </Box>
    </Box>
  );
};
