import { useEffect, useState } from 'react';
import { Order } from '../types/order.type';
import { UseFormReturn } from 'react-hook-form';

export const usePayloadsCount = (
  form: UseFormReturn<Order, any, undefined>
) => {
  const { watch, getValues } = form;

  const [payloadsCount, setPayloadsCount] = useState(
    getValues('payloads').filter((p) => p.type !== 'Passenger').length
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (
        name === 'payloads' &&
        value.payloads?.filter((p) => p?.type !== 'Passenger').length !==
          payloadsCount
      ) {
        setPayloadsCount(
          value.payloads?.filter((p) => p?.type !== 'Passenger').length || -2
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [payloadsCount, watch]);

  return payloadsCount;
};
