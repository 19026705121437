import useSWR from 'swr';
import { getCruiseParkings } from '../api/parkings.api';

export const useCruiseParkings = (cruiseId: number) => {
  const {
    data: cruiseParkings,
    mutate: refetchCruiseParkings,
    error,
  } = useSWR(`parkings/cruise/${cruiseId}`, () => getCruiseParkings(cruiseId));

  return {
    cruiseParkings,
    refetchCruiseParkings,
    error,
  };
};
