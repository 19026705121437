import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { capitalize } from '../../utils/cruise.utils';
import { ListOfWaiting } from '../../types/cruise.type';

export const ListOfWaitingHeader: FC<{
  listOfWaiting: ListOfWaiting;
  moveSelectedButton?: ReactNode;
}> = ({ listOfWaiting, moveSelectedButton }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      px={1}
      py={1}
      color="black"
      gap={1}
    >
      <Typography width={160}>List Of Waiting</Typography>

      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        mr={1.5}
        minHeight={30.75}
      >
        <Typography>{capitalize(listOfWaiting.departurePort)}</Typography>
        <ArrowForwardIcon fontSize="small" sx={{ mx: 0.25 }} />
        <Typography>{capitalize(listOfWaiting.destinationPort)}</Typography>
      </Box>

      <Box width={160}>{moveSelectedButton}</Box>
    </Box>
  );
};
