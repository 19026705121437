import { FC, useState } from 'react';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';
import { CruiseSelector, useCruiseList } from 'src/features/cruises';
import { Loading } from 'src/components';
import { useFormContext } from 'react-hook-form';
import { Order, Payload } from '../..';
import { startCase } from 'lodash';
import { OpenReferenceButton } from './OpenReferenceButton';
import { addReturnOrder } from '../../api/orders.api';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/features/routes';

export const AddReturnOrderButton: FC<{}> = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        endIcon={<AddLinkIcon />}
        color="primary"
        onClick={() => setDialogOpen(true)}
      >
        Add Return Order
      </Button>

      <Dialog maxWidth="lg" open={dialogOpen}>
        <AddReturnOrderDialog closeDialog={() => setDialogOpen(false)} />
      </Dialog>
    </>
  );
};

const AddReturnOrderDialog: FC<{ closeDialog: () => void }> = ({
  closeDialog,
}) => {
  const navigate = useNavigate();
  const { watch } = useFormContext<Order>();
  const [adding, setAdding] = useState(false);
  const [cruiseId, setCruiseId] = useState<number | null>(null);
  const [selectedPayloads, setSelectedPayloads] = useState<string[]>([]);

  const [orderId, depPortId, desPortId, payloads] = watch([
    'id',
    'departurePortId',
    'destinationPortId',
    'payloads',
  ]);

  const cruises = useCruiseList(desPortId, depPortId, undefined, 0, 50)?.data
    ?.cruises;

  const returnCanBeAdded = payloads.every((p) => p.ticketNo);

  const onAddReturnOrder = () => {
    if (!orderId || !cruiseId || selectedPayloads.length === 0) return;

    setAdding(true);
    addReturnOrder(orderId, cruiseId, selectedPayloads)
      .then((newOrderId) => {
        navigate(routes.editOrder(newOrderId));
        toast.success('Return order added successfully');
      })
      .catch((e) => {
        return toast.error(getErrorMessage(e));
      })
      .finally(() => setAdding(false));
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
        borderBottom={1}
        borderColor="divider"
      >
        <Box width={34} />

        <Typography variant="h6">Add Return Order</Typography>

        <IconButton disabled={adding} onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box gap={2} p={2}>
        {!returnCanBeAdded && (
          <Alert severity="error" sx={{ mb: 2 }}>
            All payloads must be saved before adding a return order.
          </Alert>
        )}
        {cruises ? (
          <>
            <Box display="flex" gap={4} justifyContent="space-between">
              <PayloadsSelector
                payloads={payloads}
                selected={selectedPayloads}
                setSelected={(id, checked) => {
                  setSelectedPayloads(
                    checked
                      ? [...selectedPayloads, id]
                      : selectedPayloads.filter((i) => i !== id)
                  );
                }}
              />

              <Box
                minWidth={300}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="end"
                gap={2}
              >
                <CruiseSelector
                  cruises={cruises}
                  cruiseId={cruiseId}
                  setCruiseId={setCruiseId}
                  error={!cruiseId}
                  margin="none"
                  disabled={!returnCanBeAdded}
                />

                <LoadingButton
                  sx={{ width: 180 }}
                  loading={adding}
                  loadingPosition="end"
                  endIcon={<AddLinkIcon />}
                  disabled={
                    !returnCanBeAdded ||
                    selectedPayloads.length === 0 ||
                    cruiseId == null
                  }
                  onClick={onAddReturnOrder}
                >
                  Add
                </LoadingButton>
              </Box>
            </Box>
          </>
        ) : (
          <Loading />
        )}
      </Box>
    </>
  );
};

const PayloadsSelector: FC<{
  payloads: Payload[];
  selected: string[];
  setSelected: (id: string, checked: boolean) => void;
}> = ({ payloads, selected, setSelected }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {payloads.map((payload) => (
        <Box key={payload.id} display="flex" alignItems="center" gap={1}>
          <Checkbox
            checked={selected.includes(payload.id)}
            onChange={(e) => setSelected(payload.id, e.target.checked)}
            disabled={
              payload.refPayloadId != null || payload.ticketType !== 'TwoWay'
            }
          />
          <TextField
            label="Ticket No"
            value={payload.ticketNo}
            disabled
            margin="none"
          />
          <TextField
            label="Ticket Type"
            value={startCase(payload.ticketType)}
            disabled
            margin="none"
            sx={{ width: 100 }}
          />
          <Box mt={-1} ml={-1} mr={-0.75}>
            <OpenReferenceButton refPayloadId={payload.refPayloadId} />
          </Box>
          {payload.type === 'Passenger' && (
            <TextField
              label="Name"
              value={payload.name}
              disabled
              margin="none"
            />
          )}
          {payload.type !== 'Passenger' && (
            <TextField label="No" value={payload.no} disabled margin="none" />
          )}
        </Box>
      ))}
    </Box>
  );
};
