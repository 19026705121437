import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { AmountInput } from '../forms/AmountInput';

export const AmountInputRHF: FC<{
  name: string;
  label?: string;
  type: 'int' | 'float';
  disabled?: boolean;
  required?: boolean;
}> = ({ name, label, type, disabled, required }) => (
  <Controller
    name={name}
    render={({ field }) => (
      <AmountInput
        label={label}
        value={field.value}
        onChange={field.onChange}
        type={type}
        disabled={disabled}
        required={required}
      />
    )}
  />
);
