import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Button, Card, Divider, Stack } from '@mui/material';
import {
  AmountInputRHF,
  PhoneInputRHF,
  TextFieldRHF,
  UpperTextFieldRHF,
} from 'src/components';
import { FinancialDataForm } from './FinancialDataForm';
import { PassengerFormFields } from './PassengerFormFields';
import { PayloadBreadcrumbs } from './PayloadBreadcrumbs';
import { CompanySelector } from 'src/features/companies';
import { TariffSelectorRHF } from 'src/features/tariffs';
import { initPayload } from '../../types/payload.type';
import { TicketTypeSelector } from '../order-references/TicketTypeSelector';
import { PaymentStatusInputRHF } from '../PaymentStatusInputRHF';
import { OrderStatusSelectorRHF } from '../OrderStatusSelector';

export const CarForm: FC<{
  path: string;
  onRemove: () => void;
}> = ({ onRemove, path }) => {
  const { setValue, watch } = useFormContext();
  const [driverId, cruiseId, cruiseNo] = watch([
    `${path}.driverId`,
    `${path}.cruiseId`,
    `${path}.cruiseNo`,
  ]);

  const addDriver = () => {
    const driver = initPayload(cruiseId, cruiseNo, 'Passenger');
    setValue(`${path}.driver`, driver);
    setValue(`${path}.driverId`, driver.id);
  };

  const removeDriver = () => {
    setValue(`${path}.driver`, null);
    setValue(`${path}.driverId`, null);
  };

  return (
    <Card sx={{ pb: 1 }}>
      <Box display="flex" justifyContent="space-between" px={2} py={1}>
        <PayloadBreadcrumbs
          path={path}
          label={(driverId == null ? 'Deck ' : '') + 'Car'}
        />

        <Stack direction="row" spacing={2}>
          <Button disabled={driverId != null} onClick={addDriver}>
            Add Driver
          </Button>
          <Button
            disabled={driverId == null}
            onClick={removeDriver}
            color="error"
            tabIndex={-1}
          >
            Remove Driver
          </Button>
        </Stack>

        <Button onClick={onRemove} color="error" tabIndex={-1}>
          Remove
        </Button>
      </Box>

      <Divider />

      <Box display="flex" columnGap={1} px={2} flexWrap="wrap">
        <TextFieldRHF label="Ticket No" name={`${path}.ticketNo`} disabled />

        <TariffSelectorRHF payloadType="Car" path={path} />

        <FinancialDataForm path={`${path}.amounts`} />

        <PaymentStatusInputRHF path={path} />
        <OrderStatusSelectorRHF path={`${path}.orderStatus`} />

        <Controller
          name={`${path}.refPayloadId`}
          render={({ field: { value: refPayloadId } }) => (
            <Controller
              name={`${path}.ticketType`}
              render={({ field }) => (
                <TicketTypeSelector
                  value={field.value}
                  onChange={field.onChange}
                  refPayloadId={refPayloadId}
                />
              )}
            />
          )}
        />

        <UpperTextFieldRHF label="Model" name={`${path}.model`} />
        <UpperTextFieldRHF label="No" name={`${path}.no`} />
        <UpperTextFieldRHF label="Vin code" name={`${path}.vinCode`} />

        <AmountInputRHF
          name={`${path}.length`}
          label="Length m."
          type="float"
          required
        />
        <AmountInputRHF
          label="Width m."
          name={`${path}.width`}
          type="float"
          required
        />

        <AmountInputRHF
          name={`${path}.weight`}
          label="Weight kg."
          type="float"
        />

        <UpperTextFieldRHF label="Chassis No" name={`${path}.chassisNo`} />

        <UpperTextFieldRHF label="Trailer Nos" name={`${path}.trailerNos`} />

        <UpperTextFieldRHF
          label="Trailer Chassis Nos"
          name={`${path}.trailerChassisNos`}
        />

        <PhoneInputRHF
          phoneCountryCodePath={`${path}.phoneCountryCode`}
          phoneNoPath={`${path}.phoneNo`}
        />

        <TextFieldRHF label="Comment" name={`${path}.comment`} />

        <Controller
          name={`${path}.consignorId`}
          render={({ field: { value, onChange } }) => (
            <CompanySelector
              label="Shipper"
              companyId={value}
              onCompanyIdChange={onChange}
              hide={['Email', 'Phone']}
            />
          )}
        />

        <Controller
          name={`${path}.consigneeId`}
          render={({ field: { value, onChange } }) => (
            <CompanySelector
              label="Receiver"
              companyId={value}
              onCompanyIdChange={onChange}
              hide={['Email', 'Phone']}
            />
          )}
        />
      </Box>

      {driverId != null && (
        <>
          <Divider sx={{ mt: 2 }}>Driver</Divider>
          <PassengerFormFields path={`${path}.driver`} />
        </>
      )}
    </Card>
  );
};
