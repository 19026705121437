import { today } from 'src/utils';
import { TransferType, transferTypes } from './finances.types';

export interface Bill {
  ticketNo: string;
  agentName: string;
  payloadNumber: string;
  credit: number;
  debit: number;

  transactionPlaceId: number | null;
  transactionDate: string;
  transactionType: TransferType;
  eurAmount: number;
  usdAmount: number;
  gelAmount: number;
}

export const initBill = (): Bill => ({
  ticketNo: '',
  agentName: '',
  payloadNumber: '',
  credit: 0,
  debit: 0,
  transactionPlaceId: 1,
  transactionDate: today(),
  transactionType: transferTypes[0],
  eurAmount: 0,
  usdAmount: 0,
  gelAmount: 0,
});
