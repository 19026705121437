import { createColumnHelper } from '@tanstack/react-table';
import { FC, useMemo, useState } from 'react';
import { CruisePayload, cruiseAllCargo } from '../../types/cruise.type';
import { PayloadListProps } from '../../types/payloadListProps.type';
import { compact } from 'lodash';
import { columnDefs } from './columnDefs';
import { CustomTable } from 'src/components';
import { PayloadsToolbar } from './PayloadsToolbar';
import { NewOrderButton } from 'src/features/orders';
import { ScrollableTableWrapper } from './ScrollableTableWrapper';
import { EditableCell } from './EditableCell';
import { SortPayloadsButton } from './SortPayloadsButton';

const ch = createColumnHelper<CruisePayload>();

export const AllCargoList: FC<PayloadListProps> = ({
  cruise,
  checks,
  setChecked,
  hideButtons,
  refetchCruises,
}) => {
  const columns = useMemo(
    () =>
      compact([
        columnDefs.selected(checks, setChecked),
        hideButtons ? null : columnDefs.reports,
        columnDefs.paymentStatus,
        columnDefs.orderStatus,
        columnDefs.rowNumber,
        columnDefs.orderNo(hideButtons, cruise.no),
        ch.accessor('tariffName', { header: 'Product Type', minSize: 200 }),
        ch.accessor('length', { header: 'Length', minSize: 55 }),
        ch.accessor('totalAmount', { header: 'Tariff', minSize: 55 }),
        ch.accessor('ticketType', { header: 'Ticket Type' }),
        ch.accessor('cargoName', { header: 'Cargo Type' }),
        ch.accessor('no', { header: 'Plate Number', minSize: 180 }),
        ch.accessor('driver', {
          header: 'Driver',
          cell: (info) => (
            <div style={{ textAlign: 'left', padding: '0 5px' }}>
              {info.getValue()}
            </div>
          ),
          minSize: 200,
        }),
        ch.accessor('phoneNumber', {header: 'Phone Number'}),
        ch.accessor('shipper', { header: 'Shipper Name' }),
        ch.accessor('charterer', { header: 'Charterer', minSize: 140 }),
        ch.accessor('orderComment', {
          header: 'Payment Comment',
          minSize: 200,
          cell: (info) => (
            <EditableCell
              label="Payment Comment"
              field="OrderComment"
              payloadId={info.row.original.id}
              orderId={info.row.original.orderId}
              value={info.getValue()}
              refetchCruises={refetchCruises}
            />
          ),
        }),
        ch.accessor('payloadComment', {
          header: 'Order Comment',
          minSize: 200,
          cell: (info) => (
            <EditableCell
              label="Order Comment"
              field="PayloadComment"
              payloadId={info.row.original.id}
              orderId={info.row.original.orderId}
              value={info.getValue()}
              refetchCruises={refetchCruises}
            />
          ),
        }),
        ch.accessor('createdByName', { header: 'Created By' })
      ]),
    [checks, setChecked, hideButtons, cruise.no, refetchCruises]
  );

  const data = cruiseAllCargo(cruise).map((i) => ({
    ...i,
    no: i.no + (i.trailerNo ? ` / ${i.trailerNo}` : ''),
    driver: i.driver + (i.driver2 ? ` / ${i.driver2}` : ''),
  }));

  const [globalFilter, setGlobalFilter] = useState('');

  return (
    <>
      <PayloadsToolbar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      >
        <NewOrderButton
          depPortId={cruise.departurePortId}
          desPortId={cruise.destinationPortId}
          cruiseId={cruise.id}
          cruiseNo={cruise.no}
        />
        {cruise.id && (
          <SortPayloadsButton
            cruiseId={cruise.id}
            originalPayloads={data}
            refetchCruises={refetchCruises}
          />
        )}
      </PayloadsToolbar>

      <ScrollableTableWrapper>
        <CustomTable
          data={data}
          columns={columns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          style={{ height: 1 }} // to allow EditableCell 100% height
        />
      </ScrollableTableWrapper>
    </>
  );
};
