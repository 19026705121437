import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';

export const FilesDeleteButton: FC<{
  disabled: boolean;
  deleteSelectedFiles: () => Promise<string | void>;
  deleting: boolean;
}> = ({ disabled, deleteSelectedFiles, deleting }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        disabled={disabled}
        color="error"
        onClick={() => setOpen(true)}
      >
        <DeleteIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={(_, r) => !deleting && r === 'escapeKeyDown' && setOpen(false)}
      >
        <DialogTitle>Delete selected files?</DialogTitle>
        <DialogActions
          sx={{
            mx: 2,
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button disabled={deleting} onClick={() => setOpen(false)}>
            No
          </Button>
          <LoadingButton
            loading={deleting}
            onClick={() => deleteSelectedFiles().then(() => setOpen(false))}
            color="error"
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
