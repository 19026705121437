import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import { FC } from 'react';
import { useCharterers } from '../hooks/useCharterers.hook';

export const CharterersSelector: FC<{
  label?: string;
  disabled?: boolean;
  chartererIds: number[];
  onChange: (chartererIds: number[]) => void;
  margin?: 'none' | 'dense' | 'normal';
  width?: number | string;
}> = ({
  disabled,
  chartererIds,
  onChange,
  margin,
  width = 180,
  label = 'Charterers',
}) => {
  const { data: charterers } = useCharterers();

  return (
    <FormControl
      disabled={disabled || charterers == null}
      margin={margin}
      sx={{ width }}
    >
      {charterers == null ? (
        <Skeleton />
      ) : (
        <>
          <InputLabel shrink>{label}</InputLabel>
          <Select
            multiple
            label={label}
            value={chartererIds}
            onChange={(e) => onChange(e.target.value as number[])}
            notched
            renderValue={(selected) =>
              charterers
                .filter((c) => selected.includes(c.id))
                .map((c) => c.name)
                .join(', ')
            }
          >
            {charterers?.map((charterer) => (
              <MenuItem key={charterer.id} value={charterer.id}>
                <Checkbox
                  sx={{ width: 24, height: 24 }}
                  size="small"
                  checked={chartererIds.includes(charterer.id)}
                />
                <ListItemText primary={charterer.name} />
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </FormControl>
  );
};
