import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FC } from 'react';
import { Loading } from 'src/components';
import { Ship } from '../../types/ship.type';

export const ShipsToggle: FC<{
  ships: Ship[] | undefined;
  shipId: number;
  setShipId: (shipId: number) => void;
}> = ({ ships, shipId, setShipId }) => {
  return (
    <ToggleButtonGroup
      sx={{ bgcolor: 'white', minWidth: 250 }}
      orientation="vertical"
      color="primary"
      value={shipId}
      exclusive
      onChange={(_e, v) => v != null && setShipId(v)}
    >
      {ships && (
        <ToggleButton value={0} sx={{ color: 'black', textTransform: 'none' }}>
          <Typography fontWeight={0 === shipId ? 'bold' : 'normal'}>
            All
          </Typography>
        </ToggleButton>
      )}

      {ships ? (
        ships.map((ship) => (
          <ToggleButton
            key={ship.id}
            value={ship.id || 0}
            sx={{ color: 'black', textTransform: 'none' }}
          >
            <Typography fontWeight={ship.id === shipId ? 'bold' : 'normal'}>
              {ship.name}
            </Typography>
          </ToggleButton>
        ))
      ) : (
        <Loading />
      )}
    </ToggleButtonGroup>
  );
};
