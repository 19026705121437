import { FC } from 'react';
import { useGetFinancialRecords } from '../hooks/useGetFinancialRecords.hook';
import {
  Box,
  Card,
  IconButton,
  Stack,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { FinancesTable } from './FinancesTable';
import { debounce } from 'lodash';
import { GetFinancialRecordsRequest } from '../types/finances.types';
import { Link } from 'react-router-dom';
import { routes } from 'src/features/routes';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { MoneyChip } from './MoneyChip';
import { FinancesExcelButton } from './FinancesExcelButton';

export const FinancesPage: FC<{}> = () => {
  const {
    params,
    setParams,
    financialRecords,
    isLoading,
    totalCount,
    stats,
    handleChangePage,
    handleChangeRowsPerPage,
    refresh,
  } = useGetFinancialRecords();

  const setFilters = (field: keyof GetFinancialRecordsRequest, value: any) => {
    setParams((p) => ({ ...p, [field]: value, pageNumber: 0 }));
  };
  const setFiltersDebounced = debounce(setFilters, 1000);

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          px: 1,
          py: 0.5,
          mb: 1.5,
        }}
      >
        <Stack direction="row" gap={2} flexWrap="wrap">
          {stats && (
            <Box display="flex" alignItems="center" gap={2}>
              <MoneyChip label="Credit" amount={stats.totalCredit} noColor />
              <MoneyChip label="Debit" amount={stats.totalDebit} noColor />
              <MoneyChip label="Balance" amount={stats.totalBalance} />
            </Box>
          )}
        </Stack>
        <FinancesExcelButton params={params} />
        <Stack direction="row" gap={2}>
          <TablePagination
            component="div"
            count={totalCount || 100}
            page={params.pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={params.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows:"
            rowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            showFirstButton
            showLastButton
          />
          <Tooltip title="Rates">
            <IconButton
              component={Link}
              to={routes.rates}
              color="primary"
              sx={{ alignSelf: 'center' }}
            >
              <AttachMoneyIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Card>

      <FinancesTable
        isLoading={isLoading}
        financialRecords={financialRecords}
        refresh={refresh}
        filters={params}
        setFilters={setFilters}
        setFiltersDebounced={setFiltersDebounced}
      />
    </>
  );
};
