import { IconButton, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { getReferencedOrderId } from '../../api/orders.api';
import { routes } from 'src/features/routes';

export const OpenReferenceButton: FC<{
  refPayloadId: string | null;
}> = ({ refPayloadId }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (refPayloadId == null) return;

    setLoading(true);

    getReferencedOrderId(refPayloadId)
      .then((orderId) => {
        window.open(routes.editOrder(orderId), '_blank');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip title="Open Referenced Order">
      <IconButton
        sx={{ mt: 1, ml: 0.25 }}
        color="primary"
        onClick={handleClick}
        disabled={refPayloadId == null || loading}
      >
        <LinkIcon />
      </IconButton>
    </Tooltip>
  );
};
