import { FC } from 'react';
import { LinearProgress, Box, Alert } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { Rate } from '../types/rates.types';
import { CustomTable } from 'src/components';
import useSWR from 'swr';
import { getRates } from '../api/rates.api';

const ch = createColumnHelper<Rate>();

const columns = [
  ch.accessor('date', { header: 'Date' }),
  ch.accessor('eur_to_gel', {
    header: 'EUR → GEL',
    cell: (info) => info.getValue().toFixed(4),
  }),
  ch.accessor('usd_to_gel', {
    header: 'USD → GEL',
    cell: (info) => info.getValue().toFixed(4),
  }),
  ch.accessor('usd_to_eur', {
    header: 'USD → EUR',
    cell: (info) => info.getValue().toFixed(10),
  }),
  ch.accessor('gel_to_eur', {
    header: 'GEL → EUR',
    cell: (info) => info.getValue().toFixed(10),
  }),
  ch.accessor('eur_to_usd', {
    header: 'EUR → USD',
    cell: (info) => info.getValue().toFixed(10),
  }),
  ch.accessor('gel_to_usd', {
    header: 'GEL → USD',
    cell: (info) => info.getValue().toFixed(10),
  }),
];

export const RatesPage: FC = () => {
  const { data: rates, isLoading, error } = useSWR('/rates', getRates);

  if (isLoading) return <LinearProgress />;

  if (error || rates === undefined)
    return (
      <Alert variant="filled" severity="error">
        Order was not found! It may have been deleted.
      </Alert>
    );

  return (
    <Box mx="auto" p={3}>
      <CustomTable columns={columns} data={rates} />
    </Box>
  );
};
