import { Box, Dialog, IconButton, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useEffect, useState } from 'react';
import { getPayloadParking, updatePayloadParking } from '../api/parkings.api';
import { EditPayloadParking } from '../types/parkings.types';
import {
  AmountInput,
  CustomCheckbox,
  CustomDatePicker,
  GenericSelector,
  Loading,
} from 'src/components';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { getErrorMessage } from 'src/utils';
import { Terminal, terminals } from 'src/features/cruises';
import { CompanySelector } from 'src/features/companies';
import { FilesButton } from 'src/features/files';
import { PortSelector } from 'src/features/cities';

export const ParkingEditDialog: FC<{
  onClose: () => void;
  payloadId: string | null;
  refreshCruiseParkings: () => void;
}> = ({ onClose, payloadId, refreshCruiseParkings }) => {
  const [payloadParking, setPayloadParking] = useState<EditPayloadParking>();
  const [saving, setSaving] = useState(false);

  const closeDialog = () => {
    onClose();
    setPayloadParking(undefined); // Reset the parking object on dialog close so that there is no flash on dialog open
  };

  const onSave = (parking: EditPayloadParking) => {
    setSaving(true);
    updatePayloadParking(parking)
      .then(() => {
        refreshCruiseParkings();
        toast.success('Payload Parking Updated!');
        closeDialog();
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (payloadId) {
      setPayloadParking(undefined);
      getPayloadParking(payloadId).then(setPayloadParking);
    }
  }, [payloadId]);

  return (
    <Dialog open={payloadId !== null} onClose={closeDialog} maxWidth="md">
      <Box width={860} height={510}>
        {payloadParking ? (
          <PayloadParkingForm
            payloadParking={payloadParking}
            setPayloadParking={setPayloadParking}
            onClose={closeDialog}
            onSave={onSave}
            saving={saving}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            height={320}
            alignItems="center"
          >
            <Loading />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export const PayloadParkingForm: FC<{
  payloadParking: EditPayloadParking;
  setPayloadParking: React.Dispatch<
    React.SetStateAction<EditPayloadParking | undefined>
  >;
  onClose: () => void;
  onSave: (payloadParking: EditPayloadParking) => void;
  saving: boolean;
}> = ({ payloadParking, setPayloadParking, onClose, onSave, saving }) => (
  <>
    <Box
      px={1.5}
      py={1}
      display="flex"
      justifyContent="space-between"
      gap={3}
      borderBottom={1}
      borderColor="divider"
    >
      <Typography variant="h6">Edit Parking</Typography>

      <IconButton onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>

    <Box display="flex" justifyContent="space-evenly" py={1}>
      <Box display="flex" flexDirection="column">
        <CustomDatePicker
          label="Start Date"
          initialValue={payloadParking.startDate}
          onChange={(startDate) =>
            setPayloadParking({ ...payloadParking, startDate })
          }
        />
        <CustomDatePicker
          label="End Date"
          initialValue={payloadParking.endDate}
          onChange={(endDate) =>
            setPayloadParking({ ...payloadParking, endDate })
          }
        />
        <CustomDatePicker
          label="Real End Date"
          initialValue={payloadParking.realEndDate}
          onChange={(realEndDate) =>
            setPayloadParking({ ...payloadParking, realEndDate })
          }
        />
        <AmountInput
          label="Price"
          value={payloadParking.price || 0}
          onChange={(price) => setPayloadParking({ ...payloadParking, price })}
          type="float"
        />
        <AmountInput
          label="Extra Price"
          value={payloadParking.extraPrice || 0}
          onChange={(extraPrice) =>
            setPayloadParking({ ...payloadParking, extraPrice })
          }
          type="float"
        />
        <AmountInput
          label="Port Cost"
          value={payloadParking.portCost || 0}
          onChange={(portCost) =>
            setPayloadParking({ ...payloadParking, portCost })
          }
          type="float"
        />
      </Box>

      <Box display="flex" gap={1.5} pt={1} flexDirection="column">
        <CustomCheckbox
          label="Paid Price"
          checked={payloadParking.paidPrice === true}
          onChange={(paidPrice) =>
            setPayloadParking({ ...payloadParking, paidPrice })
          }
        />
        <CustomCheckbox
          label="Paid Extra Price"
          checked={payloadParking.paidExtraPrice === true}
          onChange={(paidExtraPrice) =>
            setPayloadParking({ ...payloadParking, paidExtraPrice })
          }
        />
        <CustomCheckbox
          label="Manual Price"
          checked={payloadParking.manualPrice === true}
          onChange={(manualPrice) =>
            setPayloadParking({ ...payloadParking, manualPrice })
          }
        />
        <CustomCheckbox
          label="Empty"
          checked={payloadParking.empty === true}
          onChange={(empty) => setPayloadParking({ ...payloadParking, empty })}
        />
        <GenericSelector
          label="Terminal Type"
          value={payloadParking.terminal}
          onChange={(value) =>
            setPayloadParking({
              ...payloadParking,
              terminal: value as Terminal | null,
            })
          }
          options={terminals}
          margin="dense"
        />
        <PortSelector
          label="Port"
          cityId={payloadParking.portId}
          onChange={(portId) =>
            setPayloadParking({ ...payloadParking, portId: portId })
          }
        />
      </Box>

      <Box display="flex" flexDirection="column">
        <CompanySelector
          label="Payer"
          companyId={payloadParking.payerId}
          onCompanyIdChange={(payerId) =>
            setPayloadParking({ ...payloadParking, payerId })
          }
        />
        <TextField
          label="Comment"
          value={payloadParking.comment || ''}
          onChange={(event) =>
            setPayloadParking({
              ...payloadParking,
              comment: event.target.value,
            })
          }
          multiline
          rows={4}
          variant="outlined"
          fullWidth 
          sx={{ width: 300 }}
        />
      </Box>
    </Box>

    <Box
      px={1.5}
      py={1}
      display="flex"
      justifyContent="space-between"
      gap={1.5}
      borderTop={1}
      borderColor="divider"
    >
      <FilesButton
        dir="payloads"
        files={payloadParking.files}
        id={payloadParking.id}
        setFiles={(files) => setPayloadParking({ ...payloadParking, files })}
      />
      <LoadingButton
        onClick={() => onSave(payloadParking)}
        endIcon={<SaveIcon />}
        loadingPosition="end"
        loading={saving}
      >
        Save
      </LoadingButton>
    </Box>
  </>
);
