export interface CompanyType {
  id: number;
  name: string;
  companyId: string;
  email: string;
  phone: string;
  address: string;
  agreementNo: string;

  countryCode: string | null;
  countryName: string | null;

  isPayer: boolean;
  isConsignor: boolean;
  isConsignee: boolean;
}

export interface CompanyListType {
  data: CompanyType[];
  totalCount: number;
}

export const initCompany = (): CompanyType => ({
  id: 0,
  name: '',
  companyId: '',
  email: '',
  phone: '',
  address: '',
  agreementNo: '',

  countryCode: null,
  countryName: null,

  isPayer: false,
  isConsignor: false,
  isConsignee: false,
});
