export type AccessGroupListItem = {
  id: number;
  name: string;
};

export interface AccessGroup {
  id: number;
  name: string;
  cruises: AccessRights;
  dictionaries: AccessRights;
  access: AccessRights;
  tariffs: AccessRights;
  charterers: AccessRights;
  users: { id: number }[];
}

export interface AccessRights {
  read: boolean;
  write: boolean;
  delete: boolean;
}

export const initAccessGroup = (): AccessGroup => ({
  id: 0,
  name: '',
  cruises: initAccessRights(),
  dictionaries: initAccessRights(),
  access: initAccessRights(),
  tariffs: initAccessRights(),
  charterers: initAccessRights(),
  users: [],
});

const initAccessRights = (): AccessRights => ({
  read: false,
  write: false,
  delete: false,
});
