import { Box, CircularProgress } from '@mui/material';
import { FunctionComponent } from 'react';

export const Loading: FunctionComponent<{}> = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
      <CircularProgress size={50} />
    </Box>
  );
};
