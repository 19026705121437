import { axiosInstance } from 'src/config/axios.config';
import { ReportType, getFileExtension, getFormat } from '../types/report.type';

export const getReport = async (
  name: string,
  params: any,
  type: ReportType
) => {
  return await axiosInstance
    .get(`/api/Reports/${name}`, {
      params: { ...params, format: getFormat(type) },
      responseType: 'blob',
    })
    .then((response) => {
      // create url for file
      const url = window.URL.createObjectURL(response.data);

      if (type === 'Preview') {
        // open pdf file on new tab
        window.open(url);
      } else {
        // download file
        const link = document.createElement('a');

        const extension = getFileExtension(response.headers['content-type']);
        const fileName = response.headers['file-name'] || 'report';
        link.download = `${fileName}.${extension}`;

        link.href = url;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      // clean up url after use
      window.URL.revokeObjectURL(url);

      return response;
    });
};
