import { LoadingButton } from '@mui/lab';
import { FC, useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { useFormContext } from 'react-hook-form';
import { Order, orderValid } from '../types/order.type';

export const OrderFormSaveButton: FC<{
  loading: boolean;
  isNew: boolean;
  save: (newData: Order) => void;
}> = ({ isNew, loading, save }) => {
  const [disabled, setDisabled] = useState(false);
  const { watch, getValues } = useFormContext<Order>();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (
        name === 'orderDate' ||
        name === 'payloads' ||
        name === 'customerId' ||
        name?.includes('cargo.name') ||
        name?.includes('.tariffId') ||
        name?.includes('amounts.freight') ||
        name?.includes('.no') ||
        name?.includes('.length') ||
        name?.includes('.width') ||
        name?.includes('.phoneNo') ||
        name?.includes('.orderStatus')
      ) {
        setDisabled(!orderValid(value as Order));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues]);

  return (
    <LoadingButton
      onClick={() => save(getValues())}
      disabled={disabled}
      loading={loading}
      loadingPosition="end"
      endIcon={<SaveIcon />}
    >
      {isNew ? 'Create' : 'Save'}
    </LoadingButton>
  );
};
