import { LoginResponse } from '../types/auth.types';

const AUTH = 'auth';

const getAuth = (): LoginResponse | null => {
  const authJons = localStorage.getItem(AUTH);
  if (authJons) return JSON.parse(authJons);
  else return null;
};

export const auth = {
  // getters
  isAuthenticated: (): boolean => !!getAuth()?.token,
  getToken: (): string | null => getAuth()?.token || null,
  getUser: (): string | null => getAuth()?.user.name || null,

  // methods
  login: (token: LoginResponse): void => {
    localStorage.setItem(AUTH, JSON.stringify(token));
    dispatchEvent(new Event(AUTH));
  },
  logout: (): void => {
    localStorage.removeItem(AUTH);
    dispatchEvent(new Event(AUTH));
  },

  // events
  subscribe: (callback: () => void) => {
    window.addEventListener(AUTH, callback);
    return () => window.removeEventListener(AUTH, callback);
  },
};
