/** Outputs number in 1,978.00 format */
export const customMoneyFormat = (
  amount: number | null | undefined,
  decimals: number = 2
): string => {
  if (amount == null) return '';

  return amount.toLocaleString('ka-GE', {
    // uncomment the following line to display currency symbol
    // style: 'currency', currency: 'EUR',
    // display 2 decimal places by default
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    // hide - sign for negative numbers
    signDisplay: 'never',
  });
};
