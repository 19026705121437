import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import {
  Currency,
  GetFinancialRecordResponse,
  Transfer,
  TransferType,
  currencies,
  getFinancialRecordBalance,
  getRemainingAmount,
  getTransferEURAmount,
  initTransfer,
  transferTypes,
} from '../types/finances.types';
import { getFinancialRecord, savePayloadTransfers } from '../api/finances.api';
import {
  AmountInput,
  CustomDatePicker,
  GenericSelector,
  Loading,
} from 'src/components';
import { CitySelector } from 'src/features/cities';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { produce } from 'immer';
import { useRate } from '../hooks/useRate.hook';
import { MoneyChip } from './MoneyChip';

export const PayloadTransfersDialog: FC<{
  payloadId: string | null;
  open: boolean;
  onClose: () => void;
}> = ({ payloadId, open, onClose }) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth="lg"
    onClose={(_, r) => r === 'escapeKeyDown' && onClose()}
  >
    {payloadId && (
      <PayloadTransfersDialogContent payloadId={payloadId} onClose={onClose} />
    )}
  </Dialog>
);

const PayloadTransfersDialogContent: FC<{
  payloadId: string;
  onClose: () => void;
}> = ({ payloadId, onClose }) => {
  const [financialRecord, setFinancialRecord] =
    useState<GetFinancialRecordResponse>();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const balance = getFinancialRecordBalance(financialRecord);

  useEffect(() => {
    getFinancialRecord(payloadId)
      .then((data) => {
        setFinancialRecord(data);
      })
      .finally(() => setLoading(false));
  }, [payloadId]);

  if (loading || !financialRecord) return <Loading />;

  const save = () => {
    setSaving(true);
    savePayloadTransfers(financialRecord)
      .then(() => toast.success('Transfers saved successfully!'))
      .finally(() => setSaving(false));
  };

  const addTransfer = () => {
    setFinancialRecord(
      produce(financialRecord, (draft) => {
        draft.transfers.push(initTransfer());
      })
    );
  };

  const updateTransfer = (transfer: Transfer) => {
    setFinancialRecord(
      produce(financialRecord, (draft) => {
        const index = draft.transfers.findIndex((t) => t.id === transfer.id);
        draft.transfers[index] = transfer;
      })
    );
  };

  const removeTransfer = (id: string) => () => {
    setFinancialRecord(
      produce(financialRecord, (draft) => {
        draft.transfers = draft.transfers.filter((t) => t.id !== id);
      })
    );
  };

  return (
    <Box>
      <Box
        px={2}
        py={1}
        display="flex"
        justifyContent="space-between"
        gap={3}
        borderBottom={1}
        borderColor="divider"
      >
        <Box width={80}>
          <LoadingButton
            onClick={save}
            endIcon={<SaveIcon />}
            loadingPosition="end"
            loading={saving}
          >
            Save
          </LoadingButton>
        </Box>

        <Typography variant="h6">
          {financialRecord.ticketNo} Transfers
        </Typography>

        <Box width={80} display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <Box pt={1} px={2} display="flex" gap={1} alignItems="center">
          <TextField label="Agent" value={financialRecord.charterer} disabled />
          <TextField label="Payload No" value={financialRecord.no} disabled />
          <TextField label="Credit" value={financialRecord.credit} disabled />
          <Box display="flex" gap={1} alignItems="center" mt={1} ml={1}>
            <MoneyChip label="Balance" amount={balance} decimals={4} />
          </Box>
        </Box>

        <Divider sx={{ mt: 1 }} />

        {financialRecord.transfers.map((transfer) => (
          <TransferLine
            key={transfer.id}
            transfer={transfer}
            setTransfer={updateTransfer}
            removeTransfer={removeTransfer(transfer.id)}
            balance={balance}
          />
        ))}

        <Button sx={{ mx: 2, my: 1 }} onClick={addTransfer}>
          Add Transfer
        </Button>
      </Box>
    </Box>
  );
};

const TransferLine: FC<{
  transfer: Transfer;
  setTransfer: (transfer: Transfer) => void;
  removeTransfer: () => void;
  balance: number;
}> = ({ transfer, setTransfer, removeTransfer, balance }) => {
  const [totalAmount, setTotalAmount] = useState(
    transfer.amount + transfer.comission
  );
  const { rate, rateLoading, rateError } = useRate(transfer.date);
  const currencyRate = (() => {
    if (transfer.currency === 'EUR') return (1).toFixed(4);
    if (rateError) return 'None';
    if (rateLoading) return 'Loading...';
    if (transfer.currency === 'GEL') return rate.gel_to_eur.toFixed(4);
    if (transfer.currency === 'USD') return rate.usd_to_eur.toFixed(4);
  })();
  const rateColor =
    rateError && transfer.currency !== 'EUR' ? 'red' : undefined;
  const isTerminalTransfer = transfer.type === 'Terminal';
  const remaining = getRemainingAmount(
    balance,
    transfer.type,
    transfer.currency,
    rate
  );

  useEffect(() => {
    setTransfer({
      ...transfer,
      amountEUR: getTransferEURAmount(transfer, rate),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transfer.currency,
    transfer.date,
    transfer.amount,
    transfer.comission,
    rate.gel_to_eur,
    rate.usd_to_eur,
  ]);

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      alignItems="center"
      px={2}
      py={1}
      borderBottom={1}
      borderColor="divider"
    >
      <Box>
        <Box display="flex" gap={1} alignItems="center">
          <CitySelector
            label="Place"
            cityId={transfer.placeId}
            onChange={(placeId) =>
              setTransfer({ ...transfer, placeId: placeId! })
            }
          />
          <CustomDatePicker
            label="Date"
            initialValue={transfer.date}
            onChange={(date) => setTransfer({ ...transfer, date })}
          />
          <TextField
            label="Payer"
            value={transfer.payer}
            onChange={(e) =>
              setTransfer({ ...transfer, payer: e.target.value })
            }
          />
          <GenericSelector
            label="Type"
            options={transferTypes}
            value={transfer.type}
            onChange={(type) =>
              setTransfer({ ...transfer, type: type as TransferType })
            }
          />
          <Box width={90}>
            <GenericSelector
              label="Currency"
              options={currencies}
              value={transfer.currency}
              onChange={(currency) =>
                setTransfer({ ...transfer, currency: currency as Currency })
              }
              fullWidth
            />
          </Box>
          <TextField
            type="float"
            label="Rate"
            value={currencyRate}
            disabled
            InputLabelProps={{ style: { color: rateColor } }}
            sx={{
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': { borderColor: rateColor },
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: rateColor,
              },
              width: 80,
            }}
          />
          <TextField
            type="float"
            label="Remaining"
            value={remaining.toFixed(4)}
            disabled
          />
        </Box>

        <Box display="flex" gap={1} alignItems="center">
          {isTerminalTransfer ? (
            <>
              <AmountInput
                type="float"
                label="Total Amount"
                value={totalAmount}
                onChange={(totalAmount) => {
                  setTotalAmount(totalAmount);
                  setTransfer({
                    ...transfer,
                    amount: totalAmount * 0.98,
                    comission: totalAmount * 0.02,
                  });
                }}
              />
              <TextField
                label="Debit"
                value={transfer.amount.toFixed(4)}
                disabled
              />
              <TextField
                label="Comission"
                value={transfer.comission.toFixed(4)}
                disabled
              />
            </>
          ) : (
            <>
              <TextField
                label="Total Amount"
                value={totalAmount.toFixed(4)}
                disabled
              />
              <AmountInput
                type="float"
                label="Debit"
                value={transfer.amount}
                onChange={(debit) => {
                  setTransfer({ ...transfer, amount: debit });
                  setTotalAmount(debit + transfer.comission);
                }}
              />
              <AmountInput
                type="float"
                label="Comission"
                value={transfer.comission}
                onChange={(comission) => {
                  setTransfer({ ...transfer, comission });
                  setTotalAmount(transfer.amount + comission);
                }}
              />
            </>
          )}

          <TextField
            type="float"
            label="Amount EUR"
            value={transfer.amountEUR.toFixed(4)}
            disabled
            InputLabelProps={{ style: { color: rateColor } }}
            sx={{
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': { borderColor: rateColor },
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: rateColor,
              },
            }}
          />

          {transfer.createdByName && (
            <TextField
              label="Created By"
              value={transfer.createdByName}
              disabled
            />
          )}
        </Box>
      </Box>

      <Box>
        <IconButton color="error" onClick={removeTransfer}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
