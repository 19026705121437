import { LoadingButton } from '@mui/lab';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'src/components/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/features/routes';
import toast from 'react-hot-toast';
import { deleteAccessGroup } from '../api/accessGroups.api';
import { getErrorMessage } from 'src/utils';

export const DeleteAccessGroupButton: FC<{
  accessGroupId: number;
}> = ({ accessGroupId }) => {
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    setDeleting(true);
    deleteAccessGroup(accessGroupId)
      .then(() => {
        navigate(routes.access);
        toast.success('Access Group deleted!');
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setDeleting(false));
  };

  return (
    <>
      <LoadingButton
        loading={deleting}
        loadingPosition="end"
        endIcon={<DeleteIcon />}
        color="error"
        onClick={() => setOpen(true)}
      >
        Delete
      </LoadingButton>

      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        title="Delete this Access Group?"
        onConfirm={handleDelete}
      />
    </>
  );
};
