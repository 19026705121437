import { axiosInstance } from 'src/config/axios.config';
import { CompanyListType, CompanyType } from '../type/company.type';

const endpoint = '/api/Companies';

export type GetCompanyListParams = {
  page: { page: number; size: number };
  name: string;
  companyId: string;
};

export const getCompanyList = async (
  params: GetCompanyListParams
): Promise<CompanyListType> =>
  (await axiosInstance.put(`${endpoint}/get-all`, params)).data;

export const getCompany = async (id: number): Promise<CompanyType> =>
  (await axiosInstance.get(`${endpoint}/${id}`)).data;

export const addCompany = async (company: CompanyType): Promise<CompanyType> =>
  (await axiosInstance.post(endpoint, company)).data;

export const updateCompany = async (company: CompanyType): Promise<void> =>
  await axiosInstance.put(endpoint, company);

export const deleteCompany = async (id: number) =>
  await axiosInstance.delete(`${endpoint}/${id}`);
