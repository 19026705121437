import { createColumnHelper } from '@tanstack/react-table';
import { FC, useMemo, useState } from 'react';
import { CruisePayload, cruiseDeckCargo } from '../../types/cruise.type';
import { columnDefs } from './columnDefs';
import { PayloadsToolbar } from './PayloadsToolbar';
import { CustomTable } from 'src/components';
import { PayloadListProps } from '../../types/payloadListProps.type';
import { compact } from 'lodash';
import { ReportButton } from 'src/features/reports';
import { ScrollableTableWrapper } from './ScrollableTableWrapper';

const ch = createColumnHelper<CruisePayload>();

export const DeckCargoList: FC<PayloadListProps> = ({
  cruise,
  checks,
  setChecked,
  hideButtons,
}) => {
  const columns = useMemo(
    () =>
      compact([
        columnDefs.selected(checks, setChecked),
        hideButtons ? null : columnDefs.reports,
        columnDefs.paymentStatus,
        columnDefs.orderStatus,
        columnDefs.rowNumber,
        columnDefs.orderNo(hideButtons, cruise.no),
        ch.accessor('no', { header: 'No' }),
        ch.accessor('type', { header: 'Type' }),
        ch.accessor('cargoName', { header: 'Cargo Name' }),
        ch.accessor('cargoFullWeight', { header: 'Full Weight' }),
        ch.accessor('shipper', { header: 'Payer' }),
        ch.accessor('charterer', { header: 'Charterer' }),
        ch.accessor('driverPhone', { header: 'Phone Number' }),
        ch.accessor('createdByName', { header: 'Created By' }),
      ]),
    [checks, setChecked, hideButtons, cruise.no]
  );

  const data = cruiseDeckCargo(cruise).map((i) => ({
    ...i,
    no: i.no + (i.trailerNo ? ` / ${i.trailerNo}` : ''),
    cargoName: i.type === 'Car' ? i.model : i.cargoName,
    type: (() => {
      if (i.type === 'Car') return 'Deck Car';
      else if (i.type === 'Truck') return 'Deck Truck';
      else return 'Deck Cargo';
    })(),
  }));

  const [globalFilter, setGlobalFilter] = useState('');

  return (
    <>
      <PayloadsToolbar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      >
        {cruise.id && (
          <>
            <ReportButton
              name="ContainerManifest"
              params={{ cruiseId: cruise.id }}
              formats={['Word', 'Excel']}
            />
            <ReportButton
              name="BillOfLadingFromCruise"
              label="Bill Of Lading (Original)"
              params={{ cruiseId: cruise.id, isOriginal: true }}
              formats={['Word', 'Excel']}
            />
            <ReportButton
              name="BillOfLadingFromCruise"
              label="Bill Of Lading (Copy)"
              params={{ cruiseId: cruise.id, isOriginal: false }}
              formats={['Word', 'Excel']}
            />
            <ReportButton
              name="CargoManifestEATK_3"
              params={{ cruiseId: cruise.id }}
              formats={['Excel']}
            />
          </>
        )}
      </PayloadsToolbar>

      <ScrollableTableWrapper>
        <CustomTable
          data={data}
          columns={columns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </ScrollableTableWrapper>
    </>
  );
};
