import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import { CustomTable, Loading } from 'src/components';
import { AccessGroupListItem } from '../types/accessGroup.type';
import { Link } from 'react-router-dom';
import { routes } from 'src/features/routes';
import { Box, Button, Card, Typography } from '@mui/material';
import { useAccessGroups } from '../hooks/useAccessGroups.hook';

const ch = createColumnHelper<AccessGroupListItem>();

const columns = [
  ch.accessor('name', {
    header: 'Name',
    cell: (info) => (
      <Link to={routes.accessGroup(info.row.original.id)}>
        {info.getValue()}
      </Link>
    ),
  }),
];

export const AccessGroupList: FC<{}> = () => {
  const { data } = useAccessGroups();

  return (
    <Box>
      <Card
        sx={{
          p: 1,
          mb: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box width={64} display={{ sm: 'block', md: 'none', lg: 'block' }} />

        <Typography variant="h6">Access Groups</Typography>

        <Button component={Link} to={routes.createAccessGroup}>
          Add
        </Button>
      </Card>

      {!data ? <Loading /> : <CustomTable columns={columns} data={data} />}
    </Box>
  );
};
