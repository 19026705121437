import { FC } from 'react';
import { GenericSelector } from 'src/components';
import { PayloadType, payloadTypes } from 'src/features/orders';

export const TariffTypeSelector: FC<{
  value: PayloadType;
  onChange: (value: PayloadType) => void;
}> = ({ value, onChange }) => (
  <GenericSelector
    label="Type"
    value={value}
    onChange={(e) => onChange(e as PayloadType)}
    options={payloadTypes}
  />
);
