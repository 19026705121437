import { FC } from 'react';
import { TENANT } from 'src/config/app.config';
import { InvoiceReportButton } from './InvoiceReportButton';
import { BSFInvoiceReportButton } from './BSFInvoiceReportButton';

export const GenericInvoiceReportButton: FC<{
  orderId: number;
  size?: 'default' | 'small';
  useIconButton?: boolean;
  useFilledButton?: boolean;
}> = ({ orderId, size = 'default', useIconButton, useFilledButton }) => {
  switch (TENANT) {
    case 'E60':
      return (
        <InvoiceReportButton
          orderId={orderId}
          type="E60Invoice"
          size={size}
          useIconButton={useIconButton}
          useFilledButton={useFilledButton}
        />
      );
    case 'BSF':
      return (
        <BSFInvoiceReportButton
          orderId={orderId}
          size={size}
          useIconButton={useIconButton}
        />
      );
    default:
      return null;
  }
};
