import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FC } from 'react';

const filterFn: FilterFn<any> = (row, columnId, filter) => {
  const value = String(row.getValue(columnId));
  return value.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
};

export const CustomTable: FC<{
  data: any[];
  columns: ColumnDef<any, any>[];
  globalFilter?: string;
  setGlobalFilter?: (value: string) => void;
  style?: React.CSSProperties | undefined;
}> = ({ data, columns, globalFilter, setGlobalFilter, style }) => {
  const table = useReactTable<any>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // Filtering setup
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: { globalFilter },
    filterFns: { fuzzyFilter: filterFn },
    globalFilterFn: filterFn,
  });

  return (
    <table className="custom-table" style={style}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={{
                  minWidth: cell.column.columnDef.minSize,
                  maxWidth: cell.column.columnDef.maxSize,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
