import { FC, useState } from 'react';
import { getReport } from '../api/reports.api';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { ReportType } from '../types/report.type';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { startCase } from 'lodash';

export const ReportButton: FC<{
  name: string;
  label?: string;
  params: any;
  formats: ReportType[];
  size?: 'default' | 'small';
  disabled?: boolean;
  useIconButton?: boolean;
  iconButtonIcon?: JSX.Element;
  useFilledButton?: boolean;
}> = ({
  name,
  label,
  params,
  formats,
  size = 'default',
  disabled,
  useIconButton,
  iconButtonIcon,
  useFilledButton,
}) => {
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  const handleMenuItemClick = (type: ReportType) => {
    closeMenu();
    setLoading(true);

    getReport(name, params, type)
      .catch(() => toast.error('Error while generating report'))
      .finally(() => setLoading(false));
  };

  const buttonComponent = (
    <LoadingButton
      color="success"
      loadingPosition="end"
      endIcon={<ReceiptLongIcon />}
      loading={loading}
      onClick={handleClick}
      sx={{
        textTransform: 'none',
        minHeight: size === 'small' ? 24 : undefined,
        maxHeight: size === 'small' ? 24 : undefined,
      }}
      disabled={disabled}
    >
      {label || startCase(name)}
    </LoadingButton>
  );

  const iconButtonComponent = (
    <IconButton
      color="success"
      onClick={handleClick}
      disabled={disabled || loading}
    >
      <Tooltip title={label || startCase(name)}>
        {iconButtonIcon || <ReceiptLongIcon />}
      </Tooltip>
    </IconButton>
  );

  const filledIconButtonComponent = (
    <Tooltip title={label || startCase(name)}>
      <IconButton
        color="success"
        onClick={handleClick}
        sx={{
          color: 'whitesmoke',
          bgcolor: 'success.main',
          ':hover': { bgcolor: 'success.dark' },
          ':disabled': { color: 'whitesmoke', bgcolor: 'text.disabled' },
          height: 30,
          mt: 0.125,
        }}
        disabled={disabled || loading}
      >
        {iconButtonIcon || <ReceiptLongIcon fontSize="small" />}
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {useIconButton
        ? useFilledButton
          ? filledIconButtonComponent
          : iconButtonComponent
        : buttonComponent}

      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        {formats.includes('Preview') && (
          <MenuItem onClick={() => handleMenuItemClick('Preview')}>
            <ListItemIcon>
              <OpenInNewIcon color="inherit" fontSize="small" />
            </ListItemIcon>
            Open
          </MenuItem>
        )}

        {formats.includes('Pdf') && (
          <MenuItem onClick={() => handleMenuItemClick('Pdf')}>
            <ListItemIcon>
              <PictureAsPdfIcon fontSize="small" />
            </ListItemIcon>
            Pdf
          </MenuItem>
        )}

        {formats.includes('Word') && (
          <MenuItem onClick={() => handleMenuItemClick('Word')}>
            <ListItemIcon>
              <ArticleIcon fontSize="small" />
            </ListItemIcon>
            Word
          </MenuItem>
        )}

        {formats.includes('Excel') && (
          <MenuItem onClick={() => handleMenuItemClick('Excel')}>
            <ListItemIcon sx={{ pl: 0.11, mr: -0.11 }}>
              <RiFileExcel2Fill fontSize={18} />
            </ListItemIcon>
            Excel
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
