import useSWR from 'swr';
import { getCruiseList } from '../api/cruises.api';

export const useCruiseList = (
  depPortId: number | undefined,
  desPortId: number | undefined,
  shipId: number | undefined,
  page: number | undefined,
  size: number | undefined
) => {
  const { data, mutate: refresh } = useSWR(
    [`/cruises/${depPortId}/${desPortId}/${shipId}`, page, size],
    ([, page, size]) => getCruiseList(depPortId, desPortId, shipId, page, size)
  );

  return { data, refresh };
};
