import { FC } from 'react';
import { SearchResponseDataItem } from '../types/searchResponse.type';
import { Link } from 'react-router-dom';
import { routes } from 'src/features/routes';
import { SearchColumnsType } from '../types/searchColumns.type';
import { startCase } from 'lodash';
import { customDateFormat } from 'src/utils';

export const SearchTableCell: FC<{
  column: SearchColumnsType;
  row: SearchResponseDataItem;
}> = ({ column, row }) => {
  const cell = {
    'Payload Type': startCase(row.payloadType),
    'Cruise No': <Link to={routes.cruise(row.cruiseId)}>{row.cruiseNo}</Link>,
    'Cruise Date': customDateFormat(row.cruiseDate),
    Ship: row.ship,
    'Departure Port': row.departurePort,
    'Destination Port': row.destinationPort,
    'Order No': <Link to={routes.editOrder(row.orderId)}>{row.orderNo}</Link>,
    'Order Date': customDateFormat(row.orderDate),
    'Order Status': startCase(row.orderStatus),
    'Payment Status': row.paymentStatus,
    'Payment Comment': row.orderComment,
    'Ticket Type': startCase(row.ticketType),
    'Created By': row.createdBy,
    Charterer: row.charterer,
    // Payload
    No: row.no,
    Name: row.name,
    Surname: row.surname,
    Passport: row.passport,
    'Personal Id': row.personalId,
    Shipper: row.shipper,
    'Freight Rate': row.freightRate,
    Tariff: row.tariff,
    'Phone Number': row.phoneNumber,
  }[column];

  return <>{cell}</>;
};
